import { IAuditJobUpdate } from "./../../websocket/types";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { IncidentsFilterOptions } from "../../shared/enums";
import {
  IIncident,
  IncidentsState,
  IFilteredIncidentsCount,
} from "./incidentsTypes";
import { RequestStatus } from "@montel/montelpro-shared-components/enums";
import moment from "moment";
import { getUserId } from "../session/sessionSlice";
import { fetchIncidents, fetchIncidentsByAuditId } from "./incidentsThunks";
import { getPersonIdNameMapping } from "../lists/listsSlice";

export const initialState: IncidentsState = {
  status: RequestStatus.LOADING,
  data: [],
  error: undefined,
  currentFilter: IncidentsFilterOptions.ACTIVE,
  isDataLoaded: false,
  filters: [],
  postponeModalId: undefined,
};

export const incidentsSlice = createSlice({
  name: "incidents",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setCurrentFilter: (state, { payload }) => {
      state.currentFilter = payload;
    },
    updateIncident: (state, { payload }: { payload: IIncident }) => {
      const existingIncidentIdx = state.data.findIndex(
        (incident) => incident.id === payload.id
      );
      if (existingIncidentIdx === -1) {
        state.data = [...state.data, payload];
        return;
      }
      state.data[existingIncidentIdx] = payload;
    },
    updateSourceStatus: (state, { payload }: { payload: IAuditJobUpdate }) => {
      const { auditId, runStatus } = payload;
      state.data.forEach((incident) => {
        if (incident.sourceId === auditId) {
          incident.sourceStatus = runStatus;
        }
      });
    },
    toggleFilter: (state, { payload }) => {
      const { category, value } = payload;
      const isSelected = state.filters.find(
        (filter) => filter.category === category && filter.value === value
      );
      if (isSelected) {
        state.filters = state.filters.filter(
          (filter) => !(filter.category === category && filter.value === value)
        );
      } else {
        state.filters.push({ category, value });
      }
    },
    setPostponeModalId: (state, { payload }) => {
      state.postponeModalId = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncidents.pending, (state) => {
        state.status = RequestStatus.LOADING;
        state.error = undefined;
      })
      .addCase(fetchIncidents.fulfilled, (state, action) => {
        state.status = RequestStatus.SUCCESS;
        state.data = action.payload;
        state.isDataLoaded = true;
      })
      .addCase(fetchIncidents.rejected, (state, action) => {
        state.status = RequestStatus.ERROR;
        state.error = action.error;
      })
      .addCase(fetchIncidentsByAuditId.pending, (state) => {
        state.status = RequestStatus.LOADING;
        state.error = undefined;
      })
      .addCase(fetchIncidentsByAuditId.fulfilled, (state, action) => {
        state.status = RequestStatus.SUCCESS;
        state.data = action.payload;
      })
      .addCase(fetchIncidentsByAuditId.rejected, (state, action) => {
        state.status = RequestStatus.ERROR;
        state.error = action.error;
      });
  },
});

export const {
  setData,
  setCurrentFilter,
  toggleFilter,
  updateIncident,
  updateSourceStatus,
  setPostponeModalId,
} = incidentsSlice.actions;

export const getIncidentsState = (state: RootState) => state.incidents;

export const getIncidents = (state: RootState) => getIncidentsState(state).data;

const filterIncidents = (
  incidents: Array<IIncident>,
  filterType: IncidentsFilterOptions,
  filters: { category: string; value: any }[]
) => {
  const filteredByStatus = incidents.filter((incident) =>
    incidentStatusFilter(incident, filterType)
  );
  const filteredUniversal = incidentUniversalFilter(filteredByStatus, filters);
  return filteredUniversal;
};

const incidentStatusFilter = (
  incident: IIncident,
  filterType: IncidentsFilterOptions
) => {
  switch (filterType) {
    case IncidentsFilterOptions.COMPLETED:
      return incident.isClosed;
    case IncidentsFilterOptions.POSTPONED:
      return isPostponed(incident) && !incident.isClosed;
    default:
      return !isPostponed(incident) && !incident.isClosed;
  }
};

export const incidentUniversalFilter = (
  incidents: IIncident[],
  filters: { category: string; value: any }[]
) => {
  if (filters.length === 0) return incidents;

  const categories = Array.from(
    new Set(filters.map((filter) => filter.category))
  );

  return incidents.filter((incident: IIncident) =>
    categories.every((category) => {
      const values = filters
        .filter((filter) => filter.category === category)
        .map((filter) => filter.value);
      return values.some(
        (value) => incident[category as keyof IIncident] === value
      );
    })
  );
};

const isPostponed = (incident: IIncident) =>
  moment(incident.postponedTo).isAfter(moment.now());

export const getFilteredIncidents = (state: RootState) => {
  const { currentFilter } = getIncidentsState(state);
  const incidents = getIncidents(state);
  const filters = getFilters(state);
  return filterIncidents(incidents, currentFilter, filters);
};

export const getFilteredIncidentsCount = (
  state: RootState
): IFilteredIncidentsCount => {
  const incidents = getIncidents(state);
  const filters = getFilters(state);
  return {
    active: filterIncidents(incidents, IncidentsFilterOptions.ACTIVE, filters)
      .length,
    postponed: filterIncidents(
      incidents,
      IncidentsFilterOptions.POSTPONED,
      filters
    ).length,
    completed: filterIncidents(
      incidents,
      IncidentsFilterOptions.COMPLETED,
      filters
    ).length,
    all: getIncidents(state).length,
  };
};

export const getCurrentStatusFilter = (state: RootState) =>
  getIncidentsState(state).currentFilter;
export const getStatus = (state: RootState) => getIncidentsState(state).status;
export const getError = (state: RootState) => getIncidentsState(state).error;

export const getIsAssignedToCurrentUser =
  (incidentId: number) => (state: RootState) => {
    const userId = getUserId(state);
    return (
      getIncidents(state).find((incident) => incident.id === incidentId)
        ?.assigneeId === userId
    );
  };

export const getFilters = (state: RootState) =>
  getIncidentsState(state).filters;

export const getIsFilterSelected = (
  state: RootState,
  category: string,
  value: any
): boolean =>
  getFilters(state).some(
    (filter) => filter.category === category && filter.value === value
  );

export const getAssigneesFilterOptions = (state: RootState) => {
  let currentUserId = getUserId(state);

  let assigneeIds: any = new Set(
    getIncidents(state)
      .map((incident) => incident.assigneeId)
      .filter((id) => id !== undefined)
      .filter((id) => id !== currentUserId)
  );
  assigneeIds = Array.from(assigneeIds);

  const personIdNameMapping = getPersonIdNameMapping(state) as {
    [id: string]: string;
  };

  return assigneeIds.map((assigneeId: string) => {
    const id = assigneeId || "";
    return {
      label: personIdNameMapping[id],
      value: id,
    };
  });
};

export const getPostponeModalId = (state: RootState) =>
  getIncidentsState(state).postponeModalId;

export default incidentsSlice.reducer;
