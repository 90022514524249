import { mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Button,
  DividerLine,
  SubmitButton,
} from "@montel/montelpro-shared-components";
import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import useModal from "../../../hooks/useModal";
import {
  getIsDataDirty,
  getIsDataValid,
  getIsEditMode,
  getSubmitStatus,
  setIsEditMode,
} from "../auditSlice";
import { revertChanges, saveData, setShowErrors } from "../auditThunks";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import styles from "./buttonsBar.module.scss";

const ButtonsBar: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const isEditMode = useAppSelector(getIsEditMode);
  const submitStatus = useAppSelector(getSubmitStatus);
  const isDataDirty = useAppSelector(getIsDataDirty);
  const isDataValid = useAppSelector(getIsDataValid);
  const { isModalOpen, openModal, closeModal } = useModal();

  return (
    <>
      <DividerLine />
      <div className={styles.buttonGroup}>
        {isEditMode ? (
          <>
            <Button
              text={"Cancel"}
              onClick={() => {
                if (isDataDirty) {
                  openModal();
                } else {
                  dispatch(setIsEditMode(false));
                }
              }}
            />
            <SubmitButton
              status={submitStatus}
              text={"Save"}
              kind={"primary"}
              onClick={() => {
                dispatch(setShowErrors());
                if (isDataValid) {
                  dispatch(saveData());
                }
              }}
            />
            <ConfirmationModal
              isOpen={isModalOpen}
              onOk={() => {
                dispatch(revertChanges());
                dispatch(setIsEditMode(false));
                closeModal();
              }}
              onCancel={closeModal}
            />
          </>
        ) : (
          <Button
            text={"Edit"}
            iconLeft={<Icon path={mdiPencil} />}
            onClick={() => dispatch(setIsEditMode(true))}
          />
        )}
      </div>
    </>
  );
};

export default ButtonsBar;
