import { FunctionComponent, useState } from "react";
import Datetime from "react-datetime";
import Icon from "@mdi/react";
import { mdiTimerOutline } from "@mdi/js";
import styles from "./timePicker.module.scss";
import { Moment } from "moment";
import moment from "moment";

export interface TimePickerProps {
  value: string | Moment | undefined | null;
  onChange: (arg: string) => void;
  errorMessage?: string;
}

const TIME_FORMAT = "HH:mm";

export const TimePicker: FunctionComponent<TimePickerProps> = ({
  value,
  onChange,
  errorMessage,
}) => {
  const [localValue, setLocalValue] = useState(value);

  return (
    <Datetime
      className={styles.rdt}
      dateFormat={false}
      timeFormat={true}
      closeOnSelect
      renderInput={(props) =>
        renderInput({
          ...props,
          value,
          onChange,
          errorMessage,
          localValue,
          setLocalValue,
        })
      }
      value={value || undefined}
      onChange={(value) => {
        const formattedToTime = formatMomentToTime(value);
        setLocalValue(formattedToTime);
        onChange(formattedToTime);
      }}
    />
  );
};

const renderInput = ({
  value,
  onChange,
  errorMessage,
  localValue,
  setLocalValue,
  ...restOfProps
}: any) => {
  return (
    <div>
      <div className={`${styles.container} ${errorMessage && styles.error}`}>
        <input
          {...restOfProps}
          value={localValue}
          placeholder={"--:--"}
          maxLength={5}
          onChange={(event) => {
            const newValue = event.target.value;
            setLocalValue(newValue);
            const timeMoment = moment(newValue, TIME_FORMAT, true);
            if (timeMoment.isValid()) {
              onChange(formatMomentToTime(timeMoment));
            }
          }}
          onBlur={() => {
            value ? setLocalValue(value) : setLocalValue("");
          }}
        />
        <Icon path={mdiTimerOutline} className={styles.icon} />
      </div>
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </div>
  );
};

export const formatMomentToTime = (value: Moment | string) =>
  moment(value).format(TIME_FORMAT);
