import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { getAuditId, getName } from "../auditSlice";
import { RequestStatus } from "@montel/montelpro-shared-components/enums";
import axios from "axios";
import apiRoutes from "../../../apiRoutes";
import { toast } from "react-toastify";
import appPages from "../../../appPages";
import Modal from "../../../components/Modal/Modal";

export interface DeleteAuditModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
}

const DeleteAuditModal: FunctionComponent<DeleteAuditModalProps> = ({
  isModalOpen,
  closeModal,
}) => {
  const navigate = useNavigate();
  const name = useAppSelector(getName);
  const id = useAppSelector(getAuditId);
  const [submitStatus, setSubmitStatus] = useState(RequestStatus.UNLOADED);

  const deleteAudit = async () => {
    setSubmitStatus(RequestStatus.LOADING);
    let isSuccess = false;
    try {
      await axios.delete(apiRoutes.auditDelete(id));
      toast.success("Audit successfully deleted.", { autoClose: 3000 });
      isSuccess = true;
    } catch (error: any) {
      toast.error("Something went wrong. Audit was not deleted.", {
        autoClose: false,
      });
    }
    setSubmitStatus(RequestStatus.UNLOADED);
    return isSuccess;
  };

  const onConfirmDelete = async () => {
    const isDeleted = await deleteAudit();
    closeModal();
    if (isDeleted) {
      navigate(appPages.audits);
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      title={`Delete: ${name}`}
      onOk={onConfirmDelete}
      onCancel={() => closeModal()}
      onOkStatus={submitStatus}
    >
      <div>
        <p>Delete the selected audit? This cannot be undone.</p>
        <p>Note: related incidents will be deleted as well.</p>
      </div>
    </Modal>
  );
};

export default DeleteAuditModal;