import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "@montel/montelpro-shared-components/enums";
import { NotesState, INote } from "./notesTypes";
import { deleteNote, fetchNotes, submitNote } from "./notesThunks";
import toServerTimestamp from "../../utils/dateTimeUtils/toServerTimestamp";
import moment from "moment";

export const initialState: NotesState = {
  status: RequestStatus.UNLOADED,
  error: undefined,
  data: [],
  currentId: undefined,
  currentText: "",
  submitStatus: RequestStatus.UNLOADED,
  deleteStatus: RequestStatus.UNLOADED,
};

export const NEW_NOTE_ID = -1;

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    editNote: (state, { payload: id }) => {
      state.submitStatus = RequestStatus.UNLOADED;
      const currentNote = getNoteById(state, id);
      state.currentText = currentNote ? currentNote.text : "";
      state.currentId = id;
    },
    editNewNote: (state) => {
      state.submitStatus = RequestStatus.UNLOADED;
      state.currentId = NEW_NOTE_ID;
      state.currentText = "";
    },
    updateCurrentText: (state, { payload: text }) => {
      state.currentText = text;
    },
    finishEditing: (state) => {
      state.currentId = undefined;
      state.currentText = "";
    },
    addSavedNote: (state, { payload: note }) => {
      state.data.push(note);
    },
    updateSavedNote: (state) => {
      const currentNote = getNoteById(state, state.currentId);
      if (!currentNote) return;
      currentNote.text = state.currentText;
      currentNote.editedAt = toServerTimestamp(moment());
    },
    deleteNoteAction: (state, { payload: id }) => {
      state.data = state.data.filter((note) => note.id !== id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotes.pending, (state) => {
        state.status = RequestStatus.LOADING;
        state.error = undefined;
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.status = RequestStatus.SUCCESS;
        state.data = action.payload;
      })
      .addCase(fetchNotes.rejected, (state, action) => {
        state.status = RequestStatus.ERROR;
        state.error = action.error;
      })
      .addCase(submitNote.pending, (state) => {
        state.submitStatus = RequestStatus.LOADING;
      })
      .addCase(submitNote.fulfilled, (state, action) => {
        state.submitStatus = RequestStatus.SUCCESS;
      })
      .addCase(submitNote.rejected, (state, action) => {
        state.submitStatus = RequestStatus.ERROR;
      })
      .addCase(deleteNote.pending, (state) => {
        state.deleteStatus = RequestStatus.LOADING;
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.deleteStatus = RequestStatus.SUCCESS;
      })
      .addCase(deleteNote.rejected, (state, action) => {
        state.deleteStatus = RequestStatus.ERROR;
      });
  },
});

const getNoteById = (
  state: NotesState,
  id: number | undefined
): INote | undefined => state.data.find((note) => note.id === id);

export const {
  editNote,
  editNewNote,
  updateCurrentText,
  finishEditing,
  addSavedNote,
  updateSavedNote,
  deleteNoteAction,
} = notesSlice.actions;

export default notesSlice.reducer;
