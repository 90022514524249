import { FunctionComponent } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Checkbox } from "@montel/montelpro-shared-components";
import { IFilterHandler } from "./Types";

export interface IMenuItemProps extends IFilterHandler {
  category: string;
  label: string;
  value: any;
}

const MenuItem: FunctionComponent<IMenuItemProps> = ({
  category,
  label,
  value,
  isFilterSelectedSelector,
  toggleFilterAction,
}) => {
  const dispatch = useAppDispatch();
  const isSelected = useAppSelector((state) =>
    isFilterSelectedSelector(state, category, value)
  );

  return (
    <Checkbox
      label={label}
      checked={isSelected}
      onChange={() => dispatch(toggleFilterAction({ category, value }))}
    />
  );
};

export default MenuItem;
