import { FunctionComponent } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import MenuItem from "../../../../components/ContextMenu/MenuItem";
import { runAudit } from "../../../audit/auditThunks";
import { mdiPlay } from "@mdi/js";

export interface RunAuditButtonProps {
  auditId: number;
}

const RunAuditButton: FunctionComponent<RunAuditButtonProps> = ({
  auditId,
}) => {
  const dispatch = useAppDispatch();
  return (
    <MenuItem
      iconPath={mdiPlay}
      text={"Run audit"}
      onClick={() => dispatch(runAudit(auditId))}
    />
  );
};

export default RunAuditButton;
