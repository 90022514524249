import { FunctionComponent } from "react";
import { Select, TextArea } from "@montel/montelpro-shared-components";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getOptionByValue } from "../../utils/objectUtils";
import { getSystemsOptions } from "../lists/listsSlice";
import { priorityOptions } from "../../shared/options";
import {
  getAuditInfo,
  getIsDescriptionValid,
  getIsRelatedSystemsValid,
  getIsSolutionProposalValid,
  getShowValidationErrors,
  setDescription,
  setPriority,
  setRelatedSystems,
  setSolutionProposal,
} from "./auditInformationDataSlice";
import styles from "./auditInformation.module.scss";

const EditInformation: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { description, relatedSystems, solutionProposal, priority } =
    useAppSelector(getAuditInfo) || {};
  const systemsOptions = useAppSelector(getSystemsOptions);
  const showValidationErrors = useAppSelector(getShowValidationErrors);
  const isRelatedSystemsValid = useAppSelector(getIsRelatedSystemsValid);
  const isDescriptionValid = useAppSelector(getIsDescriptionValid);
  const isSolutionProposalValid = useAppSelector(getIsSolutionProposalValid);

  return (
    <>
      <Select
        label="Priority"
        className={styles.input}
        options={priorityOptions}
        value={getOptionByValue(priorityOptions, priority)}
        onChange={({ value }) => dispatch(setPriority(value))}
      />
      <Select
        label="Related systems"
        className={styles.input}
        options={systemsOptions}
        isMulti
        value={relatedSystems}
        onChange={(selections) => {
          dispatch(setRelatedSystems(selections));
        }}
        errorMessage={
          showValidationErrors && !isRelatedSystemsValid
            ? "Select at least one related system"
            : undefined
        }
      />
      <TextArea
        className={styles.input}
        label="Short Description"
        value={description}
        rows={2}
        onChange={(event: any) => dispatch(setDescription(event.target.value))}
        errorMessage={
          showValidationErrors && !isDescriptionValid
            ? "Description should not be empty"
            : undefined
        }
      />

      <TextArea
        className={styles.input}
        label="Solution proposal"
        rows={7}
        value={solutionProposal}
        onChange={(event: any) =>
          dispatch(setSolutionProposal(event.target.value))
        }
        errorMessage={
          showValidationErrors && !isSolutionProposalValid
            ? "Solution proposal should not be empty"
            : undefined
        }
      />
    </>
  );
};

export default EditInformation;
