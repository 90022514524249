import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Header } from "./components/Header/Header";
import Incidents from "./features/incidents/Incidents";
import Audits from "./features/audits/Audits";
import { ToastContainer } from "react-toastify";
import appPages from "./appPages";
import "react-toastify/dist/ReactToastify.css";
import "./styles/toast.scss";
import "./styles/globals.scss";
import ContentNotFound from "./components/ContentNotFound/ContentNotFound";
import AuthComplete from "./features/auth/AuthComplete";
import RequireAuth from "./features/auth/RequireAuth";
import useSetUser from "./features/auth/useSetUser";
import useAxiosInteceptor from "./features/auth/useAxiosInterseptor";
import useLoadCommonData from "./hooks/useLoadCommonData";
import Audit from "./features/audit/Audit";
import NewAudit from "./features/newAudit/NewAudit";
import SqlQueryDetailedResult from "./features/auditConfig/sqlQueryResult/SqlQueryDetailedResult";
import Services from "./features/services/Services";

function App() {
  useAxiosInteceptor();
  useSetUser();
  useLoadCommonData();
  return (
    <div>
      <BrowserRouter>
        <div>
          <Header />
          <main>
            <Routes>
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Incidents />
                  </RequireAuth>
                }
              />
              <Route
                path={appPages.incidents}
                element={
                  <RequireAuth>
                    <Incidents />
                  </RequireAuth>
                }
              />
              <Route
                path={appPages.services}
                element={
                  <RequireAuth>
                    <Services />
                  </RequireAuth>
                }
              />
              <Route
                path={appPages.audits}
                element={
                  <RequireAuth>
                    <Audits />
                  </RequireAuth>
                }
              />
              <Route
                path={`${appPages.audit}/:id`}
                element={
                  <RequireAuth>
                    <Audit />
                  </RequireAuth>
                }
              />
              <Route
                path={`${appPages.auditSqlResult}`}
                element={
                  <RequireAuth>
                    <SqlQueryDetailedResult />
                  </RequireAuth>
                }
              />
              <Route
                path={appPages.newAudit}
                element={
                  <RequireAuth>
                    <NewAudit />
                  </RequireAuth>
                }
              />
              <Route path={appPages.authComplete} element={<AuthComplete />} />
              <Route path={"*"} element={<ContentNotFound />} />
            </Routes>
          </main>
        </div>
      </BrowserRouter>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
      />
    </div>
  );
}

export default App;
