import { FunctionComponent } from "react";
import styles from "./liveIndicator.module.scss";
const LiveIndicator: FunctionComponent<{
  isLive: boolean;
  className?: string;
}> = ({ isLive, className }) => (
  <span
    className={`${styles.container} ${!isLive && styles.offline} ${className}`}
  >
    <span
      className={`${styles.circle} ${styles.blink}`}
      aria-hidden="true"
    ></span>
    {isLive ? "Live" : "Offline"}
  </span>
);

export default LiveIndicator;
