import jwtDecode from "jwt-decode";
import { UserRole } from "../../shared/enums";

type DecodedToken = {
  roles: string[];
};

const getRoleFromToken = (accessToken?: string): UserRole | undefined => {
  if (!accessToken) return undefined;
  let decodedToken;
  try {
    decodedToken = jwtDecode(accessToken) as DecodedToken;
  } catch {
    console.error("Invalid access token");
    return undefined;
  }
  const roles = decodedToken?.roles;
  if (!roles || !roles.length) return undefined;
  return roles[0] as UserRole;
};

export default getRoleFromToken;
