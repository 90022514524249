export const WATCHER_API = process.env.REACT_APP_WATCHER_API || "";
const apiRoutes = {
  incidents: `${WATCHER_API}/Incidents`,
  incidentComments: (incidentId: string): string =>
    `${WATCHER_API}/Incidents/${incidentId}/comments`,
  incidentHistory: (incidentId: string): string =>
    `${WATCHER_API}/Incidents/${incidentId}/history`,
  incidentSetAssignee: (incidentId: number): string =>
    `${WATCHER_API}/Incidents/${incidentId}/SetAssignee`,
  incidentPostpone: (incidentId: number): string =>
    `${WATCHER_API}/Incidents/${incidentId}/Postpone`,
  incidentComplete: (incidentId: number): string =>
    `${WATCHER_API}/Incidents/${incidentId}/Complete`,
  audits: `${WATCHER_API}/Audits`,
  auditInfo: (auditId: number | undefined): string =>
    `${WATCHER_API}/Audits/${auditId}/AuditInfo`,
  auditConfig: (auditId: number | undefined): string =>
    `${WATCHER_API}/Audits/${auditId}/AuditConfig`,
  auditsIncidents: (auditId: number | undefined): string =>
    `${WATCHER_API}/Audits/${auditId}/incidents`,
  auditPause: (auditId: string): string =>
    `${WATCHER_API}/Audits/${auditId}/Pause`,
  auditResume: (auditId: string): string =>
    `${WATCHER_API}/Audits/${auditId}/Resume`,
  auditRun: (auditId: number | undefined): string =>
    `${WATCHER_API}/audits/${auditId}/run`,
  auditDelete: (auditId: number | undefined): string =>
    `${WATCHER_API}/Audits/${auditId}`,
  auditSubscribe: (auditId: number | undefined): string =>
    `${WATCHER_API}/Audits/${auditId}/Subscribe`,
  auditUnsubscribe: (auditId: number | undefined): string =>
    `${WATCHER_API}/Audits/${auditId}/Unsubscribe`,
  auditNotes: (auditId: number | undefined): string =>
    `${WATCHER_API}/Audits/${auditId}/Notes`,
  services: `${WATCHER_API}/Services`,
  servicesStartService: `${WATCHER_API}/Services/StartService`,
  servicesStopService: `${WATCHER_API}/Services/StopService`,
  servicesForgetService: `${WATCHER_API}/Services/ForgetService`,
  auditRunSql: `${WATCHER_API}/Audits/RunSqlQuery`,
  notes: `${WATCHER_API}/Notes`,
  persons: `${WATCHER_API}/Users`,
  systems: `${WATCHER_API}/Documentation/Systems`,
  connectionIds: `${WATCHER_API}/Connections`,
  liveUpdates: `${WATCHER_API}/liveUpdates`,
};

export default apiRoutes;
