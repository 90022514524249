import { FunctionComponent, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import useModal from "../../../hooks/useModal";
import { AuditViewTabs } from "../auditEnums";
import { getCurrentTab, getIsDataDirty, setCurrentTab } from "../auditSlice";
import { revertChanges } from "../auditThunks";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import Tabs from "./Tabs";

export const AuditTabs: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const currentTab = useAppSelector(getCurrentTab);
  const isDataDirty = useAppSelector(getIsDataDirty);
  const { isModalOpen, openModal, closeModal } = useModal();
  const [tabToBeConfirmed, setTabToBeConfirmed] = useState("");

  const onTabClick = (selectedTab: string) => {
    if (isDataDirty) {
      setTabToBeConfirmed(selectedTab);
      openModal();
    } else {
      dispatch(setCurrentTab(selectedTab));
    }
  };
  const tabs = Object.values(AuditViewTabs);

  return (
    <>
      <Tabs tabs={tabs} currentTab={currentTab} onTabClick={onTabClick}></Tabs>
      <ConfirmationModal
        isOpen={isModalOpen}
        onOk={() => {
          dispatch(revertChanges());
          dispatch(setCurrentTab(tabToBeConfirmed));
          closeModal();
        }}
        onCancel={closeModal}
      />
    </>
  );
};
