import { FunctionComponent } from "react";
import { mdiPlus, mdiPencil } from "@mdi/js";
import MenuItem from "../../../../components/ContextMenu/MenuItem";
import { useAppDispatch } from "../../../../app/hooks";
import { setRelatedServiceNameModal } from "../../servicesSlice";

export interface AddEditServiceButtonProps {
  serviceName: string;
  relatedSystemId?: string | number;
}

const AddEditServiceButton: FunctionComponent<AddEditServiceButtonProps> = ({
  serviceName,
  relatedSystemId,
}) => {
  const dispatch = useAppDispatch();

  return (
    <>
      {relatedSystemId ? (
        <MenuItem
          iconPath={mdiPencil}
          text={"Edit related system"}
          onClick={() => dispatch(setRelatedServiceNameModal(serviceName))}
        />
      ) : (
        <MenuItem
          iconPath={mdiPlus}
          text={"Add related system"}
          onClick={() => dispatch(setRelatedServiceNameModal(serviceName))}
        />
      )}
    </>
  );
};

export default AddEditServiceButton;
