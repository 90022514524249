import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import incidentsReducer from "../features/incidents/incidentsSlice";
import notesReducer from "../features/notes/notesSlice";
import sessionReducer from "../features/session/sessionSlice";
import auditReducer from "../features/audit/auditSlice";
import auditsReducer, {
  initialState as initialStateAudits,
} from "../features/audits/auditsSlice";
import { initialState as initialStateIncidents } from "../features/incidents/incidentsSlice";
import { initialState as initialStateServices } from "../features/services/servicesSlice";
import listsReducer from "../features/lists/listsSlice";
import newAuditReducer from "../features/newAudit/newAuditSlice";
import auditInformationReducer from "../features/auditInfo/auditInformationDataSlice";
import auditConfigurationReducer from "../features/auditConfig/auditConfigurationDataSlice";
import servicesReducer from "../features/services/servicesSlice";
import storeFilterFactory from "./storeFilterFactory";

const [getAuditStoredFilters, storeFilterMiddlewareAudit] = storeFilterFactory(
  "MontelInspectorFilters",
  "audits/toggleFilter"
);

const [getIncidentStoredFilters, storeFilterMiddlewareIncident] =
  storeFilterFactory(
    "MontelInspectorFiltersIncidents",
    "incidents/toggleFilter"
  );

const [getServicesStoredFilters, storeFilterMiddlewareServices] =
  storeFilterFactory("MontelInspectorFiltersServices", "services/toggleFilter");

export const store = configureStore({
  reducer: {
    incidents: incidentsReducer,
    notes: notesReducer,
    session: sessionReducer,
    audit: auditReducer,
    audits: auditsReducer,
    lists: listsReducer,
    newAudit: newAuditReducer,
    auditInformationData: auditInformationReducer,
    auditConfigurationData: auditConfigurationReducer,
    services: servicesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      storeFilterMiddlewareAudit,
      storeFilterMiddlewareIncident,
      storeFilterMiddlewareServices
    ),
  preloadedState: {
    audits: {
      ...initialStateAudits,
      filters: [...getAuditStoredFilters()],
    },
    incidents: {
      ...initialStateIncidents,
      filters: [...getIncidentStoredFilters()],
    },
    services: {
      ...initialStateServices,
      filters: [...getServicesStoredFilters()],
    },
  } as any,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  unknown,
  unknown,
  Action<string>
>;
