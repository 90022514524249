import { toast, Slide } from "react-toastify";

class UpdateToast {
  id: any;

  constructor() {
    this.id = undefined;
  }

  loading(text = "Updating..."): UpdateToast {
    this.id = toast.loading(text, {
      position: "top-center",
      transition: Slide,
    });
    return this;
  }
  success(text = "Updated"): void {
    toast.update(this.id, {
      type: "success",
      render: text,
      isLoading: false,
      autoClose: 1000,
    });
  }
  error(text = "Something went wrong, data was not submitted"): void {
    toast.update(this.id, {
      type: "error",
      render: text,
      isLoading: false,
      autoClose: 5000,
      closeButton: true,
    });
  }
}

export default UpdateToast;
