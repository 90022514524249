import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Notes from "../notes/Notes";
import useOnMount from "../../hooks/useOnMount";
import { fetchNotes } from "../notes/notesThunks";
import { getAuditId } from "./auditSlice";
import styles from "./audit.module.scss";

const NotesTab: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const auditId = useAppSelector(getAuditId);

  useOnMount(() => {
    dispatch(fetchNotes(auditId));
  });
  return (
    <div className={`${styles.tabContent} ${styles.elementsContainer}`}>
      <Notes />
    </div>
  );
};

export default NotesTab;
