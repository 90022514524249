import getOptionByKey, { IOption } from "./getOptionByKey";

const getOptionByValue = (
  options: IOption[] | undefined,
  value: number | string | undefined | null
) => {
  if (!options) return undefined;
  return getOptionByKey(options, value, "value");
};

export default getOptionByValue;
