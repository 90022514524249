import { SortOrder } from "../../components/SortableTable/sortableTableTypes";

const comparatorNumeric = (key: string, order: SortOrder) => {
  return <T extends { [key: string]: any }>(a: T, b: T) => {
    if (a[key] === undefined && b[key] === undefined) return 0;
    if (a[key] === undefined) return 1;
    if (b[key] === undefined) return -1;
    return (a[key] > b[key] ? 1 : -1) * (order === SortOrder.ASC ? 1 : -1);
  };
};

export default comparatorNumeric;
