import { FunctionComponent } from "react";
import { mdiCached, mdiHomeImportOutline } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { Button } from "@montel/montelpro-shared-components";
import styles from "./apiError.module.scss";

const ApiError: FunctionComponent<{ error: any }> = ({ error }) => {
  const { message } = error || {};
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <h3>There seems to have been an error</h3>
      <p className={styles.errorDescription}>
        {message || "Please try reloading the page or contacting support."}
      </p>
      <div className={styles.buttonGroup}>
        <Button
          text={"Reload page"}
          size={"small"}
          iconLeft={<Icon path={mdiCached} />}
          onClick={() => window.location.reload()}
        />
        <Button
          text={"To start page"}
          size={"small"}
          iconLeft={<Icon path={mdiHomeImportOutline} />}
          onClick={() => navigate("/")}
        />
      </div>
    </div>
  );
};

export default ApiError;
