import { TStatusLabelColors } from "../../components/StatusLabel/StatusLabel";
import { ResourceStatus } from "../../shared/enums";

const getAuditStatusLabelColor = (text: string): TStatusLabelColors => {
  const labelToClassMapping: { [index: string]: TStatusLabelColors } = {
    [ResourceStatus.OK]: "green",
    [ResourceStatus.FAILED]: "red",
    [ResourceStatus.EXCEPTION]: "yellow",
  };
  return labelToClassMapping[text] || "gray";
};

export default getAuditStatusLabelColor;
