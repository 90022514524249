import { FunctionComponent } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../app/hooks";
import { getIsAssignedToCurrentUser } from "../../../incidentsSlice";
import { updateIncidentAssignee } from "../../../incidentsThunks";
import { getUserId } from "../../../../session/sessionSlice";
import MenuItem from "../../../../../components/ContextMenu/MenuItem";
import { mdiHumanGreeting, mdiRunFast } from "@mdi/js";

export interface AssigneeButtonProps {
  incidentId: number;
}
const AssigneeButton: FunctionComponent<AssigneeButtonProps> = ({
  incidentId,
}) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getUserId);
  const isAssignedToCurrentUser = useAppSelector(
    getIsAssignedToCurrentUser(incidentId)
  );
  const text = isAssignedToCurrentUser
    ? "Remove me as assignee"
    : "Assign to me";
  const payload = {
    incidentId,
    assigneeId: isAssignedToCurrentUser ? undefined : userId,
  };

  return (
    <MenuItem
      iconPath={isAssignedToCurrentUser ? mdiRunFast : mdiHumanGreeting}
      text={text}
      onClick={() => dispatch(updateIncidentAssignee(payload))}
    />
  );
};

export default AssigneeButton;
