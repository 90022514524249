import { createSlice } from "@reduxjs/toolkit";
import { IAuditInfo, IAuditInfoDataState } from "./auditInformationTypes";
import { RootState } from "../../app/store";
import { isDeepEqual, replaceUndefinedToNull } from "../../utils/objectUtils";
import { Priority } from "../../shared/enums";

export const initialStateData: IAuditInfo = {
  description: "",
  relatedSystems: [],
  solutionProposal: "",
  priority: Priority.Medium,
};

export const initialState: IAuditInfoDataState = {
  masterData: initialStateData,
  data: initialStateData,
  showValidationErrors: false,
};

export const slice = createSlice({
  name: "auditInformationData",
  initialState,
  reducers: {
    setSolutionProposal: (state, { payload }) => {
      state.data.solutionProposal = payload;
    },
    setDescription: (state, { payload }) => {
      state.data.description = payload;
    },
    setRelatedSystems: (state, { payload }) => {
      state.data.relatedSystems = payload;
    },
    setPriority: (state, { payload }) => {
      state.data.priority = payload;
    },
    initializeInfo: (state, { payload }) => {
      state.masterData = payload as IAuditInfo;
      state.data = payload as IAuditInfo;
    },
    revertChangesInfo: (state) => {
      state.data = state.masterData;
    },
    persistChangesInfo: (state) => {
      state.masterData = state.data;
    },
    setShowValidationErrorsInfo: (state) => {
      state.showValidationErrors = true;
    },
    resetStateInfo: (state) => {
      return { ...initialState };
    },
  },
});

export const {
  setSolutionProposal,
  setDescription,
  setRelatedSystems,
  setPriority,
  initializeInfo,
  revertChangesInfo,
  persistChangesInfo,
  setShowValidationErrorsInfo,
  resetStateInfo,
} = slice.actions;

export const getState = (state: RootState) => state[slice.name];
export const getAuditInfo = (state: RootState) => getState(state).data;
export const getIsDataDirtyInfo = (state: RootState) =>
  !isDeepEqual(getAuditInfo(state), getState(state).masterData);
export const getPayloadInfo = (state: RootState) => {
  const data = getAuditInfo(state);
  return replaceUndefinedToNull({
    ...data,
    relatedSystems: data.relatedSystems.map((option) => option.value),
  });
};
export const getShowValidationErrors = (state: RootState) =>
  getState(state).showValidationErrors;

export const getIsDescriptionValid = (state: RootState) =>
  Boolean(getAuditInfo(state).description);
export const getIsSolutionProposalValid = (state: RootState) =>
  Boolean(getAuditInfo(state).solutionProposal);
export const getIsRelatedSystemsValid = (state: RootState) =>
  Boolean(getAuditInfo(state).relatedSystems.length);

export const getIsDataValidInfo = (state: RootState) =>
  [
    getIsDescriptionValid(state),
    getIsSolutionProposalValid(state),
    getIsRelatedSystemsValid(state),
  ].every((isValid) => isValid);

export default slice.reducer;
