import { FunctionComponent } from "react";
import { Button, Select } from "@montel/montelpro-shared-components";
import { SortOrder } from "./sortableTableTypes";
import { SortableTableHeadProps } from "./SortableTableHead";
import { getOptionByValue } from "../../utils/objectUtils";
import Icon from "@mdi/react";
import { mdiSortAscending, mdiSortDescending } from "@mdi/js";
import styles from "./sortableTable.module.scss";

const SortableTableHeadMobile: FunctionComponent<SortableTableHeadProps> = ({
  columns,
  sortField,
  setSortField,
  order,
  setOrder,
}) => {
  const options = columns
    .filter(({ sortable }) => sortable)
    .map((column) => ({ ...column, value: column.key }));

  const handleSortingChange = (key: string) => {
    setSortField(key);
  };

  const handleOrderChange = () => {
    const sortOrder = order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    setOrder(sortOrder);
  };

  if (options.length === 0) return null;

  return (
    <div className={styles.mobileTableHead}>
      <Select
        className={styles.sortFieldSelect}
        label={"Sort by"}
        labelWithin
        options={options}
        value={getOptionByValue(options, sortField)}
        onChange={({ key }) => handleSortingChange(key)}
      />
      <Button
        className={styles.sortOrderButton}
        text={""}
        kind={"tertiary"}
        iconRight={
          <Icon
            path={
              order === SortOrder.ASC ? mdiSortAscending : mdiSortDescending
            }
          />
        }
        onClick={() => handleOrderChange()}
      />
    </div>
  );
};

export default SortableTableHeadMobile;
