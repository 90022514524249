import { FunctionComponent, useState } from "react";
import { IFilterHandler, MenuConfig } from "./Types";
import MenuLevelOne from "./MenuLevelOne";
import MenuLevelTwo from "./MenuLevelTwo";
import styles from "./menu.module.scss";

export interface IMenuProps extends IFilterHandler {
  menuConfig: MenuConfig;
}

const Menu: FunctionComponent<IMenuProps> = ({
  menuConfig,
  toggleFilterAction,
  isFilterSelectedSelector,
}) => {
  const [activeCategory, setActiveCategory] = useState("");

  return (
    <div className={`${styles.filterMenuWrapper}`}>
      {!activeCategory ? (
        <MenuLevelOne
          onClick={(category: string) => setActiveCategory(category)}
          menuConfig={menuConfig}
        />
      ) : (
        <MenuLevelTwo
          activeCategory={activeCategory}
          resetCategory={() => setActiveCategory("")}
          menuConfig={menuConfig}
          isFilterSelectedSelector={isFilterSelectedSelector}
          toggleFilterAction={toggleFilterAction}
        />
      )}
    </div>
  );
};

export default Menu;
