import { IAuditApi } from "../auditTypes";
import { replaceNullToUndefined } from "../../../utils/objectUtils";
import timeObjectToString from "./timeObjectToString";
import { IAuditConfig } from "../../auditConfig/auditConfigurationTypes";

const translateToAuditConfig = ({
  name,
  connectionId,
  statement,
  schedule,
  auditCondition,
}: IAuditApi): IAuditConfig => {
  const { runOnceStart, runStart, runStop, runStep, daysOfWeek } = schedule;
  const { conditionOperator, operandType, expectedResult } = auditCondition;
  return replaceNullToUndefined({
    name,
    connectionId,
    statement,
    conditionOperator,
    operandType,
    expectedResult,
    runOnceStart: timeObjectToString(runOnceStart),
    runStart: timeObjectToString(runStart),
    runStop: timeObjectToString(runStop),
    runStep,
    daysOfWeek,
  });
};

export default translateToAuditConfig;
