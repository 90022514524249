import { ITimeApi } from "../auditTypes";

const stringToTimeObject = (
  timeString: string | undefined | null
): ITimeApi | null => {
  if (!timeString) return null;
  const timeComponents = timeString.split(":");
  if (timeComponents.length < 2) return null;
  return { hour: Number(timeComponents[0]), minute: Number(timeComponents[1]) };
};

export default stringToTimeObject;
