import { FunctionComponent } from "react";
import { Input } from "@montel/montelpro-shared-components";
import styles from "./newAudit.module.scss";

const EditName: FunctionComponent<{
  value: string;
  setValue: (newValue: string) => void;
  isValid: boolean;
  showValidationErrors: boolean;
  className?: string;
}> = ({ value, setValue, isValid, showValidationErrors, className }) => {
  const errorMessage = value
    ? "This name is already taken"
    : "Name should not be empty";
  return (
    <Input
      label={"Name"}
      value={value}
      onChange={(event: any) => setValue(event.target.value)}
      className={styles.input}
      errorMessage={showValidationErrors && !isValid ? errorMessage : undefined}
      autoFocus
    />
  );
};

export default EditName;
