import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { loginRequestScopes } from "./authConfig";

const useInitAuth = (): { authError: any } => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress: status } = useMsal();
  const [authError, setAuthError] = useState();
  const [isHandlingRedirect, setIsHandlingRedirect] = useState(false);

  useEffect(() => {
    if (status === InteractionStatus.HandleRedirect) {
      setIsHandlingRedirect(true);
    }
  }, [status]);

  useEffect(() => {
    if (
      !isAuthenticated &&
      !isHandlingRedirect &&
      status === InteractionStatus.None
    ) {
      //redirects to login.microsoftonline.com
      //ends up at /auth-complete and then back to current page
      instance
        .loginRedirect(loginRequestScopes)
        .catch((error) => setAuthError(error));
    }
  }, [status, instance, isAuthenticated, isHandlingRedirect]);

  return { authError };
};

export default useInitAuth;
