import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getPersonIdNameMapping } from "../lists/listsSlice";
import axios from "axios";
import apiRoutes from "../../apiRoutes";
import { IIncident, IIncidentApi } from "./incidentsTypes";
import translateApiIncident from "./translateApiIncident";
import UpdateToast from "../../utils/UpdateToast";

export const fetchIncidents = createAsyncThunk(
  "incidents/fetch",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const personIdNameMapping: { [id: string]: string } =
      getPersonIdNameMapping(state);
    const response = await axios.get(apiRoutes.incidents);
    return (response.data || ([] as IIncidentApi[]))
      .map((x: any) => translateApiIncident(x))
      .map((x: IIncident) => ({
        ...x,
        assigneeName: x.assigneeId
          ? personIdNameMapping[x.assigneeId]
          : "Unassigned",
      }));
  }
);

export const fetchIncidentsByAuditId = createAsyncThunk(
  "incidents/fetchByAudit",
  async (id: number | undefined) => {
    const response = await axios.get(apiRoutes.auditsIncidents(id));
    return (response.data || ([] as IIncidentApi[])).map((x: any) =>
      translateApiIncident(x)
    );
  }
);

export const updateIncidentAssignee = createAsyncThunk(
  "incidents/updateAssignee",
  async ({
    incidentId,
    assigneeId,
  }: {
    incidentId: number;
    assigneeId: string | undefined;
  }) => {
    const toast = new UpdateToast().loading();
    try {
      await axios.patch(apiRoutes.incidentSetAssignee(incidentId), {
        assigneeId: assigneeId ? assigneeId : null,
      });
      toast.success("Assignee has been updated.");
    } catch (error: any) {
      toast.error();
      throw error;
    }
  }
);

export const updateIncidentPostponedTo = createAsyncThunk(
  "incidents/updatePostponedTo",
  async ({
    incidentId,
    postponedTo,
  }: {
    incidentId: number;
    postponedTo: string;
  }) => {
    const toast = new UpdateToast().loading();
    try {
      await axios.patch(apiRoutes.incidentPostpone(incidentId), {
        postponedTo,
      });
      toast.success("Incident has been postponed.");
    } catch (error: any) {
      toast.error();
      throw error;
    }
  }
);

export const updateIncidentComplete = createAsyncThunk(
  "incidents/complete",
  async (incidentId: number) => {
    const toast = new UpdateToast().loading();
    try {
      await axios.patch(apiRoutes.incidentComplete(incidentId), {
        incidentId,
      });
      toast.success("Incident has been completed.");
    } catch (error: any) {
      toast.error();
      throw error;
    }
  }
);
