import { FunctionComponent } from "react";
import { mdiDelete } from "@mdi/js";
import { useAppDispatch } from "../../../../app/hooks";
import { fetchServices, forgetService } from "../../servicesThunks";
import MenuItem from "../../../../components/ContextMenu/MenuItem";

export interface ForgetServiceButtonProps {
  serviceName: string;
}

const ForgetServiceButton: FunctionComponent<ForgetServiceButtonProps> = ({
  serviceName,
}) => {
  const dispatch = useAppDispatch();

  const onDelete = async () => {
    const isSuccess = await dispatch(forgetService({ serviceName }));
    if (isSuccess) dispatch(fetchServices());
  };
  return (
    <MenuItem iconPath={mdiDelete} text={"Forget service"} onClick={onDelete} />
  );
};

export default ForgetServiceButton;
