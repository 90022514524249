import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import apiRoutes from "../../apiRoutes";
import { AppThunk, RootState } from "../../app/store";
import UpdateToast from "../../utils/UpdateToast";
import {
  initializeConfig,
  persistChangesConfig,
  revertChangesConfig,
  setShowValidationErrorsConfig,
} from "../auditConfig/auditConfigurationDataSlice";
import {
  initializeInfo,
  persistChangesInfo,
  revertChangesInfo,
  setShowValidationErrorsInfo,
} from "../auditInfo/auditInformationDataSlice";
import { AuditViewTabs } from "./auditEnums";
import {
  getCurrentTab,
  getIsEnabled,
  getPayload,
  toggleIsEnabled,
  getAuditId,
  isOnInfoTab,
  getIsDataDirty,
  getSqlExecutionPayload,
  getIsSubscribedToAudit,
  toggleUserAuditSubscription,
} from "./auditSlice";
import { getUserId } from "../session/sessionSlice";
import translateAuditGeneralData from "./utils/translateAuditGeneralData";
import translateToAuditConfig from "./utils/translateToAuditConfig";
import translateToAuditInfo from "./utils/translateToAuditInfo";

export const fetchAuditById = createAsyncThunk(
  "audit/fetchAuditById",
  async (id: number | undefined, { dispatch }) => {
    if (!id) return undefined;
    const response = await axios.get(`${apiRoutes.audits}/${id}`);
    if (!response.data) return;
    dispatch(initializeConfig(translateToAuditConfig(response.data)));
    dispatch(initializeInfo(translateToAuditInfo(response.data)));
    return translateAuditGeneralData(response.data);
  }
);

export const saveData = createAsyncThunk(
  "audit/saveData",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    if (!getIsDataDirty(state)) return;
    const id = getAuditId(state);
    const payload = getPayload(state);
    const apiRoute = isOnInfoTab(state)
      ? apiRoutes.auditInfo(id)
      : apiRoutes.auditConfig(id);
    try {
      await axios.patch(apiRoute, payload);
      dispatch(persistChanges());
    } catch (error: any) {
      toast.error("Something went wrong. Audit was not saved properly.");
      throw error;
    }
  }
);

export const toggleIsSchedulingEnabled = createAsyncThunk(
  "audit/toggleIsSchedulingEnabled",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const isEnabled = getIsEnabled(state);
    const id = getAuditId(state);
    const url = isEnabled
      ? apiRoutes.auditPause(String(id))
      : apiRoutes.auditResume(String(id));
    dispatch(toggleIsEnabled());
    const toast = new UpdateToast().loading();
    try {
      await axios.get(url);
      toast.success(
        isEnabled ? "You have disabled audit." : "You have enabled audit."
      );
    } catch (error: any) {
      toast.error();
      throw error;
    }
  }
);

export const runAudit = createAsyncThunk(
  "runAudit/fetch",
  async (id: number | undefined) => {
    const toast = new UpdateToast().loading();
    try {
      await axios.get(apiRoutes.auditRun(id));
      toast.success("Request to run audit was sent.");
    } catch (error: any) {
      toast.error("Something went wrong. Audit did not run.");
      throw error;
    }
  }
);

export const runSqlQuery = createAsyncThunk(
  "audit/runSql",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const payload = getSqlExecutionPayload(state);
    const response = await axios.post(apiRoutes.auditRunSql, payload, {
      timeout: 10000,
    });
    return !response.data
      ? undefined
      : { ...response.data.result, error: response.data.error };
  }
);

export const toggleAuditSubscription = createAsyncThunk(
  "audit/toggleSubscription",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const auditId = getAuditId(state);
    const userId = getUserId(state);
    const isUserSubscribed = getIsSubscribedToAudit(state);
    const url = isUserSubscribed
      ? apiRoutes.auditUnsubscribe(auditId)
      : apiRoutes.auditSubscribe(auditId);
    const toast = new UpdateToast().loading();
    try {
      await axios.patch(url);
      dispatch(toggleUserAuditSubscription(userId));
      toast.success("You have subscribed to audit updates.");
    } catch (error: any) {
      toast.error();
      throw error;
    }
  }
);

export const revertChanges = (): AppThunk => (dispatch, getState) => {
  const currentTab = getCurrentTab(getState() as RootState);
  switch (currentTab) {
    case AuditViewTabs.INFORMATION:
      dispatch(revertChangesInfo());
      return;
    case AuditViewTabs.CONFIGURATION:
      dispatch(revertChangesConfig());
      return;
    default:
      return;
  }
};

export const persistChanges = (): AppThunk => (dispatch, getState) => {
  const currentTab = getCurrentTab(getState() as RootState);
  switch (currentTab) {
    case AuditViewTabs.INFORMATION:
      dispatch(persistChangesInfo());
      return;
    case AuditViewTabs.CONFIGURATION:
      dispatch(persistChangesConfig());
      return;
    default:
      return;
  }
};

export const setShowErrors = (): AppThunk => (dispatch, getState) => {
  const currentTab = getCurrentTab(getState() as RootState);
  switch (currentTab) {
    case AuditViewTabs.INFORMATION:
      dispatch(setShowValidationErrorsInfo());
      return;
    case AuditViewTabs.CONFIGURATION:
      dispatch(setShowValidationErrorsConfig());
      return;
    default:
      return;
  }
};
