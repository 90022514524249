import {
  IServiceStatusMessageApi,
  IServiceStatusMessage,
  ServiceStatusTextMapping,
} from "./servicesTypes";
import formatTimestamp from "../../utils/dateTimeUtils/formatTimestamp";

const translateApiServices = ({
  name,
  lastUpdated,
  status,
  relatedSystemId,
  host,
}: IServiceStatusMessageApi): IServiceStatusMessage => ({
  name,
  lastUpdated,
  status,
  relatedSystemId: relatedSystemId || undefined,
  host,
  statusText: ServiceStatusTextMapping(status),
  lastUpdatedFormatted: lastUpdated ? formatTimestamp(lastUpdated) : "Unknown",
});

export default translateApiServices;
