import { FunctionComponent } from "react";
import { SelectOptionType } from "@montel/montelpro-shared-components";
import RelatedSystemLink from "./RelatedSystemLink";

interface RelatedSystemLinksProps {
  systems: SelectOptionType[];
}

const RelatedSystemLinks: FunctionComponent<RelatedSystemLinksProps> = ({
  systems = [],
}) => {
  if (systems.length === 0) return <>None</>;
  return (
    <>
      {systems
        .flatMap((system, idx) => [
          <RelatedSystemLink id={system.value} key={system.value} />,
          <span key={idx}>, </span>,
        ])
        .slice(0, -1)}
    </>
  );
};

export default RelatedSystemLinks;
