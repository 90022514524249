import { IAuditApi } from "../auditTypes";
import { replaceNullToUndefined } from "../../../utils/objectUtils";
import { IAuditInfo } from "../../auditInfo/auditInformationTypes";
import translateAPIObjectToOption from "../../../utils/objectUtils/translateAPIObjectToOption";

const translateToAuditInfo = ({
  description,
  relatedSystems,
  solutionProposal,
  priority,
}: IAuditApi): IAuditInfo =>
  replaceNullToUndefined({
    description,
    relatedSystems: relatedSystems.map(translateAPIObjectToOption),
    solutionProposal,
    priority,
  });

export default translateToAuditInfo;
