import { FunctionComponent } from "react";
import { useAppSelector } from "../../../app/hooks";
import { getAuditId, getIsEnabled, getPersistedName } from "../auditSlice";
import styles from "./header.module.scss";

const Header: FunctionComponent = () => {
  const id = useAppSelector(getAuditId);
  const name = useAppSelector(getPersistedName);
  const isEnabled = useAppSelector(getIsEnabled);
  return (
    <div className={styles.header}>
      <h1>
        <span>Audit {id}</span> {name}
        {!isEnabled && <span className={styles.disabledLabel}>Disabled</span>}
      </h1>
    </div>
  );
};

export default Header;
