import { FunctionComponent } from "react";
import { mdiToggleSwitchOff, mdiToggleSwitchOutline } from "@mdi/js";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { getIsEnabled } from "../auditSlice";
import { getIsAdmin } from "../../session/sessionSlice";
import { toggleIsSchedulingEnabled } from "../auditThunks";
import MenuItem from "../../../components/ContextMenu/MenuItem";

const DisableButton: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const isEnabled = useAppSelector(getIsEnabled);
  const isAdmin = useAppSelector(getIsAdmin);
  const iconPath = isEnabled ? mdiToggleSwitchOff : mdiToggleSwitchOutline;
  const text = isEnabled ? "Disable" : "Enable";

  return (
    <MenuItem
      iconPath={iconPath}
      text={text}
      disabled={!isAdmin}
      onClick={() => dispatch(toggleIsSchedulingEnabled())}
    />
  );
};

export default DisableButton;
