import { FunctionComponent } from "react";
import { useAppSelector } from "../../../app/hooks";
import FilterChip from "./FilterChip";
import { MenuConfig } from "../Types";
import styles from "../menu.module.scss";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

export interface IFilterChipsProps {
  menuConfig: MenuConfig;
  toggleFilter: ActionCreatorWithPayload<any, string>;
  getFilters: (state: RootState) => any;
}

const FilterChips: FunctionComponent<IFilterChipsProps> = ({
  menuConfig,
  toggleFilter,
  getFilters,
}) => {
  const filters = useAppSelector(getFilters) as {
    category: string;
    value: any;
  }[];
  if (filters.length === 0) return null;
  return (
    <div className={styles.filterChipsWrapper}>
      {filters.map(({ category, value }) => (
        <FilterChip
          category={category}
          value={value}
          key={`${category} ${value}`}
          menuConfig={menuConfig}
          toggleFilter={toggleFilter}
        />
      ))}
    </div>
  );
};

export default FilterChips;
