export enum ComparisonOperator {
  EQUAL = "==",
  NOT_EQUAL = "!=",
  GREATER = ">",
  GREATER_EQUAL = ">=",
  LESS = "<",
  LESS_EQUAL = "<=",
}

export enum ComparisonValueType {
  INT = "int",
  STRING = "string",
  NULL = "null",
}

export enum SchedulingModeValues {
  ONCE = "once_a_day",
  REPEAT = "repeat",
}
