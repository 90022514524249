import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@montel/montelpro-shared-components";
import image from "./teleport.png";
import styles from "./contentNotFound.module.scss";

const ContentNotFound: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.contentNotFound}>
      <h1>404: The Page is Not Found</h1>
      <img src={image} alt="Teleport" />
      <Button text={"To start page"} onClick={() => navigate("/")} />
    </div>
  );
};

export default ContentNotFound;
