import { Suspense } from "@montel/montelpro-shared-components";
import { FunctionComponent } from "react";
import { useAppSelector } from "../../app/hooks";
import ApiError from "../../components/ApiError/ApiError";
import AddNewNote from "./AddNewNote";
import NoteCard from "./NoteCard";
import { getNotes, getError, getStatus } from "./notesSelectors";

const Notes: FunctionComponent = () => {
  const notes = useAppSelector(getNotes);
  const status = useAppSelector(getStatus);
  const error = useAppSelector(getError);
  return (
    <div>
      <Suspense status={status} customError={<ApiError error={error} />}>
        <AddNewNote />
        <div>
          {notes.map((note) => (
            <NoteCard key={note.id} {...note} />
          ))}
        </div>
      </Suspense>
    </div>
  );
};

export default Notes;
