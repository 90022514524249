import { FunctionComponent } from "react";
import EditName from "./EditName";
import EditInformation from "../auditInfo/EditInformation";
import {
  getIsNameValid,
  getName,
  setName as setNameSlice,
} from "./newAuditSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getShowValidationErrors as getShowValidationErrorsInfo } from "../auditInfo/auditInformationDataSlice";
import useOnMount from "../../hooks/useOnMount";
import { fetchAudits, getIsDataLoaded } from "../audits/auditsSlice";

const InformationStep: FunctionComponent = () => {
  const name = useAppSelector(getName);
  const isNameValid = useAppSelector(getIsNameValid);
  const showValidationErrors = useAppSelector(getShowValidationErrorsInfo);
  const dispatch = useAppDispatch();
  const setName = (value: string) => dispatch(setNameSlice(value));
  const isAuditsLoaded = useAppSelector(getIsDataLoaded);

  useOnMount(() => {
    !isAuditsLoaded && dispatch(fetchAudits());
  });

  return (
    <>
      <EditName
        value={name}
        setValue={setName}
        isValid={isNameValid}
        showValidationErrors={showValidationErrors}
      />
      <EditInformation />
    </>
  );
};

export default InformationStep;
