import { FunctionComponent } from "react";
import LoginButton from "./LoginButton";

const AuthError: FunctionComponent = () => (
  <div>
    <h1>Login error</h1>
    <p>Oops, something went wrong. Please try again.</p>
    <LoginButton />
  </div>
);
export default AuthError;
