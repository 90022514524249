import { FunctionComponent } from "react";
import { useAppSelector } from "../../app/hooks";
import ButtonsBar from "./buttonsBar/ButtonsBar";
import { getIsEditMode } from "./auditSlice";
import { getIsAdmin } from "../session/sessionSlice";
import EditConfiguration from "../auditConfig/EditConfiguration";
import ViewConfiguration from "../auditConfig/ViewConfiguration";
import styles from "./audit.module.scss";

const ConfigurationTab: FunctionComponent = () => {
  const isEditMode = useAppSelector(getIsEditMode);
  const isAdmin = useAppSelector(getIsAdmin);

  return (
    <>
      <div className={`${styles.tabContent} ${styles.elementsContainer}`}>
        {isEditMode ? <EditConfiguration /> : <ViewConfiguration />}
      </div>
      {isAdmin && <ButtonsBar />}
    </>
  );
};

export default ConfigurationTab;
