import { useEffect, useState } from "react";
import { ISortableTableColumn, SortOrder } from "./sortableTableTypes";
import { SortableTableHeadProps } from "./SortableTableHead";

const defaultComparator = (key: string, order: SortOrder) => {
  return <T extends { [key: string]: any }>(a: T, b: T) => {
    if (a[key] === undefined && b[key] === undefined) return 0;
    if (a[key] === undefined) return 1;
    if (b[key] === undefined) return -1;
    return (
      (a[key].toString() > b[key].toString() ? 1 : -1) *
      (order === SortOrder.ASC ? 1 : -1)
    );
  };
};

const getSortedData = (
  data: any[],
  sortColumn: ISortableTableColumn | undefined,
  sortOrder: SortOrder | undefined
) => {
  if (!sortColumn) return data;
  const {
    key,
    order: defaultOrder,
    comparator: comparatorBuilder,
  } = sortColumn;
  const sorted = [...data];
  const order = sortOrder || defaultOrder || SortOrder.ASC;
  const comparator = comparatorBuilder
    ? comparatorBuilder(key, order)
    : defaultComparator(key, order);
  sorted.sort(comparator);
  return sorted;
};

export const useSortableTable = (
  data: any[],
  columns: ISortableTableColumn[]
) => {
  const [tableData, setTableData] = useState<any[]>([]);

  const defaultSortBy = columns.find(({ order }) => order !== undefined);
  const [sortField, setSortField] = useState<string>(defaultSortBy?.key || "");
  const [order, setOrder] = useState<SortOrder | undefined>(
    defaultSortBy?.order
  );

  useEffect(() => {
    const sortBy = sortField
      ? columns.find((column) => column.key === sortField)
      : defaultSortBy;
    if (sortBy) setTableData(getSortedData(data, sortBy, order));
  }, [data, defaultSortBy, sortField, order, columns]);

  return [
    tableData,
    {
      sortField,
      setSortField,
      order,
      setOrder,
    } as Partial<SortableTableHeadProps>,
  ];
};
