import { RootState } from "../../app/store";
import { createSlice } from "@reduxjs/toolkit";
import { IAuditConfig, IAuditConfigDataState } from "./auditConfigurationTypes";
import {
  ComparisonOperator,
  ComparisonValueType,
  SchedulingModeValues,
} from "./auditConfigurationEnums";
import { isDeepEqual } from "../../utils/objectUtils";
import translateToAuditConfigApi from "./translateToAuditConfigApi";

export const initialStateData: IAuditConfig = {
  connectionId: undefined,
  statement: "",
  conditionOperator: ComparisonOperator.EQUAL,
  operandType: ComparisonValueType.NULL,
  expectedResult: undefined,
  runOnceStart: undefined,
  runStart: undefined,
  runStop: undefined,
  runStep: undefined,
  daysOfWeek: [],
};

export const initialState: IAuditConfigDataState = {
  masterData: initialStateData,
  data: initialStateData,
  showValidationErrors: false,
  selectedSchedulingMode: SchedulingModeValues.ONCE,
};

export const slice = createSlice({
  name: "auditConfigurationData",
  initialState,
  reducers: {
    setConnectionId: (state, { payload }) => {
      state.data.connectionId = payload;
    },
    setStatement: (state, { payload }) => {
      state.data.statement = payload;
    },
    setExpectedResultOperator: (state, { payload }) => {
      state.data.conditionOperator = payload;
    },
    setExpectedResultValueType: (state, { payload }) => {
      if (payload === ComparisonValueType.NULL)
        state.data.expectedResult = undefined;
      state.data.operandType = payload;
    },
    setExpectedResultValue: (state, { payload }) => {
      state.data.expectedResult = payload;
    },
    setScheduleRunOnce: (state, { payload }) => {
      state.data.runOnceStart = payload;
    },
    setScheduleStart: (state, { payload }) => {
      state.data.runStart = payload;
    },
    setScheduleStop: (state, { payload }) => {
      state.data.runStop = payload;
    },
    setScheduleStep: (state, { payload }) => {
      state.data.runStep = payload;
    },
    setSelectedSchedulingMode: (state, { payload }) => {
      state.selectedSchedulingMode = payload;
    },
    setDaysOfWeek: (state, { payload }) => {
      state.data.daysOfWeek = payload;
    },
    initializeConfig: (state, { payload }) => {
      state.data = payload as IAuditConfig;
      state.masterData = payload as IAuditConfig;
      state.selectedSchedulingMode = payload.runOnceStart
        ? SchedulingModeValues.ONCE
        : SchedulingModeValues.REPEAT;
    },
    revertChangesConfig: (state) => {
      state.data = state.masterData;
      state.selectedSchedulingMode = state.masterData.runOnceStart
        ? SchedulingModeValues.ONCE
        : SchedulingModeValues.REPEAT;
    },
    persistChangesConfig: (state) => {
      state.masterData = state.data;
    },
    setShowValidationErrorsConfig: (state) => {
      state.showValidationErrors = true;
    },
    resetStateConfig: (state) => {
      return { ...initialState };
    },
  },
});

export const {
  setConnectionId,
  setStatement,
  setExpectedResultOperator,
  setExpectedResultValueType,
  setExpectedResultValue,
  setScheduleRunOnce,
  setScheduleStart,
  setScheduleStop,
  setScheduleStep,
  setSelectedSchedulingMode,
  setDaysOfWeek,
  initializeConfig,
  revertChangesConfig,
  persistChangesConfig,
  setShowValidationErrorsConfig,
  resetStateConfig,
} = slice.actions;

export const getState = (state: RootState) => state[slice.name];
export const getAuditConfig = (state: RootState) => getState(state).data;
export const getAuditConnectionId = (state: RootState) =>
  getAuditConfig(state).connectionId;
export const getAuditStatement = (state: RootState) =>
  getAuditConfig(state).statement;
export const getAuditSelectedSchedulingMode = (state: RootState) =>
  getState(state).selectedSchedulingMode;
export const getShowValidationErrors = (state: RootState) =>
  getState(state).showValidationErrors;

export const getIsAuditScheduleFrequencyValid = (state: RootState) => {
  const { daysOfWeek } = getAuditConfig(state);
  return daysOfWeek.length > 0;
};

export const getIsAuditScheduleRunOnceStartValid = (state: RootState) => {
  if (!getIsRunOnce(state)) return true;
  const { runOnceStart } = getAuditConfig(state);
  return runOnceStart !== undefined;
};

export const getIsAuditScheduleStartValid = (state: RootState) => {
  if (getIsRunOnce(state)) return true;
  const { runStart } = getAuditConfig(state);
  return runStart !== undefined;
};

export const getIsAuditScheduleStopValid = (state: RootState) => {
  if (getIsRunOnce(state)) return true;
  const { runStop } = getAuditConfig(state);
  return runStop !== undefined;
};

export const getIsAuditScheduleTimesValid = (state: RootState) => {
  if (getIsRunOnce(state)) return true;

  const { runStart } = getAuditConfig(state);
  const { runStop } = getAuditConfig(state);

  if (!(runStart && runStop)) return true;

  return runStart < runStop;
};

export const getIsAuditScheduleStepValid = (state: RootState) => {
  if (getIsRunOnce(state)) return true;
  const { runStep } = getAuditConfig(state);
  return runStep !== undefined;
};

export const getIsRunOnce = (state: RootState) =>
  getAuditSelectedSchedulingMode(state) === SchedulingModeValues.ONCE;

export const getPayloadConfig = (state: RootState) => {
  const { data, selectedSchedulingMode } = getState(state);
  return translateToAuditConfigApi(data, selectedSchedulingMode);
};

export const getIsDataDirtyConfig = (state: RootState) =>
  !isDeepEqual(getAuditConfig(state), getState(state).masterData);

export const getIsStatementValid = (state: RootState) =>
  Boolean(getAuditConfig(state).statement);

export const getIsConnectionValid = (state: RootState) =>
  Boolean(getAuditConfig(state).connectionId);

export const getIsDaysOfWeekValid = (state: RootState) =>
  Boolean(getAuditConfig(state).daysOfWeek.length);

export const getIsConditionOperatorValid = (state: RootState) =>
  Boolean(getAuditConfig(state).conditionOperator);

export const getIsOperandTypeValid = (state: RootState) =>
  Boolean(getAuditConfig(state).operandType);

export const getIsExpectedResultValid = (state: RootState) => {
  const data = getAuditConfig(state);
  if (data.operandType === ComparisonValueType.NULL) return true;
  return Boolean(data.expectedResult);
};

export const getIsOperatorAndValueTypeComboValid = (state: RootState) => {
  const { conditionOperator, operandType } = getAuditConfig(state);

  if (!conditionOperator || !operandType) return true;

  const isInt = operandType === ComparisonValueType.INT;
  const isEqualityOperator = [
    ComparisonOperator.EQUAL,
    ComparisonOperator.NOT_EQUAL,
  ].includes(conditionOperator);

  return isInt || isEqualityOperator;
};

export const getIsDataValidConfig = (state: RootState) => {
  return [
    getIsConnectionValid(state),
    getIsStatementValid(state),
    getIsConditionOperatorValid(state),
    getIsOperandTypeValid(state),
    getIsExpectedResultValid(state),
    getIsOperatorAndValueTypeComboValid(state),
    getIsDaysOfWeekValid(state),
    getIsAuditScheduleFrequencyValid(state),
    getIsAuditScheduleRunOnceStartValid(state),
    getIsAuditScheduleStartValid(state),
    getIsAuditScheduleStopValid(state),
    getIsAuditScheduleTimesValid(state),
    getIsAuditScheduleStepValid(state),
  ].every((element) => element);
};

export default slice.reducer;
