import {
  ComparisonOperator,
  ComparisonValueType,
  SchedulingModeValues,
} from "./auditConfigurationEnums";
import { IDayOfWeek } from "./auditConfigurationTypes";

export const schedulingModeOptions = [
  {
    label: "Run once a day",
    value: SchedulingModeValues.ONCE,
  },
  {
    label: "Repeat",
    value: SchedulingModeValues.REPEAT,
  },
];

export const dayOfWeekOptions = [
  {
    label: "Mon",
    value: 1,
  },
  {
    label: "Tue",
    value: 2,
  },
  {
    label: "Wed",
    value: 3,
  },
  {
    label: "Thu",
    value: 4,
  },
  {
    label: "Fri",
    value: 5,
  },
  {
    label: "Sat",
    value: 6,
  },
  {
    label: "Sun",
    value: 7,
  },
] as IDayOfWeek[];

export const dayOfWeekPresets = [
  {
    label: "Daily",
    value: "daily",
    range: {
      start: 0,
      stop: 7,
    },
  },
  {
    label: "Weekdays",
    value: "weekdays",
    range: {
      start: 0,
      stop: 5,
    },
  },
  {
    label: "Weekends",
    value: "weekends",
    range: {
      start: 5,
      stop: 7,
    },
  },
];

export const ComparisonOperatorToText = {
  "==": "equals",
  "!=": "not equals",
  ">": "is greater than",
  ">=": "is greater than or equals",
  "<": "is less than",
  "<=": "is less than or equals",
};

export const comparisonOperatorOptions = Object.values(ComparisonOperator).map(
  (operator: string) => ({
    value: operator,
    label: operator,
  })
);

export const comparisonValueTypeOptions = [
  { value: ComparisonValueType.INT, label: "Int value" },
  { value: ComparisonValueType.STRING, label: "String value" },
  { value: ComparisonValueType.NULL, label: "Null" },
];
