import { ComparisonOperator } from "../auditConfigurationEnums";
import { ComparisonOperatorToText } from "../auditConfigurationOptions";

const getExpectedResultDescription = (
  operator: ComparisonOperator | undefined,
  value: string | number | undefined
) => {
  if (!operator) return "";
  return `Result ${operator ? ComparisonOperatorToText[operator] : ""} ${
    value === undefined ? "Null" : value
  }.`;
};
export default getExpectedResultDescription;
