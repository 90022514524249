import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getIsWaitingForRunResult,
  getAuditGeneralData,
  getAuditId,
} from "../auditSlice";
import getAuditStatusLabelColor from "../../../utils/uiUtils/getAuditStatusLabelColor";
import Icon from "@mdi/react";
import { mdiPlay } from "@mdi/js";
import { Button } from "@montel/montelpro-shared-components";
import { StatusLabel } from "../../../components/StatusLabel/StatusLabel";
import { toTitleCase } from "../../../utils/textUtils";
import LabelledContent from "../../../components/LabelledContent/LabelledContent";
import styles from "./auditRunStatus.module.scss";
import formatTimestamp from "../../../utils/dateTimeUtils/formatTimestamp";
import { runAudit } from "../auditThunks";
import useElementHighlight from "../../../hooks/useHighlightOnChange";
import { mdiAutorenew } from "@mdi/js";

export const AuditRunStatus: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { lastRunDateTime, lastRunStatus, auditError } =
    useAppSelector(getAuditGeneralData) || {};
  const auditId = useAppSelector(getAuditId);
  const isWaitingForRunResult = useAppSelector(getIsWaitingForRunResult);
  const [highlight] = useElementHighlight(lastRunDateTime, 1500);

  return (
    <div className={styles.container}>
      <div className={styles.auditRunStatus}>
        <LabelledContent
          label={"Last run status:"}
          className={highlight ? styles.highlight : ""}
        >
          <StatusLabel
            className={highlight ? styles.highlight : ""}
            text={toTitleCase(lastRunStatus || "")}
            color={getAuditStatusLabelColor(lastRunStatus || "")}
          />
        </LabelledContent>
        <LabelledContent
          className={highlight ? styles.highlight : ""}
          label={"Date:"}
          children={
            lastRunDateTime ? formatTimestamp(lastRunDateTime) : "Unknown"
          }
        />

        {isWaitingForRunResult ? (
          <Button
            text="Waiting..."
            iconRight={<Icon path={mdiAutorenew} />}
            rotateRightIcon
            className={styles.runNowButton}
          />
        ) : (
          <Button
            text={"Run now"}
            iconLeft={<Icon path={mdiPlay} />}
            kind={"secondary"}
            onClick={() => {
              dispatch(runAudit(auditId));
            }}
            className={styles.runNowButton}
          />
        )}
      </div>
      {auditError && (
        <div className={styles.errorMessage}>Error: {auditError}</div>
      )}
    </div>
  );
};
