import { FunctionComponent } from "react";
import styles from "./noIncidents.module.scss";

export const NoIncidents: FunctionComponent = () => {
  return (
    <div className={styles.noIncidents}>
      <h1>Time to Relax: There are no incidents...</h1>
    </div>
  );
};
