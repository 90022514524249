import { mdiPencil } from "@mdi/js";
import { FunctionComponent } from "react";
import MenuItem from "../../../components/ContextMenu/MenuItem";

export interface EditNameButtonProps {
  openModal: () => void;
}

const EditNameButton: FunctionComponent<EditNameButtonProps> = ({
  openModal,
}) => {
  return (
    <MenuItem iconPath={mdiPencil} text={"Change name"} onClick={openModal} />
  );
};

export default EditNameButton;
