export interface ISortableTableColumn {
  label: string;
  key: string;
  sortable: boolean;
  order?: SortOrder;
  comparator?: (
    key: string,
    order: SortOrder
  ) => <T extends { [key: string]: any }>(a: T, b: T) => number;
}

export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}
