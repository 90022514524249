import { FunctionComponent } from "react";
import { mdiPlay } from "@mdi/js";
import { useAppDispatch } from "../../../../app/hooks";
import { startService } from "../../servicesThunks";
import MenuItem from "../../../../components/ContextMenu/MenuItem";

export interface StartServiceButtonProps {
  serviceName: string;
}

const StartServiceButton: FunctionComponent<StartServiceButtonProps> = ({
  serviceName,
}) => {
  const dispatch = useAppDispatch();
  return (
    <MenuItem
      iconPath={mdiPlay}
      text={"Start service"}
      onClick={() => dispatch(startService({ serviceName }))}
    />
  );
};

export default StartServiceButton;
