import {
  Button,
  SubmitButton,
  TextArea,
} from "@montel/montelpro-shared-components";
import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import styles from "./notes.module.scss";
import {
  getCurrentId,
  getCurrentText,
  getSubmitStatus,
} from "./notesSelectors";

import { finishEditing, updateCurrentText } from "./notesSlice";
import { submitNote } from "./notesThunks";

const NoteEdit: FunctionComponent<{ isNew?: boolean }> = ({ isNew }) => {
  const currentText = useAppSelector(getCurrentText);
  const id = useAppSelector(getCurrentId);
  const submitStatus = useAppSelector(getSubmitStatus);
  const dispatch = useAppDispatch();
  return (
    <div>
      <TextArea
        label="Text"
        placeholder="Add your note ..."
        value={currentText}
        onChange={(event: any) =>
          dispatch(updateCurrentText(event.target.value))
        }
        hideLabel
        autoFocus
      />
      <div className={styles.editModeButtonsPanel}>
        <Button
          text="Cancel"
          kind="tertiary"
          onClick={() => dispatch(finishEditing())}
          size="small"
        />
        <SubmitButton
          status={submitStatus}
          text={isNew ? "Send" : "Update"}
          kind="secondary"
          size="small"
          onClick={() => {
            dispatch(submitNote(id));
          }}
        />
      </div>
    </div>
  );
};

export default NoteEdit;
