import { FunctionComponent } from "react";
import Icon from "@mdi/react";
import { mdiHumanGreeting } from "@mdi/js";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { Button } from "@montel/montelpro-shared-components";
import { updateIncidentAssignee } from "../../../incidentsThunks";
import { getUserId } from "../../../../session/sessionSlice";
import styles from "../incidentsTable.module.scss";

export interface AssignUserButtonProps {
  incidentId: number;
}

const AssignUserButton: FunctionComponent<AssignUserButtonProps> = ({
  incidentId,
}) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getUserId);

  return (
    <Button
      className={styles.unassignedButton}
      text={"I'm on it"}
      size={"small"}
      iconRight={<Icon path={mdiHumanGreeting} />}
      kind={"secondary"}
      onClick={() =>
        dispatch(updateIncidentAssignee({ incidentId, assigneeId: userId }))
      }
    />
  );
};

export default AssignUserButton;
