import { MiddlewareAPI, Dispatch, Middleware } from "redux";
import {
  getValueFromLocalStorage,
  saveToLocalStorage,
} from "@montel/montelpro-shared-components/utils";

const getFilterMiddleWare = (
  localStorageKey: string,
  actionTypeKey: string
): [
  () => any,
  (_: MiddlewareAPI) => (next: Dispatch) => (action: any) => Middleware
] => {
  const getStoredFilters = () => getValueFromLocalStorage(localStorageKey, []);

  const storeFilterMiddleware =
    (_: MiddlewareAPI) =>
    (next: Dispatch) =>
    (action: any): Middleware => {
      const { type: actionType } = action;
      if (actionType !== actionTypeKey) return next(action) as Middleware;

      let localFilters = [...getStoredFilters()] as {
        category: string;
        value: any;
      }[];
      const { payload } = action;
      const { category, value } = payload;

      const isSelected = localFilters.find(
        (filter) => filter.category === category && filter.value === value
      );

      if (isSelected) {
        localFilters = localFilters.filter(
          (filter) => !(filter.category === category && filter.value === value)
        );
      } else {
        localFilters.push({ category, value });
      }
      saveToLocalStorage(localFilters, localStorageKey);

      return next(action) as Middleware;
    };
  return [getStoredFilters, storeFilterMiddleware];
};

export default getFilterMiddleWare;
