import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

import { Fieldset, RadioButtons } from "@montel/montelpro-shared-components";
import { SchedulingModeValues } from "../auditConfigurationEnums";
import { RunOnce } from "./RunOnce/RunOnce";
import { Repeat } from "./Repeat/Repeat";
import styles from "./schedule.module.scss";
import { schedulingModeOptions } from "../auditConfigurationOptions";
import {
  getAuditSelectedSchedulingMode,
  setSelectedSchedulingMode,
  getIsAuditScheduleTimesValid,
} from "../auditConfigurationDataSlice";

export const Schedule: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const schedulingMode = useAppSelector(getAuditSelectedSchedulingMode);
  const isDeltaValid = useAppSelector(getIsAuditScheduleTimesValid);

  const deltaError = !isDeltaValid
    ? "Start time must be less than stop time."
    : undefined;

  return (
    <Fieldset legend="Schedule" className={styles.schedule}>
      <div className={styles.inputsContainer}>
        <RadioButtons
          label={"Scheduling mode"}
          hideLabel
          value={schedulingMode}
          onChange={(value) => dispatch(setSelectedSchedulingMode(value))}
          options={schedulingModeOptions}
          className={styles.schedulingMode}
        />
        {schedulingMode === SchedulingModeValues.ONCE ? (
          <RunOnce />
        ) : (
          <Repeat />
        )}
      </div>
      {deltaError ? (
        <div className={styles.validationMessage}>{deltaError}</div>
      ) : (
        <></>
      )}
    </Fieldset>
  );
};
