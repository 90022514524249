import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";

const useLogout = () => {
  const { instance } = useMsal();

  const logout = () => {
    instance
      .logoutRedirect({
        postLogoutRedirectUri: "/",
      })
      .catch((e) => {
        console.error(e);
        toast.error("Something went wrong, you were not logged out properly");
      });
  };

  return [logout];
};

export default useLogout;
