import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getOptionByValue } from "../../utils/objectUtils";
import { getConnectionIdsOptions } from "../lists/listsSlice";
import { Select, TextArea } from "@montel/montelpro-shared-components";
import {
  getAuditConfig,
  getIsConnectionValid,
  getIsStatementValid,
  getShowValidationErrors,
  setConnectionId,
  setStatement,
} from "./auditConfigurationDataSlice";
import ExpectedResult from "./expectedResult/ExpectedResult";
import styles from "./auditConfiguration.module.scss";
import { DaysOfWeek } from "./daysOfWeek/DaysOfWeek";
import { Schedule } from "./scheduler/Schedule";

const EditConfiguration: FunctionComponent = () => {
  const { connectionId, statement } = useAppSelector(getAuditConfig);
  const connectionIdsOptions = useAppSelector(getConnectionIdsOptions);
  const dispatch = useAppDispatch();
  const showValidationErrors = useAppSelector(getShowValidationErrors);
  const isStatementValid = useAppSelector(getIsStatementValid);
  const isConnectionValid = useAppSelector(getIsConnectionValid);

  return (
    <>
      <Select
        label={"Connection"}
        value={getOptionByValue(connectionIdsOptions, connectionId)}
        options={connectionIdsOptions}
        onChange={({ value }) => dispatch(setConnectionId(value))}
        className={styles.input}
        errorMessage={
          showValidationErrors && !isConnectionValid
            ? "Select connection"
            : undefined
        }
      />
      <TextArea
        label={"Statement"}
        value={statement}
        onChange={(event: any) => dispatch(setStatement(event.target.value))}
        className={styles.input}
        rows={5}
        errorMessage={
          showValidationErrors && !isStatementValid
            ? "Statement should not be empty"
            : undefined
        }
      />
      <ExpectedResult />
      <Schedule />
      <DaysOfWeek isEditMode />
    </>
  );
};

export default EditConfiguration;
