import { FunctionComponent, ReactElement } from "react";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { DividerLine, IconButton } from "@montel/montelpro-shared-components";
import styles from "./card.module.scss";

export interface CardProps {
  title: string | ReactElement;
  children: ReactElement | ReactElement[] | string | number | null | undefined;
  onClose?: any;
  className?: string;
  actionButton?: any;
}

const Card: FunctionComponent<CardProps> = ({
  title,
  children,
  onClose,
  className,
  actionButton,
}) => {
  return (
    <div className={`${styles.outerContainer} ${className}`}>
      <div className={styles.cardHeader}>
        <div className={styles.title}>{title}</div>
        {actionButton}
        {onClose && (
          <IconButton
            altText={"Close card"}
            kind="tertiary"
            icon={<Icon path={mdiClose} />}
            onClick={onClose}
          />
        )}
      </div>
      <DividerLine />
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Card;
