import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { getSqlResult } from "../../audit/auditSlice";

import tableStyles from "../../../components/SortableTable/sortableTable.module.scss";
import styles from "./sqlQueryResult.module.scss";
import SqlResultHeader from "./SqlResultHeader";
import appPages from "../../../appPages";

const SqlQueryDetailedResult: FunctionComponent = () => {
  const navigate = useNavigate();
  const sqlResult = useAppSelector(getSqlResult);

  useEffect(() => {
    if (!sqlResult) navigate(appPages.audits);
  }, [sqlResult, navigate]);

  return (
    <div className={styles.sqlResultsContainer}>
      <SqlResultHeader />
      <div className={`${tableStyles.sortableTable} ${styles.sqlResultTable}`}>
        <table>
          <thead>
            <tr>
              {sqlResult?.columnNames.map((columnName) => (
                <th key={columnName} className={styles.tableHeaderColumn}>
                  {columnName || "Unknown"}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sqlResult?.rows.map((row, rowIdx) => (
              <tr key={rowIdx}>
                {Object.values(row).map((item, itemIdx) => (
                  <td
                    data-title={sqlResult?.columnNames[itemIdx] || "Unknown"}
                    key={itemIdx}
                  >
                    {item}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SqlQueryDetailedResult;
