import { FunctionComponent } from "react";
import { mdiDelete } from "@mdi/js";
import MenuItem from "../../../components/ContextMenu/MenuItem";

export interface DeleteButtonProps {
  openModal: () => void;
}

const DeleteButton: FunctionComponent<DeleteButtonProps> = ({ openModal }) => {
  return <MenuItem iconPath={mdiDelete} text={"Delete"} onClick={openModal} />;
};

export default DeleteButton;
