import { IServiceStatusMessage } from "./servicesTypes";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import moment from "moment";
import { RequestStatus } from "@montel/montelpro-shared-components/enums";
import { fetchServices } from "./servicesThunks";

export interface IServicesState {
  data: IServiceStatusMessage[];
  searchTerm: string;
  filters: { category: string; value: any }[];
  loadStatus: RequestStatus;
  error?: any;
  relatedServiceNameModal?: string;
}

export const initialState: IServicesState = {
  data: [] as IServiceStatusMessage[],
  searchTerm: "",
  filters: [] as { category: string; value: any }[],
  loadStatus: RequestStatus.UNLOADED,
  error: undefined,
  relatedServiceNameModal: undefined,
};

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    updateData: (state, { payload }) => {
      const idx = state.data.findIndex((item) => item.name === payload.name);
      if (idx === -1) {
        state.data = [...state.data, payload];
      } else {
        state.data[idx] = { ...state.data[idx], ...payload };
      }
    },
    setSearchTerm: (state, { payload }) => {
      state.searchTerm = payload;
    },
    toggleFilter: (state, { payload }) => {
      const { category, value } = payload;
      const isSelected = state.filters.find(
        (filter) => filter.category === category && filter.value === value
      );
      if (isSelected) {
        state.filters = state.filters.filter(
          (filter) => !(filter.category === category && filter.value === value)
        );
      } else {
        state.filters.push({ category, value });
      }
    },
    setRelatedServiceNameModal: (state, { payload }) => {
      state.relatedServiceNameModal = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServices.pending, (state) => {
        state.loadStatus = RequestStatus.LOADING;
        state.error = undefined;
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadStatus = RequestStatus.SUCCESS;
        state.error = undefined;
      })
      .addCase(fetchServices.rejected, (state, action) => {
        state.loadStatus = RequestStatus.ERROR;
        state.error = action.error;
      });
  },
});

export const {
  setSearchTerm,
  updateData,
  toggleFilter,
  setRelatedServiceNameModal,
} = servicesSlice.actions;

export const getServicesState = (state: RootState) => state.services;
export const getServices = (state: RootState) => getServicesState(state).data;
export const getHasServices = (state: RootState) =>
  getServices(state).length > 0;
export const getLoadStatus = (state: RootState) =>
  getServicesState(state).loadStatus;
export const getError = (state: RootState) => getServicesState(state).error;

export const getSearchTerm = (state: RootState) =>
  getServicesState(state).searchTerm;

export const getFilters = (state: RootState) => getServicesState(state).filters;

export const getIsFilterSelected = (
  state: RootState,
  category: string,
  value: any
): boolean =>
  getFilters(state).some(
    (filter) => filter.category === category && filter.value === value
  );

export const getFilteredServices = (state: RootState) => {
  const services = getServices(state);
  const filters = getFilters(state);
  if (filters.length === 0) return services;

  const categories = Array.from(
    new Set(filters.map((filter) => filter.category))
  );

  return services.filter((service: IServiceStatusMessage) =>
    categories.every((category) => {
      const values = filters
        .filter((filter) => filter.category === category)
        .map((filter) => filter.value);
      return values.some((value) => {
        if (category === "timeStampFormatted")
          return (
            service[category as keyof IServiceStatusMessage] as string
          ).includes(moment().format("DD.MM.YYYY"));
        return service[category as keyof IServiceStatusMessage] === value;
      });
    })
  );
};

export const getSearchFilteredServices = (state: RootState) => {
  const services = getFilteredServices(state);
  const searchTerm = getSearchTerm(state);
  if (services.length === 0 || !searchTerm) return services;

  const searchableProperties = ["name", "host", "lastUpdatedFormatted"];
  const regex = new RegExp(searchTerm, "i");

  return services.filter((audit: any) =>
    searchableProperties.some((prop) => regex.test(audit[prop]))
  );
};

export const getRelatedServiceNameModal = (state: RootState) =>
  getServicesState(state).relatedServiceNameModal;

export default servicesSlice.reducer;
