import { FunctionComponent } from "react";
import FilterMenu from "../../../../components/FilterMenu/FilterMenu";
import menuConfig from "./menuConfig";
import { getIsFilterSelected, toggleFilter } from "../../servicesSlice";

const ServicesFilter: FunctionComponent<{ className?: string }> = ({
  className,
}) => {
  return (
    <FilterMenu
      menuConfig={menuConfig}
      isFilterSelectedSelector={getIsFilterSelected}
      toggleFilterAction={toggleFilter}
      className={className}
    />
  );
};

export default ServicesFilter;
