import useOnMount from "../../hooks/useOnMount";
import { IncidentsFilterOptions } from "../../shared/enums";
import { setCurrentFilter } from "./incidentsSlice";

const RERENDER_INTERVAL_MS = 60000;

//Rerenders all incidents, so postponed ones
//will be moved automatically to active tab on rerender
const useMoveExpiredPostponedToActive = (
  currentStatusFilter: IncidentsFilterOptions,
  dispatch: any
) => {
  useOnMount(() => {
    const interval = setInterval(() => {
      const currentTab = currentStatusFilter;
      dispatch(setCurrentFilter(IncidentsFilterOptions.POSTPONED));
      dispatch(setCurrentFilter(currentTab));
    }, RERENDER_INTERVAL_MS);
    return () => {
      clearInterval(interval);
    };
  });
};

export default useMoveExpiredPostponedToActive;
