import { FunctionComponent } from "react";
import ScheduleTimeDescription from "./scheduler/ScheduleTimeDescription";
import { useAppSelector } from "../../app/hooks";
import { getAuditConfig } from "./auditConfigurationDataSlice";
import { getConnectionIdsOptions } from "../lists/listsSlice";
import LabelledContent from "../../components/LabelledContent/LabelledContent";
import { getOptionByValue } from "../../utils/objectUtils";
import getExpectedResultDescription from "./expectedResult/getExpectedResultDescription";
import { DaysOfWeek } from "./daysOfWeek/DaysOfWeek";
import StatementOverview from "./StatementOverview";

const ViewConfiguration: FunctionComponent = () => {
  const {
    connectionId,
    statement,
    conditionOperator,
    expectedResult,
    runOnceStart,
    runStart,
    runStop,
    runStep,
  } = useAppSelector(getAuditConfig);
  const connectionIdsOptions = useAppSelector(getConnectionIdsOptions);

  return (
    <>
      <LabelledContent label={"Connection"}>
        {getOptionByValue(connectionIdsOptions, connectionId)?.label}
      </LabelledContent>
      <LabelledContent label={"Statement"}>
        <StatementOverview statement={statement} />
      </LabelledContent>
      <LabelledContent label={"Expected result"}>
        {getExpectedResultDescription(conditionOperator, expectedResult)}
      </LabelledContent>
      <LabelledContent label={"Schedule"}>
        <ScheduleTimeDescription
          runOnceStart={runOnceStart}
          runStart={runStart}
          runStop={runStop}
          runStep={runStep}
        />
      </LabelledContent>
      <DaysOfWeek />
    </>
  );
};

export default ViewConfiguration;
