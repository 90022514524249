import { FunctionComponent } from "react";
import { useAppSelector } from "../../app/hooks";

import EditInformation from "../auditInfo/EditInformation";
import { ViewInformation } from "../auditInfo/ViewInformation";
import ButtonsBar from "./buttonsBar/ButtonsBar";
import { getIsEditMode } from "./auditSlice";
import styles from "./audit.module.scss";

const InformationTab: FunctionComponent = () => {
  const isEditMode = useAppSelector(getIsEditMode);
  return (
    <>
      <div className={`${styles.tabContent} ${styles.elementsContainer}`}>
        {isEditMode ? <EditInformation /> : <ViewInformation />}
      </div>
      <ButtonsBar />
    </>
  );
};

export default InformationTab;
