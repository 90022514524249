import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import apiRoutes from "../../apiRoutes";
import { IPerson, IPersonApi, ISystem } from "./listsTypes";
import { SelectOptionType } from "@montel/montelpro-shared-components";
import translateAPIObjectToOption from "../../utils/objectUtils/translateAPIObjectToOption";
import translateIdToOption from "../../utils/objectUtils/translateIdToOption";
import { toast } from "react-toastify";

export interface IListsState {
  systems: ISystem[];
  connectionIds: string[];
  persons: IPerson[];
  isPersonsLoaded: boolean;
}

export const initialState: IListsState = {
  systems: [],
  connectionIds: [],
  persons: [],
  isPersonsLoaded: false,
};

const translateApiPersons = ({
  id,
  name,
  email,
  phoneNumber,
  jobTitle,
  department,
}: IPersonApi): IPerson => ({
  id,
  name,
  email,
  phoneNumber,
  jobTitle: jobTitle || undefined,
  department,
});

export const fetchLists = createAsyncThunk(
  "lists/fetchLists",
  async (_, { dispatch }) => {
    dispatch(fetchPersons());
    dispatch(fetchSystems());
    dispatch(fetchConnectionIds());
  }
);

export const fetchPersons = createAsyncThunk(
  "lists/fetchPersons",
  async (_, { dispatch }) => {
    try {
      const result = await axios.get(apiRoutes.persons);
      const persons = (result.data as IPersonApi[]).map(translateApiPersons);
      dispatch(setPersons(persons));
    } catch (e) {
      toast.error("An error has occured while fetching persons");
    }
  }
);
export const fetchSystems = createAsyncThunk(
  "lists/fetchSystems",
  async (_, { dispatch }) => {
    try {
      const result = await axios.get(apiRoutes.systems);
      dispatch(setSystems(result.data as ISystem[]));
    } catch (e) {
      toast.error("An error has occured while fetching systems from IT Glue");
    }
  }
);

export const fetchConnectionIds = createAsyncThunk(
  "lists/fetchConnectionIds",
  async (_, { dispatch }) => {
    try {
      const result = await axios.get(apiRoutes.connectionIds);
      dispatch(setConnectionIds(result.data as string[]));
    } catch (e) {
      toast.error("An error has occured while fetching connections");
    }
  }
);

export const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {
    setPersons: (state, { payload }) => {
      state.persons = payload;
    },
    setSystems: (state, { payload }) => {
      state.systems = payload;
    },
    setConnectionIds: (state, { payload }) => {
      state.connectionIds = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPersons.fulfilled, (state) => {
      state.isPersonsLoaded = true;
    });
  },
});

export const { setPersons, setSystems, setConnectionIds } = listsSlice.actions;

export const getListsState = (state: RootState) => state.lists;

export const getSystems = (state: RootState) => getListsState(state).systems;

export const getSystemsOptions = (state: RootState) =>
  getSystems(state).map(translateAPIObjectToOption);

export const getConnectionIds = (state: RootState) =>
  getListsState(state).connectionIds;

export const getConnectionIdsOptions = (state: RootState) =>
  getConnectionIds(state).map(translateIdToOption);

export const getPersons = (state: RootState) => getListsState(state).persons;

export const getPersonsOptions = (state: RootState) => {
  const persons = getPersons(state);
  const options = persons.map((person) => ({
    value: person.id,
    label: person.name,
  }));
  return [
    ...options,
    { value: undefined, label: "Unassigned" } as SelectOptionType,
  ];
};

export const getPersonNameById =
  (id: string | undefined) => (state: RootState) => {
    const persons = getPersons(state);
    return persons.find((person) => person.id === id)?.name || "Unassigned";
  };

export const getPersonIdNameMapping = (state: RootState) => {
  const persons = getPersons(state);
  return persons.reduce(
    (acc, current) => ({
      ...acc,
      [current.id]: current.name,
    }),
    {}
  );
};
export const getSystemById =
  (id: string | number | undefined) => (state: RootState) => {
    const systems = getSystems(state);
    return systems.find((system) => system.id === id);
  };

export const getIsPersonsLoaded = (state: RootState) =>
  getListsState(state).isPersonsLoaded;

export default listsSlice.reducer;
