import { FunctionComponent } from "react";
import { useAppSelector } from "../../../app/hooks";
import {
  getUserName,
  getUserRole,
} from "../../../features/session/sessionSlice";
import { getInitials } from "../../../utils/textUtils";
import styles from "./userBadge.module.scss";

interface IUserBadgeProps {
  onClick: () => void;
}

export const UserBadge: FunctionComponent<IUserBadgeProps> = ({ onClick }) => {
  const name = useAppSelector(getUserName);
  const role = useAppSelector(getUserRole);

  return (
    <div className={styles.userBadge} onClick={onClick}>
      <div className={styles.information}>
        <div className={styles.role}>{role || "Unknown"}</div>
        <div className={styles.name}>{name || "Unknown"}</div>
      </div>
      <button className={`${styles.profileImage}`}>
        {name && getInitials(name)}
      </button>
    </div>
  );
};
