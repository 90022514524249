import { FunctionComponent } from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import sql from "react-syntax-highlighter/dist/cjs/languages/hljs/sql";
import { nord } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import styles from "./auditConfiguration.module.scss";
import { IconButton } from "@montel/montelpro-shared-components";
import Icon from "@mdi/react";
import { mdiContentCopy } from "@mdi/js";
import copyToClipboard from "../../utils/copyToClipboard";
import { toast } from "react-toastify";
import SqlQueryButton from "./sqlQueryResult/SqlQueryButton";
import SqlQueryShortResult from "./sqlQueryResult/SqlQueryShortResult";

SyntaxHighlighter.registerLanguage("sql", sql);

const StatementOverview: FunctionComponent<{
  statement: string | undefined;
}> = ({ statement }) => {
  return (
    <div className={styles.highlightedSyntax}>
      <SyntaxHighlighter language="sql" style={nord}>
        {statement || ""}
      </SyntaxHighlighter>
      <SqlQueryButton />
      <IconButton
        altText="Copy statement to clipboard"
        icon={<Icon path={mdiContentCopy} />}
        size="small"
        kind="tertiary"
        onClick={() => {
          copyToClipboard(statement);
          toast.success("Statement copied to clipboard", {
            autoClose: 2000,
          });
        }}
        className={styles.copyToClipboard}
      />
      <SqlQueryShortResult />
    </div>
  );
};

export default StatementOverview;
