import React, { FunctionComponent } from "react";
import { ISortableTableColumn } from "../../../../components/SortableTable/sortableTableTypes";
import { ServicesStatus, IServiceStatusMessage } from "../../servicesTypes";
import useHighlightOnChange from "../../../../hooks/useHighlightOnChange";
import { useAppSelector } from "../../../../app/hooks";
import { getSearchTerm } from "../../servicesSlice";
import stylesServices from "./servicesTable.module.scss";
import HighlightSearch from "../../../../components/HighlightSearch/HighlightSearch";
import { StatusLabel } from "../../../../components/StatusLabel/StatusLabel";
import getServiceStatusLabelColor from "../../../../utils/uiUtils/getServiceStatusLabelColor";
import ServicesContextMenu from "../ContextMenu/ServicesContextMenu";

const ServicesTableRow: FunctionComponent<{
  columns: ISortableTableColumn[];
  data: IServiceStatusMessage;
}> = ({ columns, data }) => {
  const {
    name,
    host,
    lastUpdatedFormatted,
    status,
    statusText,
    relatedSystemId,
  } = data;
  const [highlight] = useHighlightOnChange(status);
  const searchTerm = useAppSelector(getSearchTerm);
  const isFeedEnabled =
    status !== ServicesStatus.UNKNOWN && status !== ServicesStatus.NOT_RUNNING;

  return (
    <tr key={name} className={`${highlight && stylesServices.highlight}`}>
      <td className={stylesServices.contextMenuCellMobile}>
        <ServicesContextMenu
          disabled={false}
          serviceName={name}
          isServiceEnabled={isFeedEnabled}
          relatedSystemId={relatedSystemId}
        />
      </td>
      <td data-title={columns[0].label}>
        <HighlightSearch searchTerm={searchTerm}>{name}</HighlightSearch>
      </td>
      <td data-title={columns[1].label}>
        <HighlightSearch searchTerm={searchTerm}>{host}</HighlightSearch>
      </td>
      <td data-title={columns[2].label}>
        <HighlightSearch searchTerm={searchTerm}>
          {lastUpdatedFormatted}
        </HighlightSearch>
      </td>
      <td data-title={columns[2].label}>
        <StatusLabel
          text={statusText}
          color={getServiceStatusLabelColor(status)}
        />
      </td>
      <td className={stylesServices.contextMenuCell}>
        <ServicesContextMenu
          disabled={false}
          serviceName={name}
          isServiceEnabled={isFeedEnabled}
          relatedSystemId={relatedSystemId}
        />
      </td>
    </tr>
  );
};

export default ServicesTableRow;
