import React, { FunctionComponent } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { useSortableTable } from "../../../../components/SortableTable/useSortableTable";
import { getSearchFilteredAudits, getHasAudits } from "../../auditsSlice";
import SortableTableHead, {
  SortableTableHeadProps,
} from "../../../../components/SortableTable/SortableTableHead";
import { IAuditItem } from "../../../audit/auditTypes";
import {
  ISortableTableColumn,
  SortOrder,
} from "../../../../components/SortableTable/sortableTableTypes";
import SortableTableHeadMobile from "../../../../components/SortableTable/SortableTableHeadMobile";
import styles from "../../../../components/SortableTable/sortableTable.module.scss";
import stylesAudits from "./auditsTable.module.scss";
import AuditTableRow from "./AuditTableRow";
import AuditsFilter from "../AuditsFilter/AuditsFilter";
import AuditFilterChips from "../AuditsFilter/AuditFilterChips";
import {
  comparatorDate,
  comparatorNumeric,
} from "../../../../utils/objectUtils/index";
import comparatorPriority from "../../../../utils/objectUtils/comparatorPriority";

const columns: ISortableTableColumn[] = [
  {
    label: "Id",
    key: "id",
    sortable: true,
    comparator: comparatorNumeric,
  },
  {
    label: "Name",
    key: "name",
    sortable: true,
    order: SortOrder.ASC,
  },
  {
    label: "Priority",
    key: "priority",
    sortable: true,
    comparator: comparatorPriority,
  },
  {
    label: "Last run",
    key: "lastRunDateTime",
    sortable: true,
    comparator: comparatorDate,
  },
  {
    label: "Status",
    key: "runStatus",
    sortable: true,
  },
  {
    label: "",
    key: "",
    sortable: false,
  },
];

export const AuditsTable: FunctionComponent = () => {
  const hasAudits = useAppSelector(getHasAudits);
  const audits = useAppSelector(getSearchFilteredAudits);
  const [tableData, tableHeaderProps] = useSortableTable(audits, columns);

  if (!hasAudits) {
    return (
      <div className={stylesAudits.noData}>
        <h2>There are no audits yet</h2>
      </div>
    );
  }

  return (
    <section className={`${styles.sortableTable} ${stylesAudits.auditsTable}`}>
      <div className={stylesAudits.mobileSortFilter}>
        <SortableTableHeadMobile
          {...({
            columns,
            ...tableHeaderProps,
          } as SortableTableHeadProps)}
        />
        <AuditsFilter />
      </div>
      <AuditFilterChips />
      {audits.length === 0 ? (
        <div className={stylesAudits.noData}>
          <h2>There are no audits matching selected criteria.</h2>
        </div>
      ) : (
        <table>
          <SortableTableHead
            {...({
              columns,
              ...tableHeaderProps,
            } as SortableTableHeadProps)}
          />
          <tbody>
            {(tableData as IAuditItem[]).map((data) => (
              <AuditTableRow key={data.id} columns={columns} data={data} />
            ))}
          </tbody>
        </table>
      )}
    </section>
  );
};
