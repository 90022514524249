import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiAutorenew } from "@mdi/js";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import useOnMount from "../../../hooks/useOnMount";
import {
  getSqlRunStatus,
  getSqlResult,
  resetSqlResult,
} from "../../audit/auditSlice";
import styles from "./sqlQueryResult.module.scss";
import { RequestStatus } from "@montel/montelpro-shared-components/enums";
import { ISqlQueryResult } from "../../audit/auditTypes";
import appPages from "../../../appPages";

const SqlQueryShortResult: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(getSqlRunStatus);
  const sqlResult = useAppSelector(getSqlResult);

  useOnMount(() => {
    dispatch(resetSqlResult());
  });

  const createShortResult = (sqlResult: ISqlQueryResult): string => {
    if (sqlResult.error) return sqlResult.error;
    if (sqlResult.rows.length === 0) return "Null";
    return sqlResult.rows[0][sqlResult.columnNames[0]];
  };

  if (status === RequestStatus.UNLOADED) return <></>;

  return (
    <div className={styles.sqlQueryResult}>
      <span>Result:</span>
      {status === RequestStatus.LOADING && (
        <Icon path={mdiAutorenew} className={styles.syncIcon} />
      )}
      {sqlResult && `${createShortResult(sqlResult)}`}
      {sqlResult && !sqlResult.error && (
        <span>
          <Link to={appPages.auditSqlResult}>Detailed result</Link>
        </span>
      )}
    </div>
  );
};

export default SqlQueryShortResult;
