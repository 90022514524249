import { FunctionComponent } from "react";
import { toTitleCase } from "../../../utils/textUtils";
import styles from "./auditTabs.module.scss";

const Tabs: FunctionComponent<{
  tabs: string[];
  currentTab: string;
  onTabClick: (selectedTab: string) => void;
}> = ({ tabs, currentTab, onTabClick }) => {
  return (
    <nav className={styles.tabbedNavigation}>
      {tabs.map((tab) => {
        return (
          <button
            key={tab}
            className={`${currentTab === tab && styles.active}`}
            onClick={() => onTabClick(tab)}
          >
            {toTitleCase(tab)}
          </button>
        );
      })}
    </nav>
  );
};

export default Tabs;
