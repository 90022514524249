import { FunctionComponent } from "react";

const HighlightSearch: FunctionComponent<{
  searchTerm?: string;
  children: any;
}> = ({ searchTerm, children }) => {
  if (!searchTerm) {
    return children;
  }
  //() - group capture, i - case insensitive.
  const regex = new RegExp(`(${searchTerm})`, "i");
  const parts: string[] = children.toString().split(regex);

  return (
    <span>
      {parts.map((part, i) =>
        regex.test(part) ? (
          <mark key={i}>{part}</mark>
        ) : (
          <span key={i}>{part}</span>
        )
      )}
    </span>
  );
};

export default HighlightSearch;
