import { FunctionComponent } from "react";
import { getFilters, toggleFilter } from "../../auditsSlice";
import FilterChips from "../../../../components/FilterMenu/FilterChips/FilterChips";
import menuConfig from "./menuConfig";

const AuditFilterChips: FunctionComponent = () => {
  return (
    <FilterChips
      menuConfig={menuConfig}
      toggleFilter={toggleFilter}
      getFilters={getFilters}
    />
  );
};

export default AuditFilterChips;
