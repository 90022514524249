export enum Priority {
  Critical = 1,
  Medium,
  Low,
}

export enum WorkState {
  NEW = "new",
  IN_PROGRESS = "inProgress",
  CLOSED = "closed",
}

export enum UserRole {
  Support = "Support",
  Administrator = "Administrator",
}

export enum IncidentSourceType {
  AUDIT = "audit",
  SERVICE = "service",
  FATALERROR = "fatalError",
}

export enum IncidentsFilterOptions {
  ACTIVE = "active",
  POSTPONED = "postponed",
  COMPLETED = "completed",
}

export enum DateTimeFormats {
  SERVER = "YYYY-MM-DDTHH:mm:ss.SSSS[Z]",
  POSTPONE_OPTIONS = "ddd, HH:mm",
  PRESENTATION = "DD.MM.YYYY, HH:mm",
  TIME = "HH:mm",
}

export enum ResourceStatus {
  OK = "ok",
  FAILED = "failed",
  EXCEPTION = "exception",
  UNKNOWN = "unknown",
}
