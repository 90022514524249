import {
  AccountInfo,
  InteractionRequiredAuthError,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { protectedResources } from "./authConfig";

//1. Tries to get token from session storage first.
//2. If token is not present/expired, fetches a new one from Azure AD with refresh token.
//3. If refresh token not present/expired, redirects user to login.microsoftonline.com to enter credentials.
//4. Redirects back, first to home page and then to current page.
const getValidAccessToken = async (
  msalInstance: IPublicClientApplication,
  accounts: AccountInfo[]
) => {
  try {
    const response = await msalInstance.acquireTokenSilent({
      scopes: protectedResources.watcherAPI.scopes,
      account: accounts[0],
    });
    return response.accessToken;
  } catch (error) {
    console.error(error);
    if (error instanceof InteractionRequiredAuthError) {
      //redirects to login.microsoftonline.com
      //ends up at /auth-complete and then back to current page
      msalInstance.acquireTokenRedirect({
        scopes: protectedResources.watcherAPI.scopes,
      });
    }
    return undefined;
  }
};

export default getValidAccessToken;
