import { FunctionComponent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { editNote } from "./notesSlice";
import NoteEdit from "./NoteEdit";
import styles from "./notes.module.scss";
import { mdiDelete, mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import { IconButton } from "@montel/montelpro-shared-components";
import { getUserId } from "../session/sessionSlice";
import Modal from "../../components/Modal/Modal";
import formatTimestamp from "../../utils/dateTimeUtils/formatTimestamp";
import { deleteNote } from "./notesThunks";
import { getDeleteStatus, getIsInEditMode } from "./notesSelectors";
import { INote } from "./notesTypes";
import { getPersonNameById } from "../lists/listsSlice";

const NoteCard: FunctionComponent<INote> = ({
  id,
  createdAt,
  authorId,
  text,
  editedAt,
}) => {
  const isInEditMode = useAppSelector((state) => getIsInEditMode(state, id));
  const deleteStatus = useAppSelector(getDeleteStatus);
  const userId = useAppSelector(getUserId);
  const authorName = useAppSelector(getPersonNameById(authorId));
  const isEditable = authorId === userId && !isInEditMode;
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className={styles.card}>
      <div className={styles.header}>
        {authorName}
        <span className={styles.noted}>
          {` ${formatTimestamp(createdAt)}
          ${editedAt ? "(edited)" : ""}`}
        </span>
        {isEditable && (
          <div className={styles.editAndDeleteButtons}>
            <IconButton
              altText="Edit note"
              kind="tertiary"
              size="small"
              icon={<Icon path={mdiPencil} />}
              onClick={() => dispatch(editNote(id))}
            />
            <IconButton
              altText="Delete note"
              kind="tertiary"
              size="small"
              icon={<Icon path={mdiDelete} />}
              onClick={() => setIsModalOpen(true)}
            />
            <Modal
              isOpen={isModalOpen}
              title="Delete note"
              onOk={() => dispatch(deleteNote(id))}
              onCancel={() => setIsModalOpen(false)}
              onOkStatus={deleteStatus}
            >
              <div>Are you sure you want to delete your note?</div>
            </Modal>
          </div>
        )}
      </div>
      {isInEditMode ? <NoteEdit /> : <p>{text}</p>}
    </div>
  );
};

export default NoteCard;
