import React, { FunctionComponent, useState, useRef } from "react";
import { IconButton } from "@montel/montelpro-shared-components";
import Icon from "@mdi/react";
import { mdiDotsVertical } from "@mdi/js";
import useDetectOutsideClick from "../Header/useDetectOutsideClick";
import useDetectEscPressed from "../Header/useDetectEscPressed";
import styles from "./ContextMenu.module.scss";

export interface ContextMenuProps {
  children: React.ReactElement[];
  disabled?: boolean;
  className?: string;
  altText?: string;
}

const ContextMenu: FunctionComponent<ContextMenuProps> = ({
  children,
  disabled = false,
  className = undefined,
  altText = "Context menu",
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = (e: any) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };
  const onOutsideClick = (e: any) => {
    if (e) e.stopPropagation();
    setIsOpen(false);
  };

  useDetectOutsideClick(ref, onOutsideClick);
  useDetectEscPressed(onOutsideClick);

  return (
    <div
      ref={ref}
      className={`${styles.contextMenu} ${className && className}`}
    >
      <IconButton
        onClick={toggleMenu}
        icon={<Icon path={mdiDotsVertical} />}
        disabled={disabled}
        altText={altText}
        className={styles.menuButton}
        kind="tertiary"
      />
      {isOpen && (
        <div className={`${styles.contextMenuDropdown}`}>
          <ul>
            {children.map((child, idx) => (
              <li key={idx} onClick={onOutsideClick}>
                {child}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ContextMenu;
