import React, { FunctionComponent } from "react";
import ContextMenu from "../../../../../components/ContextMenu/ContextMenu";
import AssigneeButton from "./AssigneeButton";
import { PostponeButton } from "./PostponeButton";
import RunAuditButton from "./RunAuditButton";
import styles from "./IncidentsContextMenu.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  getPostponeModalId,
  setPostponeModalId,
} from "../../../incidentsSlice";
import { PostponeModal } from "./PostponeModal/PostponeModal";
import StartServiceButton from "../../../../services/components/ContextMenu/StartServiceButton";
import { IncidentSourceType } from "../../../../../shared/enums";
import CompleteIncidentButton from "./CompleteIncidentButton";

export interface IncidentsContextMenuProps {
  incidentId: number;
  incidentSourceType: IncidentSourceType;
  sourceId: number | string;
  disabled: boolean;
  postponedTo?: string;
  className?: string;
}

const IncidentsContextMenu: FunctionComponent<IncidentsContextMenuProps> = ({
  incidentId,
  sourceId,
  incidentSourceType,
  disabled = false,
  postponedTo,
  className,
}) => {
  const dispatch = useAppDispatch();
  const postponeModalId = useAppSelector(getPostponeModalId);

  const showModal =
    postponeModalId !== undefined && postponeModalId === incidentId;

  const menuItems = [
    <AssigneeButton incidentId={incidentId} key={1} />,
    <PostponeButton
      incidentId={incidentId}
      postponedTo={postponedTo}
      key={2}
    />,
    ...(incidentSourceType === IncidentSourceType.AUDIT &&
    typeof sourceId === "number"
      ? [<RunAuditButton auditId={sourceId} key={3} />]
      : []),
    ...(incidentSourceType === IncidentSourceType.SERVICE &&
    typeof sourceId === "string"
      ? [<StartServiceButton serviceName={sourceId} />]
      : []),
    ...(incidentSourceType === IncidentSourceType.FATALERROR
      ? [<CompleteIncidentButton incidentId={incidentId} key={3} />]
      : []),
  ];

  return (
    <>
      <ContextMenu
        children={menuItems}
        disabled={disabled}
        className={`${styles.incidentsMenuDropdown} ${
          disabled && styles.disabledMenu
        } ${className && className}`}
      />
      {showModal && (
        <PostponeModal
          isModalOpen={showModal}
          setIsModalOpen={() => dispatch(setPostponeModalId(undefined))}
          incidentId={incidentId}
          postponedTo={postponedTo}
        />
      )}
    </>
  );
};

export default IncidentsContextMenu;
