import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { VerticalDividerLine } from "../../../../components/VerticalDividerLine/VerticalDividerLine";
import { TimePicker } from "../../../../components/TimePicker/TimePicker";
import styles from "./runOnce.module.scss";
import {
  getAuditConfig,
  getIsAuditScheduleRunOnceStartValid,
  getShowValidationErrors,
  setScheduleRunOnce,
} from "../../auditConfigurationDataSlice";

export const RunOnce: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { runOnceStart: start } = useAppSelector(getAuditConfig);
  const showValidationErrors = useAppSelector(getShowValidationErrors);
  const isRunOnceValid = useAppSelector(getIsAuditScheduleRunOnceStartValid);

  return (
    <div className={styles.runOnce}>
      <VerticalDividerLine />
      <div className={styles.inputs}>
        <span className={styles.text}>at </span>
        <TimePicker
          value={start}
          onChange={(val) => dispatch(setScheduleRunOnce(val))}
          errorMessage={
            showValidationErrors && !isRunOnceValid ? "Required" : undefined
          }
        />
      </div>
    </div>
  );
};
