import { useEffect, RefObject } from "react";

const clickEventTypes = ["mousedown", "touchstart"];

const useDetectOutsideClick = (ref: RefObject<any>, onDetected: any) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onDetected();
      }
    }
    clickEventTypes.forEach((eventType) => {
      document.addEventListener(eventType, handleClickOutside);
    });

    return () => {
      clickEventTypes.forEach((eventType) => {
        document.removeEventListener(eventType, handleClickOutside);
      });
    };
  }, [ref, onDetected]);
};

export default useDetectOutsideClick;
