import { FunctionComponent } from "react";
import { mdiStop } from "@mdi/js";
import { useAppDispatch } from "../../../../app/hooks";
import { stopService } from "../../servicesThunks";
import MenuItem from "../../../../components/ContextMenu/MenuItem";

export interface StopServiceButtonProps {
  serviceName: string;
}

const StartServiceButton: FunctionComponent<StopServiceButtonProps> = ({
  serviceName,
}) => {
  const dispatch = useAppDispatch();
  return (
    <MenuItem
      iconPath={mdiStop}
      text={"Stop service"}
      onClick={() => dispatch(stopService({ serviceName }))}
    />
  );
};

export default StartServiceButton;
