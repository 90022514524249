import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { UserRole } from "../../shared/enums";

export interface SessionState {
  isAuthCompleted: boolean;
  userId: string | undefined;
  userName: string | undefined;
  userRole: string | undefined;
  isWebSocketLive: boolean;
}
const initialState: SessionState = {
  isAuthCompleted: false,
  userId: undefined,
  userName: undefined,
  userRole: undefined,
  isWebSocketLive: false,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      const { userId, userName } = payload;
      state.userId = userId;
      state.userName = userName;
    },
    completeAuth: (state) => {
      state.isAuthCompleted = true;
    },
    setRole: (state, { payload }) => {
      state.userRole = payload;
    },
    setIsWebSocketLive: (state, { payload }) => {
      state.isWebSocketLive = payload;
    },
  },
});

export const { setUser, completeAuth, setRole, setIsWebSocketLive } =
  sessionSlice.actions;

export const getSessionState = (state: RootState) => state.session;
export const getIsAuthCompleted = (state: RootState) =>
  getSessionState(state).isAuthCompleted;
export const getUserId = (state: RootState) => getSessionState(state).userId;
export const getUserName = (state: RootState) =>
  getSessionState(state).userName;
export const getUserRole = (state: RootState) =>
  getSessionState(state).userRole;
export const getIsAdmin = (state: RootState) =>
  getUserRole(state) === UserRole.Administrator;
export const getIsWebSocketLive = (state: RootState) =>
  getSessionState(state).isWebSocketLive;

export default sessionSlice.reducer;
