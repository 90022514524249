import { getIsNameAlreadyTaken } from "./../audits/auditsSlice";
import { IAuditApi } from "./../audit/auditTypes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "@montel/montelpro-shared-components/enums";
import axios from "axios";
import apiRoutes from "../../apiRoutes";
import { toast } from "react-toastify";
import { fetchAudits } from "../audits/auditsSlice";
import { AppThunk, RootState } from "../../app/store";
import {
  getIsDataDirtyInfo,
  getIsDataValidInfo,
  getPayloadInfo,
  resetStateInfo,
  setShowValidationErrorsInfo,
} from "../auditInfo/auditInformationDataSlice";
import {
  getIsDataDirtyConfig,
  getIsDataValidConfig,
  getPayloadConfig,
  resetStateConfig,
  setShowValidationErrorsConfig,
} from "../auditConfig/auditConfigurationDataSlice";

export interface INewAuditState {
  currentStep: number;
  progress: number;
  submitStatus: RequestStatus;
  auditName: string;
}

export const initialState: INewAuditState = {
  currentStep: 0,
  progress: 0,
  submitStatus: RequestStatus.UNLOADED,
  auditName: "",
};

export const createNewAudit = createAsyncThunk(
  "newAudit/create",
  async (_, { getState, dispatch }) => {
    const state = getState();
    const payload = getPayload(state as RootState);
    if (!payload) return undefined;
    try {
      await axios.post(apiRoutes.audits, payload);
      dispatch(fetchAudits());
      toast.success("New audit has been created.");
    } catch (error: any) {
      toast.error("Something went wrong. Audit was not created.");
      throw error;
    }
  }
);

export const setShowErrors = (): AppThunk => (dispatch, getState) => {
  const currentStep = getCurrentStep(getState() as RootState);
  switch (currentStep) {
    case 0:
      dispatch(setShowValidationErrorsInfo());
      return;
    case 1:
      dispatch(setShowValidationErrorsConfig());
      return;
    default:
      return;
  }
};

export const resetState = (): AppThunk => (dispatch) => {
  dispatch(resetNewAuditState());
  dispatch(resetStateInfo());
  dispatch(resetStateConfig());
};

export const newAuditSlice = createSlice({
  name: "newAudit",
  initialState,
  reducers: {
    setName: (state, { payload }) => {
      state.auditName = payload;
    },
    setCurrentStep: (state, { payload }) => {
      state.currentStep = payload;
    },
    incrementStep: (state) => {
      state.currentStep += 1;
      state.progress = Math.max(state.progress, state.currentStep);
    },
    decrementStep: (state) => {
      state.currentStep -= 1;
    },
    resetNewAuditState: (state) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewAudit.pending, (state) => {
        state.submitStatus = RequestStatus.LOADING;
      })
      .addCase(createNewAudit.fulfilled, (state) => {
        state.submitStatus = RequestStatus.SUCCESS;
      })
      .addCase(createNewAudit.rejected, (state) => {
        state.submitStatus = RequestStatus.ERROR;
      });
  },
});

export const {
  setCurrentStep,
  incrementStep,
  decrementStep,
  resetNewAuditState,
  setName,
} = newAuditSlice.actions;

export const getState = (state: RootState) => state.newAudit;
export const getSubmitStatus = (state: RootState) =>
  getState(state).submitStatus;
export const getCurrentStep = (state: RootState) => getState(state).currentStep;
export const getProgress = (state: RootState) => getState(state).progress;
export const getName = (state: RootState) => getState(state).auditName;
export const getIsNameValid = (state: RootState) => {
  const name = getName(state);
  return Boolean(name) && !getIsNameAlreadyTaken(state, name);
};

export const getIsCurrentStepValid = (state: RootState) => {
  const currentStep = getCurrentStep(state);
  switch (currentStep) {
    case 0:
      return getIsDataValidInfo(state) && getIsNameValid(state);
    case 1:
      return getIsDataValidConfig(state);
    default:
      return true;
  }
};

export const getIsDataDirty = (state: RootState) =>
  Boolean(getName(state)) ||
  getIsDataDirtyInfo(state) ||
  getIsDataDirtyConfig(state);

export const getPayload = (state: RootState) =>
  ({
    ...getPayloadInfo(state),
    ...getPayloadConfig(state),
    name: getName(state),
  } as IAuditApi);

export default newAuditSlice.reducer;
