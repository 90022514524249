import { RootState } from "../../app/store";
import { NEW_NOTE_ID } from "./notesSlice";
import { getAuditId } from "../audit/auditSlice";

export const getNotesState = (state: RootState) => state.notes;
export const getNotes = (state: RootState) => {
  const notes = getNotesState(state).data;
  return [...notes].sort((c1, c2) =>
    new Date(c1.createdAt) < new Date(c2.createdAt) ? 1 : -1
  );
};
export const getStatus = (state: RootState) => getNotesState(state).status;
export const getSubmitStatus = (state: RootState) =>
  getNotesState(state).submitStatus;
export const getDeleteStatus = (state: RootState) =>
  getNotesState(state).deleteStatus;
export const getError = (state: RootState) => getNotesState(state).error;
export const getCurrentText = (state: RootState) =>
  getNotesState(state).currentText;
export const getCurrentId = (state: RootState) =>
  getNotesState(state).currentId;
export const getIsInEditMode = (state: RootState, id: number) =>
  getCurrentId(state) === id;
export const getIsNewNoteInEditMode = (state: RootState) =>
  getCurrentId(state) === NEW_NOTE_ID;
export const getPayload = (state: RootState) => ({
  auditId: getAuditId(state),
  text: getCurrentText(state),
});
