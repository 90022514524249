import { useState } from "react";

const useModal = (): {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
} => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return {
    isModalOpen,
    openModal: () => setIsModalOpen(true),
    closeModal: () => setIsModalOpen(false),
  };
};

export default useModal;
