import { FunctionComponent, useRef } from "react";
import styles from "./userMenu.module.scss";
import useLogout from "../../../features/auth/useLogout";
import useDetectOutsideClick from "../useDetectOutsideClick";
import useDetectEscPressed from "../useDetectEscPressed";

interface IUserMenuProps {
  onOuterClick: () => void;
}

const UserMenu: FunctionComponent<IUserMenuProps> = ({ onOuterClick }) => {
  const [logOut] = useLogout();
  const ref = useRef<HTMLDivElement>(null);
  useDetectOutsideClick(ref, onOuterClick);
  useDetectEscPressed(onOuterClick);

  return (
    <div ref={ref} className={styles.userMenu}>
      <div className={`${styles.menuWrapper} ${styles.menu}`}>
        <ul>
          <li>
            <button onClick={() => logOut()}>Sign out</button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserMenu;
