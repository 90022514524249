import React, { FunctionComponent, useEffect } from "react";
import useOnMount from "../../hooks/useOnMount";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getStatus,
  getFilteredIncidentsCount,
  getFilteredIncidents,
  getCurrentStatusFilter,
  getFilters,
  setPostponeModalId,
} from "./incidentsSlice";
import { fetchIncidents } from "./incidentsThunks";
import Suspense from "../../components/Suspense/Suspense";
import { TabbedFilter } from "./components/IncidentsTable/TabbedFilter/TabbedFilter";
import { IncidentsTable } from "./components/IncidentsTable/IncidentsTable";
import { NoIncidents } from "./components/NoIncidents/NoIncidents";
import styles from "./incidents.module.scss";
import { IncidentsFilterOptions } from "../../shared/enums";
import { getIsPersonsLoaded } from "../lists/listsSlice";
import IncidentsFilter from "./components/IncidentsFilter/IncidentsFilter";
import { getIsWebSocketLive } from "../session/sessionSlice";
import LiveIndicator from "../../components/LiveIndicator/LiveIndicator";
import useMoveExpiredPostponedToActive from "./useMoveExpiredPostponedToActive";

const Incidents: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const incidentsCount = useAppSelector(getFilteredIncidentsCount);
  const incidentsFiltered = useAppSelector(getFilteredIncidents);
  const currentStatusFilter = useAppSelector(getCurrentStatusFilter);
  const currentFilters = useAppSelector(getFilters);
  const status = useAppSelector(getStatus);
  const isPersonsLoaded = useAppSelector(getIsPersonsLoaded);
  const isWebSocketLive = useAppSelector(getIsWebSocketLive);

  useMoveExpiredPostponedToActive(currentStatusFilter, dispatch);

  const noActiveIncidents =
    incidentsCount.active === 0 &&
    currentStatusFilter === IncidentsFilterOptions.ACTIVE &&
    currentFilters.length === 0;

  useOnMount(() => {
    dispatch(setPostponeModalId(undefined));
  });

  useEffect(() => {
    if (!isPersonsLoaded) return;
    dispatch(fetchIncidents());
  }, [isPersonsLoaded, dispatch]);

  return (
    <div className={styles.incidentsContainer}>
      <LiveIndicator
        className={styles.liveIndicator}
        isLive={isWebSocketLive}
      />
      <h1>Incidents</h1>
      <Suspense status={status} loaderType={"table"}>
        <div className={styles.incidentsTableContainer}>
          <div className={styles.incidentsDesktopFilters}>
            <TabbedFilter />
            <IncidentsFilter />
          </div>

          {noActiveIncidents ? (
            <NoIncidents />
          ) : (
            <IncidentsTable data={incidentsFiltered} />
          )}
        </div>
      </Suspense>
    </div>
  );
};

export default Incidents;
