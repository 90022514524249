import { FunctionComponent, useState } from "react";
import Icon from "@mdi/react";
import { mdiPlay } from "@mdi/js";
import { IconButton } from "@montel/montelpro-shared-components";
import { useAppDispatch } from "../../../app/hooks";
import { runSqlQuery } from "../../audit/auditThunks";
import { resetSqlResult } from "../../audit/auditSlice";
import styles from "../auditConfiguration.module.scss";

const TIMEOUT_MS = 7000;

const SqlQueryButton: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const [isRunSqlStatusDisabled, setIsRunSqlStatusDisabled] = useState(false);

  return (
    <IconButton
      icon={<Icon path={mdiPlay} />}
      altText={"Run SQL Statement"}
      size="small"
      kind="tertiary"
      disabled={isRunSqlStatusDisabled}
      className={styles.sqlQueryButton}
      onClick={() => {
        setIsRunSqlStatusDisabled(true);
        dispatch(resetSqlResult());
        dispatch(runSqlQuery());
        setTimeout(() => setIsRunSqlStatusDisabled(false), TIMEOUT_MS);
      }}
    />
  );
};

export default SqlQueryButton;
