import React, { FunctionComponent } from "react";
import styles from "./statusLabel.module.scss";

export type TStatusLabelColors =
  | "gray"
  | "green"
  | "yellow"
  | "red"
  | undefined;

interface IncidentsLabelProps {
  text: string;
  className?: string;
  color?: TStatusLabelColors;
}

export const StatusLabel: FunctionComponent<IncidentsLabelProps> = ({
  text,
  className = "",
  color = "gray",
}) => {
  return (
    <div className={`${styles.statusLabel} ${styles[color]} ${className}`}>
      {text}
    </div>
  );
};
