import { useAppSelector } from "../../../../app/hooks";
import { getUserId } from "../../../session/sessionSlice";
import { getAssigneesFilterOptions } from "../../incidentsSlice";
import { IncidentSourceType, Priority } from "../../../../shared/enums";

export const useMenuConfig = () => {
  const currentUserId = useAppSelector(getUserId);
  const assigneeFilterOptions = useAppSelector(getAssigneesFilterOptions);
  return [
    {
      label: "Assignee",
      value: "assigneeId",
      items: [
        {
          label: "Unassigned",
          value: undefined,
        },
        {
          label: "Me",
          value: currentUserId,
        },
        ...assigneeFilterOptions,
      ],
    },
    {
      label: "Priority",
      value: "priority",
      items: [
        {
          label: "Critical",
          value: Priority.Critical,
        },
        {
          label: "Medium",
          value: Priority.Medium,
        },
        {
          label: "Low",
          value: Priority.Low,
        },
      ],
    },
    {
      label: "Source Type",
      value: "incidentSourceType",
      items: [
        {
          label: "Audit",
          value: IncidentSourceType.AUDIT,
        },
        {
          label: "Fatal Error",
          value: IncidentSourceType.FATALERROR,
        },
      ],
    },
  ];
};
