import { FunctionComponent } from "react";
import Icon from "@mdi/react";
import { mdiChevronLeft } from "@mdi/js";
import MenuItem from "./MenuItem";
import styles from "./menu.module.scss";
import { IFilterHandler, MenuConfig } from "./Types";

export interface IMenuLevelTwoProps extends IFilterHandler {
  activeCategory: string;
  resetCategory: () => void;
  menuConfig: MenuConfig;
}

const MenuLevelTwo: FunctionComponent<IMenuLevelTwoProps> = ({
  activeCategory,
  resetCategory,
  menuConfig,
  isFilterSelectedSelector,
  toggleFilterAction,
}) => {
  const categoryConfig = menuConfig.find(
    (item) => item.value === activeCategory
  );
  if (!categoryConfig) return null;
  return (
    <ul>
      <li className={styles.underlined}>
        <button onClick={resetCategory} className={styles.back}>
          <span>
            <Icon path={mdiChevronLeft} />
            {categoryConfig.label}
          </span>
        </button>
      </li>
      {categoryConfig.items.map((item: any) => {
        const { label, value } = item;
        return (
          <li key={value}>
            <MenuItem
              category={activeCategory}
              label={label}
              value={value}
              isFilterSelectedSelector={isFilterSelectedSelector}
              toggleFilterAction={toggleFilterAction}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default MenuLevelTwo;
