import { FunctionComponent } from "react";
import { mdiOpenInNew } from "@mdi/js";
import { useAppSelector } from "../../../../app/hooks";
import MenuItem from "../../../../components/ContextMenu/MenuItem";
import { getSystemById } from "../../../lists/listsSlice";

export interface RelatedSystemButtonProps {
  relatedSystemId: string | number;
}

const RelatedSystemLinkButton: FunctionComponent<RelatedSystemButtonProps> = ({
  relatedSystemId,
}) => {
  const system = useAppSelector(getSystemById(relatedSystemId));
  const { url } = system || {};

  return (
    <MenuItem
      iconPath={mdiOpenInNew}
      text={"Related system details"}
      onClick={() => window.open(url, "_blank")}
    />
  );
};

export default RelatedSystemLinkButton;
