import { FunctionComponent } from "react";
import styles from "./sqlQueryResult.module.scss";
import { Button } from "@montel/montelpro-shared-components";
import Icon from "@mdi/react";
import { mdiArrowLeft } from "@mdi/js";
import { useNavigate } from "react-router-dom";

const SqlResultHeader: FunctionComponent = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <div className={styles.sqlResultsHeader}>
      <Button
        text={"Back"}
        iconLeft={<Icon path={mdiArrowLeft} />}
        onClick={goBack}
      />
      <h1>SQL Query Result</h1>
    </div>
  );
};

export default SqlResultHeader;
