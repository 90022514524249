import { ResourceStatus } from "../shared/enums";
import { IAuditJobUpdate, IAuditJobUpdateMessageAPI } from "./types";
const translateAuditJobMessage = (
  data: IAuditJobUpdateMessageAPI
): IAuditJobUpdate => {
  const { auditId, result, dateTime, error } = data;
  return {
    auditId,
    dateTime,
    runStatus: error
      ? ResourceStatus.EXCEPTION
      : result
      ? ResourceStatus.OK
      : ResourceStatus.FAILED,
    error,
  };
};

export default translateAuditJobMessage;
