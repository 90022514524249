import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { VerticalDividerLine } from "../../../../components/VerticalDividerLine/VerticalDividerLine";
import { Select } from "@montel/montelpro-shared-components";
import { TimePicker } from "../../../../components/TimePicker/TimePicker";
import styles from "./repeat.module.scss";
import {
  getAuditConfig,
  getIsAuditScheduleStartValid,
  getIsAuditScheduleStepValid,
  getIsAuditScheduleStopValid,
  getShowValidationErrors,
  setScheduleStart,
  setScheduleStep,
  setScheduleStop,
} from "../../auditConfigurationDataSlice";

const minutes = Array.from(Array(60).keys()).map((minute) => ({
  label: minute.toString(),
  value: minute,
}));

export const Repeat: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const {
    runStart: start,
    runStop: stop,
    runStep: step,
  } = useAppSelector(getAuditConfig);
  const showValidationErrors = useAppSelector(getShowValidationErrors);
  const isStartValid = useAppSelector(getIsAuditScheduleStartValid);
  const isStopValid = useAppSelector(getIsAuditScheduleStopValid);
  const isStepValid = useAppSelector(getIsAuditScheduleStepValid);

  return (
    <div className={styles.container}>
      <VerticalDividerLine />
      <div className={styles.containercontainer}>
        <div className={styles.step}>
          <span className={styles.text}>each</span>
          <Select
            className={styles.stepSelector}
            label={"Each"}
            hideLabel
            options={minutes}
            value={{ label: step?.toString(), value: step }}
            onChange={({ value }) => dispatch(setScheduleStep(value))}
            errorMessage={
              showValidationErrors && !isStepValid ? "Required" : undefined
            }
          />
          <span className={styles.text}>minute(s),</span>
        </div>
        <div className={styles.startStop}>
          <span className={styles.text}>from</span>
          <TimePicker
            value={start}
            onChange={(val) => dispatch(setScheduleStart(val))}
            errorMessage={
              showValidationErrors && !isStartValid ? "Required" : undefined
            }
          />
          <span className={styles.text}>to</span>
          <TimePicker
            value={stop}
            onChange={(val) => dispatch(setScheduleStop(val))}
            errorMessage={
              showValidationErrors && !isStopValid ? "Required" : undefined
            }
          />
        </div>
      </div>
    </div>
  );
};
