import React, { FunctionComponent, useState } from "react";
import { IIncident } from "../../incidentsTypes";
import {
  ISortableTableColumn,
  SortOrder,
} from "../../../../components/SortableTable/sortableTableTypes";
import { useSortableTable } from "../../../../components/SortableTable/useSortableTable";
import SortableTableHead, {
  SortableTableHeadProps,
} from "../../../../components/SortableTable/SortableTableHead";
import SortableTableHeadMobile from "../../../../components/SortableTable/SortableTableHeadMobile";
import IncidentsFilter from "../IncidentsFilter/IncidentsFilter";
import IncidentsFilterChips from "../IncidentsFilter/IncidentsFilterChips";
import IncidentsTableRow from "./IncidentsTableRow";
import styles from "../../../../components/SortableTable/sortableTable.module.scss";
import incidentsTableStyles from "./incidentsTable.module.scss";
import { comparatorAnyWithDate } from "../../../../utils/objectUtils/index";
import { comparatorDate } from "../../../../utils/objectUtils/index";
import ErrorDetailsModal from "./ErrorDetailsModal/ErrorDetailsModal";

interface IncidentsTableProps {
  data: Array<IIncident>;
}

const columns: ISortableTableColumn[] = [
  {
    label: "Source",
    key: "sourceName",
    sortable: true,
    comparator: comparatorAnyWithDate("createdAt"),
  },
  {
    label: "Type",
    key: "incidentSourceType",
    sortable: true,
    comparator: comparatorAnyWithDate("createdAt"),
  },
  {
    label: "Info",
    key: "sourceStatus",
    sortable: true,
    comparator: comparatorAnyWithDate("createdAt"),
  },
  {
    label: "Priority",
    key: "priority",
    sortable: true,
    comparator: comparatorAnyWithDate("createdAt"),
  },
  {
    label: "Date",
    key: "createdAt",
    sortable: true,
    comparator: comparatorDate,
    order: SortOrder.DESC,
  },
  {
    label: "Assignee",
    key: "assigneeName",
    sortable: true,
    comparator: comparatorAnyWithDate("createdAt"),
  },
  {
    label: "",
    key: "",
    sortable: false,
  },
];

export const IncidentsTable: FunctionComponent<IncidentsTableProps> = ({
  data,
}) => {
  const [tableData, tableHeaderProps] = useSortableTable(data, columns);
  const [errorDetailsModalContent, setErrorDetailsModalContent] = useState<
    IIncident | undefined
  >();
  const [isErrorDetailsModalOpen, setIsErrorDetailsModalOpen] =
    useState<boolean>(false);

  const onErrorDetailsModalOpen = (data: IIncident) => {
    setErrorDetailsModalContent(data);
    setIsErrorDetailsModalOpen(true);
  };

  const onErrorDetailsModalClose = () => {
    setIsErrorDetailsModalOpen(false);
    setErrorDetailsModalContent(undefined);
  };

  return (
    <section
      className={`${styles.sortableTable} ${incidentsTableStyles.incidentsTable}`}
    >
      <ErrorDetailsModal
        data={errorDetailsModalContent}
        isModalOpen={isErrorDetailsModalOpen}
        closeModal={onErrorDetailsModalClose}
      />
      <div className={incidentsTableStyles.mobileSortFilter}>
        <SortableTableHeadMobile
          {...({
            columns,
            ...tableHeaderProps,
          } as SortableTableHeadProps)}
        />
        <IncidentsFilter />
      </div>
      <IncidentsFilterChips />
      {data.length === 0 ? (
        <div className={incidentsTableStyles.noData}>
          <h2>There are no incidents matching selected criteria.</h2>
        </div>
      ) : (
        <table>
          <SortableTableHead
            {...({
              columns,
              ...tableHeaderProps,
            } as SortableTableHeadProps)}
          />
          <tbody>
            {(tableData as IIncident[]).map((incident) => (
              <IncidentsTableRow
                key={incident.id}
                columns={columns}
                data={incident}
                onErrorDetailsModalOpen={onErrorDetailsModalOpen}
              />
            ))}
          </tbody>
        </table>
      )}
    </section>
  );
};
