import { FunctionComponent, useRef, useState } from "react";
import FilterButton from "./FilterButton";
import Menu from "./Menu";
import useDetectOutsideClick from "../Header/useDetectOutsideClick";
import useDetectEscPressed from "../Header/useDetectEscPressed";
import { IFilterHandler, MenuConfig } from "./Types";
import styles from "./menu.module.scss";

export interface IFilterMenuProps extends IFilterHandler {
  className?: string;
  menuConfig: MenuConfig;
}

const FilterMenu: FunctionComponent<IFilterMenuProps> = ({
  className = "",
  menuConfig,
  isFilterSelectedSelector,
  toggleFilterAction,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const onOuterClick = () => setIsMenuOpen(false);

  useDetectOutsideClick(ref, onOuterClick);
  useDetectEscPressed(onOuterClick);

  const onFilterButtonClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div ref={ref} className={`${styles.filterMenu} ${className}`}>
      <FilterButton onClick={onFilterButtonClick} />
      {isMenuOpen && (
        <Menu
          menuConfig={menuConfig}
          toggleFilterAction={toggleFilterAction}
          isFilterSelectedSelector={isFilterSelectedSelector}
        />
      )}
    </div>
  );
};

export default FilterMenu;
