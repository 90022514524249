import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import authConfig from "./features/auth/authConfig";
import WebSocketProvider from "../src/websocket/WebSocketContext";

const container = document.getElementById("root")!;
const root = createRoot(container);

export const msalInstance = new PublicClientApplication(authConfig);

root.render(
  // <React.StrictMode>
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <WebSocketProvider>
        <App />
      </WebSocketProvider>
    </Provider>
  </MsalProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
