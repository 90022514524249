import isObject from "./isObject";

const _replacePropertyValue = (
  object: Object & Record<any, any>,
  findValue: any,
  replaceValue: any
) => {
  const keys = Object.keys(object);
  for (let key of keys) {
    if (isObject(object[key])) {
      _replacePropertyValue(object[key], findValue, replaceValue);
    } else {
      if (object[key] === findValue) {
        object[key] = replaceValue;
      }
    }
  }
  return object;
};

const replacePropertyValue = (
  object: any,
  findValue: any,
  replaceValue: any
) => {
  if (!isObject(object)) return object;
  return _replacePropertyValue({ ...object }, findValue, replaceValue);
};

export default replacePropertyValue;
