import { SortOrder } from "../../components/SortableTable/sortableTableTypes";

const comparatorDate = (key: string, order: SortOrder) => (a: any, b: any) => {
  const factor = order === SortOrder.ASC ? 1 : -1;
  if (new Date(a[key]) > new Date(b[key])) return 1 * factor;
  if (new Date(a[key]) < new Date(b[key])) return -1 * factor;
  return 0;
};

export default comparatorDate;
