import { FunctionComponent } from "react";
import StopServiceButton from "./StopServiceButton";
import StartServiceButton from "./StartServiceButton";

export interface StartStopServiceButtonProps {
  serviceName: string;
  isServiceEnabled: boolean;
}

const StartStopServiceButton: FunctionComponent<
  StartStopServiceButtonProps
> = ({ serviceName, isServiceEnabled }) => {
  if (isServiceEnabled) return <StopServiceButton serviceName={serviceName} />;
  return <StartServiceButton serviceName={serviceName} />;
};

export default StartStopServiceButton;