import { FunctionComponent } from "react";
import Icon from "@mdi/react";
import { mdiFilter, mdiChevronDown } from "@mdi/js";
import { Button as BaseButton } from "@montel/montelpro-shared-components";

export interface IFilterButtonProps {
  className?: string;
  onClick: () => void;
}

const FilterButton: FunctionComponent<IFilterButtonProps> = ({
  className = "",
  onClick,
}) => {
  return (
    <BaseButton
      className={className}
      text={"Filter"}
      iconLeft={<Icon path={mdiFilter} />}
      iconRight={<Icon path={mdiChevronDown} />}
      onClick={onClick}
    />
  );
};

export default FilterButton;
