import { FunctionComponent, ReactElement } from "react";
import styles from "./labelledContent.module.scss";

export interface LabelledContentProps {
  label: string;
  children: ReactElement | ReactElement[] | string | number | null | undefined;
  className?: string;
  labelClassName?: string;
  isHorizontal?: boolean;
}
const LabelledContent: FunctionComponent<LabelledContentProps> = ({
  label,
  children,
  className = "",
  labelClassName = "",
  isHorizontal = false,
}) => {
  return (
    <div
      className={`${isHorizontal ? styles.horizontal : styles.vertical}
      ${className} `}
    >
      <div className={`${styles.label} ${labelClassName}`}>{label}</div>
      <div className={`${styles.content}`}>{children || "None"}</div>
    </div>
  );
};

export default LabelledContent;
