const getInitials = (name: string): string => {
  let result = "";
  let parts = name.split(" ");
  if (parts.length >= 2) {
    result += parts[0].substring(0, 1);
    result += parts[1].substring(0, 1);
  } else {
    result += parts[0].substring(0, 2);
  }
  return result;
};

export default getInitials;
