import React, { FunctionComponent } from "react";
import styles from "./sidebarIcon.module.scss";

export interface SidebarIconProps {
  isSidebarOpen: boolean;
  onChange: (arg: any) => void;
}

export const SidebarIcon: FunctionComponent<SidebarIconProps> = ({
  isSidebarOpen,
  onChange,
}) => {
  return (
    <div className={styles.sidebarIcon}>
      <input
        type="checkbox"
        id={"openSidebarMenu"}
        checked={isSidebarOpen}
        onChange={onChange}
      />
      <label
        htmlFor={"openSidebarMenu"}
        className={`${styles.sidebarIconToggle}`}
      >
        <div
          className={`${styles.spinner} ${styles.diagonal} ${styles.part1}`}
        />
        <div className={`${styles.spinner} ${styles.horizontal}`} />
        <div
          className={`${styles.spinner} ${styles.diagonal} ${styles.part2}`}
        />
      </label>
    </div>
  );
};
