import { ServicesStatus } from "../../features/services/servicesTypes";
import { TStatusLabelColors } from "../../components/StatusLabel/StatusLabel";

const getServiceStatusLabelColor = (
  status: ServicesStatus
): TStatusLabelColors => {
  switch (status) {
    case ServicesStatus.ACTIVE:
      return "green";
    case ServicesStatus.NOT_RUNNING:
      return "red";
    case ServicesStatus.INTERNAL_ERROR:
      return "yellow";
    case ServicesStatus.NOT_RESPONDING:
      return "yellow";
    default:
      return "gray";
  }
};

export default getServiceStatusLabelColor;
