import React, { FunctionComponent } from "react";
import AssignUserButton from "./AssignUserButton";
import styles from "./assignUserButton.module.scss";
import tableStyles from "../incidentsTable.module.scss";

export interface AssigneeCellProps {
  label: string;
  incidentId: number;
  userId?: string;
  userName?: string;
  isClosed: boolean;
}

const AssigneeCell: FunctionComponent<AssigneeCellProps> = ({
  label,
  incidentId,
  userId,
  userName,
  isClosed,
}) => {
  return (
    <td data-title={label}>
      <div className={styles.assignUserCell}>
        {userId || isClosed ? (
          userName
        ) : (
          <>
            <AssignUserButton incidentId={incidentId} />
            <div className={`${styles.text} ${tableStyles.unassignedText}`}>
              Unassigned
            </div>
          </>
        )}
      </div>
    </td>
  );
};

export default AssigneeCell;
