import { SortOrder } from "../../components/SortableTable/sortableTableTypes";

const comparatorPriority =
  (key: string, order: SortOrder) => (a: any, b: any) => {
    const factor = order === SortOrder.ASC ? 1 : -1;
    if (a[key] > b[key]) return -1 * factor;
    if (a[key] < b[key]) return 1 * factor;
    return 0;
  };

export default comparatorPriority;
