import { FunctionComponent } from "react";
import useWindowDimensions from "./useWindowDimensions";
import ContentLoader from "react-content-loader";
import styles from "./suspense.module.scss";

const LoaderCard: FunctionComponent = () => {
  const { width } = useWindowDimensions();
  const isMobile = width < 428;
  const baseWidth = isMobile ? width : 700;
  const baseHeight = 600;
  const scale = isMobile ? 0.8 : 0.9;
  const Card = (
    <ContentLoader
      speed={2}
      width={baseWidth}
      height={baseHeight}
      backgroundColor="#425a66"
      foregroundColor="#516e7d"
    >
      <rect x="1" y="0" rx="0" ry="0" width="2" height={baseHeight} />
      <rect
        x={baseWidth - 2}
        y="1"
        rx="0"
        ry="0"
        width="2"
        height={baseHeight}
      />
      <rect x="1" y="0" rx="0" ry="0" width={baseWidth} height="2" />
      <rect
        x="1"
        y={baseHeight - 2}
        rx="0"
        ry="0"
        width={baseWidth}
        height="2"
      />
      <rect x="1" y="60" rx="0" ry="0" width={baseWidth} height="1" />
      <rect
        x="20"
        y="20"
        rx="0"
        ry="0"
        width={baseWidth * scale - 20}
        height="20"
      />
      <rect x={baseWidth - 32} y="22" rx="4" ry="4" width="12" height="12" />
      <rect x="20" y="80" rx="4" ry="4" width={baseWidth - 40} height="30" />
      <rect x="20" y="120" rx="4" ry="4" width={baseWidth - 40} height="30" />
      <rect x="20" y="160" rx="4" ry="4" width={baseWidth - 40} height="30" />
      <rect x="20" y="200" rx="4" ry="4" width={baseWidth - 40} height="30" />
      <rect x="20" y="240" rx="4" ry="4" width={baseWidth - 40} height="30" />
      <rect x="20" y="280" rx="4" ry="4" width={baseWidth - 40} height="30" />
    </ContentLoader>
  );

  return <div className={styles.skeletonLoaderContainer}>{Card}</div>;
};

export default LoaderCard;
