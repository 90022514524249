import { useNavigate } from "react-router-dom";
import { IAuditItem } from "../../../audit/auditTypes";
import appPages from "../../../../appPages";
import stylesAudits from "./auditsTable.module.scss";
import { Priority } from "../../../../shared/enums";
import { StatusLabel } from "../../../../components/StatusLabel/StatusLabel";
import { toTitleCase } from "../../../../utils/textUtils";
import getAuditStatusLabelColor from "../../../../utils/uiUtils/getAuditStatusLabelColor";
import { ISortableTableColumn } from "../../../../components/SortableTable/sortableTableTypes";
import useHighlightOnChange from "../../../../hooks/useHighlightOnChange";
import { useAppSelector } from "../../../../app/hooks";
import { getSearchTerm } from "../../auditsSlice";
import HighlightSearch from "../../../../components/HighlightSearch/HighlightSearch";
import AuditsContextMenu from "../ContextMenu/AuditsContextMenu";

const AuditTableRow = ({
  columns,
  data,
}: {
  columns: ISortableTableColumn[];
  data: IAuditItem;
}) => {
  const {
    id,
    name,
    lastRunDateTimeFormatted,
    runStatus,
    priority,
    isEnabled,
    isWaitingForRunResult,
  } = data;
  const navigate = useNavigate();
  const [highlight] = useHighlightOnChange(runStatus);

  const searchTerm = useAppSelector(getSearchTerm);

  return (
    <tr
      key={id}
      onClick={() => navigate(`${appPages.audit}/${id}`)}
      className={`${!isEnabled && stylesAudits.disabledAudit} ${
        highlight && stylesAudits.highlight
      }`}
    >
      <td className={stylesAudits.contextMenuCellMobile}>
        <AuditsContextMenu
          auditId={id}
          isWaitingForRunResult={isWaitingForRunResult}
        />
      </td>
      <td data-title={columns[0].label}>
        <HighlightSearch searchTerm={searchTerm}>{id}</HighlightSearch>
      </td>
      <td data-title={columns[1].label}>
        <div className={stylesAudits.auditName}>
          <HighlightSearch searchTerm={searchTerm}>{name}</HighlightSearch>
          {!isEnabled && (
            <span className={stylesAudits.disabledLabel}>Disabled</span>
          )}
        </div>
      </td>
      <td data-title={columns[2].label}>{Priority[priority]}</td>
      <td data-title={columns[3].label}>
        <HighlightSearch searchTerm={searchTerm}>
          {lastRunDateTimeFormatted}
        </HighlightSearch>
      </td>
      <td data-title={columns[4].label}>
        <StatusLabel
          text={toTitleCase(runStatus)}
          color={getAuditStatusLabelColor(runStatus)}
        />
      </td>
      <td className={stylesAudits.contextMenuCell}>
        <AuditsContextMenu
          auditId={id}
          isWaitingForRunResult={isWaitingForRunResult}
        />
      </td>
    </tr>
  );
};

export default AuditTableRow;
