import React, { FunctionComponent, useState } from "react";
import moment, { Moment } from "moment";
import { useAppDispatch } from "../../../../../../app/hooks";
import { DateTimeFormats } from "../../../../../../shared/enums";
import { timeStampConfig, getMomentFromPreset } from "./presetTimeStamps";
import { DateTimePicker } from "@montel/montelpro-shared-components";
import { RadioButtons } from "../../../../../../components/RadioButtons/RadioButtons";
import Modal from "../../../../../../components/Modal/Modal";
import styles from "./postponeModal.module.scss";
import getPostponeDate from "./getPostponeDate";
import { updateIncidentPostponedTo } from "../../../../incidentsThunks";

const timeStampOptions = timeStampConfig.map(({ label, nextTs }, idx) => {
  return {
    label: `${label}`,
    secondaryLabel: `${getMomentFromPreset(idx).format(
      DateTimeFormats.POSTPONE_OPTIONS
    )}`,
    value: idx,
  };
});

export interface PostponeModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  incidentId: number;
  postponedTo?: string;
}

export const PostponeModal: FunctionComponent<PostponeModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  incidentId,
}) => {
  const dispatch = useAppDispatch();

  const [postponeDatePreset, setPostponeDatePreset] = useState<
    number | undefined
  >(undefined);

  const [postponeDate, setPostponeDate] = useState<Moment | undefined>(
    undefined
  );
  const [isOkPressed, setIsOkPressed] = useState(false);

  const disablePastDt = (current: Moment) => {
    const yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  const onCancelPostpone = () => {
    setIsModalOpen(false);
  };

  const isDateValid = postponeDatePreset !== undefined || postponeDate;

  const onSavePostpone = () => {
    setIsOkPressed(true);
    if (!isDateValid) {
      return;
    }
    const postponeDateStr = getPostponeDate(postponeDatePreset, postponeDate);
    dispatch(
      updateIncidentPostponedTo({ incidentId, postponedTo: postponeDateStr })
    );
    setIsModalOpen(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      title={`Postpone incident`}
      onOk={() => onSavePostpone()}
      onCancel={() => onCancelPostpone()}
    >
      <div className={styles.postponeCard}>
        <RadioButtons
          label={"Postpone to"}
          onChange={(val) => {
            setPostponeDate(undefined);
            setPostponeDatePreset(val);
          }}
          options={timeStampOptions}
          value={postponeDatePreset}
        />
        <DateTimePicker
          label={"Or"}
          withTime={true}
          value={postponeDate}
          onChange={(val) => {
            setPostponeDatePreset(undefined);
            setPostponeDate(val);
          }}
          isValidDate={disablePastDt}
          errorMessage={
            isOkPressed && !isDateValid
              ? "Select a valid date in future."
              : undefined
          }
        />
      </div>
    </Modal>
  );
};
