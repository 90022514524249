import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ViewConfiguration from "../auditConfig/ViewConfiguration";
import ViewInformation from "../auditInfo/ViewInformation";
import { getName, setCurrentStep } from "./newAuditSlice";
import styles from "./newAudit.module.scss";
import { IconButton } from "@montel/montelpro-shared-components";
import Icon from "@mdi/react";
import { mdiPencil } from "@mdi/js";

const Review: FunctionComponent = () => {
  const name = useAppSelector(getName);
  const dispatch = useAppDispatch();
  return (
    <div className={styles.review}>
      <div className={styles.reviewSection}>
        <h2>{name}</h2>
        <div className={styles.infoContainer}>
          <ViewInformation />
        </div>
        <IconButton
          icon={<Icon path={mdiPencil} />}
          className={styles.editStepButton}
          onClick={() => dispatch(setCurrentStep(0))}
          altText="Edit information"
          kind="tertiary"
          size="small"
        />
      </div>
      <div className={`${styles.reviewSection} ${styles.configContainer}`}>
        <ViewConfiguration />
        <IconButton
          icon={<Icon path={mdiPencil} />}
          className={styles.editStepButton}
          onClick={() => dispatch(setCurrentStep(1))}
          altText="Edit configuration"
          kind="tertiary"
          size="small"
        />
      </div>
    </div>
  );
};
export default Review;
