import React, { FunctionComponent } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import {
  getCurrentStatusFilter,
  getFilteredIncidentsCount,
  setCurrentFilter,
} from "../../../incidentsSlice";
import { useAppDispatch } from "../../../../../app/hooks";
import styles from "./tabbedFilter.module.scss";

export const TabbedFilter: FunctionComponent = () => {
  const currentFilter = useAppSelector(getCurrentStatusFilter);
  const filterCounts = useAppSelector(getFilteredIncidentsCount);
  const dispatch = useAppDispatch();

  const filters = [
    {
      id: "active",
      name: "Active",
    },
    {
      id: "postponed",
      name: "Postponed",
    },
    {
      id: "completed",
      name: "Completed",
    },
  ];
  return (
    <nav className={styles.tabbedFilter}>
      {filters.map((filter) => {
        return (
          <button
            key={filter.id}
            onClick={() => {
              dispatch(setCurrentFilter(filter.id));
            }}
            className={`${currentFilter === filter.id && styles.active} ${
              filterCounts[filter.id] === 0 && styles.disabled
            }`}
          >
            {filter.name} <span>({filterCounts[filter.id]})</span>
          </button>
        );
      })}
    </nav>
  );
};
