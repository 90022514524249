import { getPayload } from "./notesSelectors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import apiRoutes from "../../apiRoutes";
import { RootState } from "../../app/store";
import {
  addSavedNote,
  deleteNoteAction,
  finishEditing,
  NEW_NOTE_ID,
  updateSavedNote,
} from "./notesSlice";
import { INoteApi } from "./notesTypes";
import translateApiNotes from "./translateApiNotes";

export const fetchNotes = createAsyncThunk(
  "notes/fetch",
  async (auditId: number | undefined) => {
    const response = await axios.get(apiRoutes.auditNotes(auditId));
    return ((response.data || []) as INoteApi[]).map(translateApiNotes);
  }
);

export const submitNote = createAsyncThunk(
  "notes/submit",
  async (id: number | undefined, { dispatch, getState }) => {
    const isNew = id === NEW_NOTE_ID;
    const payload = getPayload((getState as () => RootState)());
    try {
      if (isNew) {
        const response = await axios.post(apiRoutes.notes, payload);
        dispatch(addSavedNote(translateApiNotes(response.data)));
      } else {
        await axios.patch(`${apiRoutes.notes}/${id}`, payload);
        dispatch(updateSavedNote());
      }
      dispatch(finishEditing());
    } catch (error: any) {
      toast.error("Something went wrong, data was not submitted");
      throw error;
    }
  }
);

export const deleteNote = createAsyncThunk(
  "notes/delete",
  async (id: number, { dispatch }) => {
    try {
      await axios.delete(`${apiRoutes.notes}/${id}`);
      dispatch(deleteNoteAction(id));
      toast.success("Your note was successfully deleted");
    } catch (error: any) {
      toast.error("Something went wrong, the note was not deleted");
      throw error;
    }
  }
);
