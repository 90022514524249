import { IIncident, IIncidentApi } from "./incidentsTypes";
import { replaceNullToUndefined } from "../../utils/objectUtils";
import {
  IncidentSourceType,
  Priority,
  ResourceStatus,
} from "../../shared/enums";

const translateApiIncident = ({
  id,
  createdAt,
  assigneeId,
  postponedTo,
  isClosed,
  closedAt,
  audit,
  service,
  fatalError,
}: IIncidentApi): IIncident => {
  let newIncident = {
    id,
    createdAt,
    assigneeId,
    postponedTo,
    isClosed,
    closedAt,
  } as Partial<IIncident>;

  if (audit) {
    return replaceNullToUndefined({
      ...newIncident,
      priority: audit.priority,
      sourceId: audit.id,
      sourceName: audit.name,
      sourceStatus: audit.runStatus,
      incidentSourceType: IncidentSourceType.AUDIT,
    });
  }

  if (service) {
    return replaceNullToUndefined({
      ...newIncident,
      priority: Priority.Critical,
      sourceId: service.name,
      sourceName: service.name,
      sourceStatus: service.status,
      incidentSourceType: IncidentSourceType.SERVICE,
    });
  }

  if (fatalError) {
    return replaceNullToUndefined({
      ...newIncident,
      priority: Priority.Critical,
      sourceId: fatalError.id,
      sourceName: fatalError.applicationName,
      sourceStatus: ResourceStatus.UNKNOWN,
      incidentSourceType: IncidentSourceType.FATALERROR,
      fatalErrorContext: fatalError.sourceContext,
      fatalErrorMessage: fatalError.message,
      properties: fatalError.properties,
    });
  }

  return newIncident as IIncident;
};

export default translateApiIncident;
