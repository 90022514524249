import { INote, INoteApi } from "./notesTypes";

const translateApiNotes = ({
  createdAt,
  editedAt,
  auditId,
  authorId,
  text,
  id,
}: INoteApi): INote => ({
  createdAt,
  editedAt,
  auditId,
  authorId,
  text,
  id,
});

export default translateApiNotes;
