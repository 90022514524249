import { FunctionComponent } from "react";

const ScheduleTimeDescription: FunctionComponent<{
  runOnceStart: string | undefined;
  runStart: string | undefined;
  runStop: string | undefined;
  runStep: number | undefined;
}> = ({ runOnceStart, runStart, runStop, runStep }) =>
  runOnceStart ? (
    <span>Run once a day at {runOnceStart}.</span>
  ) : (
    <span>
      Repeat each {runStep} minute(s), from {runStart} to {runStop}.
    </span>
  );
export default ScheduleTimeDescription;
