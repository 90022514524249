import { FunctionComponent, useState } from "react";
import { ISortableTableColumn, SortOrder } from "./sortableTableTypes";
import styles from "./sortableTable.module.scss";

export interface SortableTableHeadProps {
  columns: ISortableTableColumn[];
  handleSorting: any;
  sortField: string;
  setSortField: (arg: string) => any;
  order: SortOrder;
  setOrder: (arg: SortOrder) => any;
}

const SortableTableHead: FunctionComponent<SortableTableHeadProps> = ({
  columns,
  sortField,
  setSortField,
  order,
  setOrder,
}) => {
  const [hoveringElementKey, setHoveringElementKey] = useState<
    undefined | string
  >(undefined);

  const handleSortingChange = (key: string) => {
    const sortOrder =
      key === sortField && order === SortOrder.ASC
        ? SortOrder.DESC
        : SortOrder.ASC;
    setSortField(key);
    setOrder(sortOrder);
  };

  return (
    <thead>
      <tr>
        {columns.map(({ label, key, sortable }) => {
          if (!key) {
            return <th key={"blank"}></th>;
          }
          const iconClassName = sortable
            ? sortField === key && order === SortOrder.ASC
              ? styles.up
              : sortField === key && order === SortOrder.DESC
              ? styles.down
              : styles.default
            : "";
          return (
            <th
              key={key}
              onClick={sortable ? () => handleSortingChange(key) : undefined}
              className={
                hoveringElementKey === key && sortField !== key
                  ? `${styles.up} ${styles.fastTransition}`
                  : iconClassName
              }
              onMouseOver={() => {
                setHoveringElementKey(key);
              }}
              onMouseOut={() => setHoveringElementKey(undefined)}
            >
              {label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default SortableTableHead;
