import { FunctionComponent } from "react";
import ReactMarkdown from "react-markdown";
import { useAppSelector } from "../../app/hooks";
import { Priority } from "../../shared/enums";
import LabelledContent from "../../components/LabelledContent/LabelledContent";
import { getAuditInfo } from "./auditInformationDataSlice";
import RelatedSystemLinks from "./RelatedSystemLinks";
import styles from "./auditInformation.module.scss";

export const ViewInformation: FunctionComponent<{ className?: string }> = ({
  className,
}) => {
  const { description, relatedSystems, solutionProposal, priority } =
    useAppSelector(getAuditInfo);

  return (
    <>
      <LabelledContent label="Priority">{Priority[priority]}</LabelledContent>
      <LabelledContent label="Related systems">
        <RelatedSystemLinks systems={relatedSystems} />
      </LabelledContent>
      <LabelledContent label="Short description">
        <ReactMarkdown className={styles.markDown}>
          {description || "None"}
        </ReactMarkdown>
      </LabelledContent>
      <LabelledContent label="Solution proposal">
        <ReactMarkdown className={styles.markDown}>
          {solutionProposal || "None"}
        </ReactMarkdown>
      </LabelledContent>
    </>
  );
};

export default ViewInformation;
