import { FunctionComponent } from "react";
import FilterMenu from "../../../../components/FilterMenu/FilterMenu";
import { getIsFilterSelected, toggleFilter } from "../../incidentsSlice";
import { useMenuConfig } from "./useMenuConfig";

const IncidentsFilter: FunctionComponent = () => {
  const menuConfig = useMenuConfig();

  return (
    <FilterMenu
      menuConfig={menuConfig}
      isFilterSelectedSelector={getIsFilterSelected}
      toggleFilterAction={toggleFilter}
    />
  );
};

export default IncidentsFilter;
