import { useEffect } from "react";

const useDetectEscPressed = (onDetected: any) => {
  useEffect(() => {
    const handleEscPressed = (e: any) => {
      if (e.key === "Escape") onDetected();
    };
    document.addEventListener("keydown", handleEscPressed);

    return () => {
      document.removeEventListener("keydown", handleEscPressed);
    };
  }, [onDetected]);
};

export default useDetectEscPressed;
