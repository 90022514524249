import { FunctionComponent } from "react";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { useAppDispatch } from "../../../app/hooks";
import { getOptionByValue } from "../../../utils/objectUtils";
import styles from "../menu.module.scss";

export interface IAuditFilterChipProps {
  category: string;
  value: any;
  menuConfig: any;
  toggleFilter: any;
}

const FilterChip: FunctionComponent<IAuditFilterChipProps> = ({
  category,
  value,
  menuConfig,
  toggleFilter,
}) => {
  const dispatch = useAppDispatch();
  const categoryConfig = menuConfig.find(
    (item: any) => item.value === category
  );
  const valueLabel = getOptionByValue(categoryConfig?.items, value)?.label;
  return (
    <button
      className={styles.filterChip}
      onClick={() => dispatch(toggleFilter({ category, value }))}
    >
      {`${categoryConfig?.label} : ${valueLabel}`} <Icon path={mdiClose} />
    </button>
  );
};

export default FilterChip;
