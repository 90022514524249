import { FunctionComponent } from "react";
import Icon from "@mdi/react";
import styles from "./ContextMenu.module.scss";

export interface MenuItemProps {
  iconPath: string;
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

const MenuItem: FunctionComponent<MenuItemProps> = ({
  iconPath,
  text,
  onClick,
  disabled = false,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${disabled && styles.disabledItem}`}
    >
      <Icon path={iconPath} />
      {text}
    </button>
  );
};

export default MenuItem;
