import { FunctionComponent } from "react";
import RunAuditButton from "./RunAuditButton";
import ContextMenu from "../../../../components/ContextMenu/ContextMenu";
import styles from "./auditsContextMenu.module.scss";
import { mdiSync } from "@mdi/js";
import Icon from "@mdi/react";

export interface AuditsContextMenuProps {
  auditId: number;
  disabled?: boolean;
  className?: string;
  isWaitingForRunResult?: boolean;
}

const AuditsContextMenu: FunctionComponent<AuditsContextMenuProps> = ({
  auditId,
  disabled = false,
  className,
  isWaitingForRunResult,
}) => {
  const menuItems = [<RunAuditButton auditId={auditId} key={1} />];

  if (isWaitingForRunResult)
    return <Icon path={mdiSync} className={styles.waitingIcon} />;

  return (
    <ContextMenu
      children={menuItems}
      disabled={disabled}
      className={`${styles.auditsMenuDropdown} ${
        disabled && styles.disabledMenu
      }  ${className && className}`}
    />
  );
};

export default AuditsContextMenu;
