import { Priority } from "./../../../../shared/enums";
import { ResourceStatus } from "../../../../shared/enums";

const menuConfig = [
  {
    label: "Priority",
    value: "priority",
    items: [
      {
        label: "Critical",
        value: Priority.Critical,
      },
      {
        label: "Medium",
        value: Priority.Medium,
      },
      {
        label: "Low",
        value: Priority.Low,
      },
    ],
  },
  {
    label: "Status",
    value: "runStatus",
    items: [
      {
        label: "Ok",
        value: ResourceStatus.OK,
      },
      {
        label: "Failed",
        value: ResourceStatus.FAILED,
      },
      {
        label: "Exception",
        value: ResourceStatus.EXCEPTION,
      },
      {
        label: "Unknown",
        value: ResourceStatus.UNKNOWN,
      },
    ],
  },
  {
    label: "Last run",
    value: "lastRunDateTimeFormatted",
    items: [
      {
        label: "Today",
        value: "Today",
      },
    ],
  },
];

export default menuConfig;
