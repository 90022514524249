import { FunctionComponent } from "react";
import Modal from "../Modal/Modal";

const ConfirmationModal: FunctionComponent<{
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
}> = ({ isOpen: isModalOpen, onOk, onCancel }) => (
  <Modal
    isOpen={isModalOpen}
    title="Discard changes?"
    onOk={onOk}
    onCancel={onCancel}
  >
    <div>All unsaved changes will be lost. Continue?</div>
  </Modal>
);

export default ConfirmationModal;
