import moment, { DurationInputArg1, DurationInputArg2, Moment } from "moment";

export const timeStampConfig = [
  {
    label: "10 min later",
    nextTs: {
      delta: {
        unit: "minutes",
        amount: 10,
      },
    },
  },
  {
    label: "30 min later",
    nextTs: {
      delta: {
        unit: "minutes",
        amount: 30,
      },
    },
  },
  {
    label: "next hour",
    nextTs: {
      delta: {
        unit: "hours",
        amount: 1,
      },
    },
  },
  {
    label: "tomorrow",
    nextTs: {
      delta: {
        unit: "days",
        amount: 1,
      },
      exact: {
        hour: 8,
        minute: 0,
      },
    },
  },
];

export const getMomentFromPreset = (presetIdx: number): Moment => {
  let now = moment();
  const preset = timeStampConfig[presetIdx].nextTs;
  if (preset.delta) {
    const { unit, amount } = preset.delta;
    now.add(amount as DurationInputArg1, unit as DurationInputArg2);
  }
  if (preset.exact) {
    const { hour, minute } = preset.exact;
    now.set("hour", hour);
    now.set("minute", minute);
  }
  return now;
};
