import { FunctionComponent, useState } from "react";
import { NavLink } from "react-router-dom";
import { SidebarIcon } from "./SidebarIcon/SidebarIcon";
import { UserBadge } from "./UserBadge/UserBadge";
import styles from "./header.module.scss";
import { getIsAuthCompleted } from "../../features/session/sessionSlice";
import { useAppSelector } from "../../app/hooks";
import UserMenu from "./UserMenu/UserMenu";
import appPages from "../../appPages";

export const Header: FunctionComponent<any> = () => {
  const mainHeaderItems = [
    {
      path: appPages.audits,
      name: "Audits",
    },
    {
      path: appPages.services,
      name: "Services",
    },
    {
      path: appPages.incidents,
      name: "Incidents",
    },
  ];

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const isAuthCompleted = useAppSelector(getIsAuthCompleted);

  const toggleUserMenuOpen = () => {
    setUserMenuOpen(!isUserMenuOpen);
  };

  return (
    <header className={styles.mainHeader}>
      <SidebarIcon
        isSidebarOpen={isSidebarOpen}
        onChange={({ target }) => setIsSidebarOpen(target.checked)}
      />
      <div
        className={`${styles.mainMenu} ${isSidebarOpen && styles.open}`}
        id={"mainMenu"}
      >
        <ul className={styles.mainMenuInner}>
          {mainHeaderItems.map((i) => {
            return (
              <li key={i.path} onClick={() => setIsSidebarOpen(false)}>
                <NavLink
                  to={i.path}
                  className={({ isActive }) =>
                    isActive ? `${styles.active}` : undefined
                  }
                  end
                >
                  {i.name}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
      <div>
        {isAuthCompleted && (
          <>
            <UserBadge onClick={toggleUserMenuOpen} />
            {isUserMenuOpen && <UserMenu onOuterClick={toggleUserMenuOpen} />}
          </>
        )}
      </div>
    </header>
  );
};
