import axios, { AxiosRequestConfig } from "axios";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { WATCHER_API } from "../../apiRoutes";
import getRoleFromToken from "./getRoleFromToken";
import { setRole } from "../session/sessionSlice";
import getValidAccessToken from "./getValidAccessToken";

const applyAxiosInterceptor = (
  msalInstance: IPublicClientApplication,
  accounts: AccountInfo[],
  dispatch: any
) => {
  axios.interceptors.request.use(
    async (requestConfig: AxiosRequestConfig) => {
      if (!isRequestToWatcherAPI(requestConfig)) {
        return requestConfig;
      }
      const accessToken = await getValidAccessToken(msalInstance, accounts);
      if (accessToken) {
        setAuthorizationHeader(requestConfig, accessToken);
        dispatch(setRole(getRoleFromToken(accessToken)));
      }
      return requestConfig;
    },
    (error) => Promise.reject(error)
  );
};

const setAuthorizationHeader = (
  requestConfig: AxiosRequestConfig<any>,
  accessToken: string
) => {
  requestConfig.headers!["Authorization"] = "Bearer " + accessToken;
};
const isRequestToWatcherAPI = (requestConfig: AxiosRequestConfig<any>) => {
  return requestConfig.url?.includes(WATCHER_API);
};

export default applyAxiosInterceptor;
