import { getIsNameAlreadyTaken } from "./../audits/auditsSlice";
import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "@montel/montelpro-shared-components/enums";
import { IAuditGeneralData, IAuditState } from "./auditTypes";
import { RootState } from "../../app/store";
import { AuditViewTabs } from "./auditEnums";
import {
  getIsDataDirtyConfig,
  getIsDataValidConfig,
  getPayloadConfig,
  getAuditConnectionId,
  getAuditStatement,
} from "../auditConfig/auditConfigurationDataSlice";
import {
  getIsDataDirtyInfo,
  getIsDataValidInfo,
  getPayloadInfo,
} from "../auditInfo/auditInformationDataSlice";
import { getUserId } from "../session/sessionSlice";
import {
  fetchAuditById,
  runAudit,
  runSqlQuery,
  saveData,
  toggleIsSchedulingEnabled,
} from "./auditThunks";
import { ResourceStatus } from "../../shared/enums";

export const initialState: IAuditState = {
  loadStatus: RequestStatus.UNLOADED,
  loadError: undefined,
  isWaitingForRunResult: false,
  runSqlStatus: RequestStatus.UNLOADED,
  sqlResult: undefined,
  data: {
    id: undefined,
    masterName: "",
    name: "",
    isEnabled: true,
    lastRunStatus: ResourceStatus.UNKNOWN,
    lastRunDateTime: undefined,
    auditError: undefined,
    subscribers: [],
  } as IAuditGeneralData,
  currentTab: AuditViewTabs.INFORMATION,
  isEditMode: false,
  submitStatus: RequestStatus.UNLOADED,
};

export const auditSlice = createSlice({
  name: "audit",
  initialState,
  reducers: {
    setCurrentTab: (state, { payload }) => {
      state.currentTab = payload;
      state.isEditMode = false;
      state.submitStatus = RequestStatus.UNLOADED;
    },
    toggleIsEnabled: (state) => {
      state.data.isEnabled = !state.data.isEnabled;
    },
    setIsEditMode: (state, { payload }) => {
      state.isEditMode = payload;
      state.submitStatus = RequestStatus.UNLOADED;
    },
    setName: (state, { payload }) => {
      state.data.name = payload;
    },
    persistName: (state) => {
      state.data.masterName = state.data.name;
    },
    resetName: (state) => {
      state.data.name = state.data.masterName;
    },
    resetSqlResult: (state) => {
      state.sqlResult = undefined;
      state.runSqlStatus = RequestStatus.UNLOADED;
    },
    updateAuditLastRunInfo: (state, { payload }) => {
      const { auditId, runStatus, dateTime, error } = payload;
      if (state.data.id === auditId) {
        state.data.lastRunStatus = runStatus;
        state.data.lastRunDateTime = dateTime;
        state.data.auditError = error;
        state.isWaitingForRunResult = false;
      }
    },
    toggleUserAuditSubscription: (state, { payload }) => {
      const isSubscribed = state.data.subscribers.includes(payload);
      state.data.subscribers = isSubscribed
        ? state.data.subscribers.filter((id) => id !== payload)
        : [...state.data.subscribers, payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuditById.pending, (state) => {
        return {
          ...initialState,
          loadStatus: RequestStatus.LOADING,
        };
      })
      .addCase(fetchAuditById.fulfilled, (state, { payload }) => {
        state.loadStatus = RequestStatus.SUCCESS;
        state.data = { ...payload! };
      })
      .addCase(fetchAuditById.rejected, (state, action) => {
        state.loadStatus = RequestStatus.ERROR;
        state.loadError = action.error;
      })
      .addCase(toggleIsSchedulingEnabled.rejected, (state) => {
        state.data.isEnabled = !state.data.isEnabled;
      })
      .addCase(runAudit.pending, (state) => {
        state.isWaitingForRunResult = true;
      })
      .addCase(runAudit.rejected, (state) => {
        state.isWaitingForRunResult = false;
      })
      .addCase(saveData.pending, (state) => {
        state.submitStatus = RequestStatus.LOADING;
      })
      .addCase(saveData.fulfilled, (state) => {
        state.submitStatus = RequestStatus.SUCCESS;
        state.isEditMode = false;
      })
      .addCase(saveData.rejected, (state) => {
        state.submitStatus = RequestStatus.ERROR;
      })
      .addCase(runSqlQuery.pending, (state, { payload }) => {
        state.runSqlStatus = RequestStatus.LOADING;
      })
      .addCase(runSqlQuery.fulfilled, (state, { payload }) => {
        state.runSqlStatus = RequestStatus.SUCCESS;
        state.sqlResult = payload;
      });
  },
});

export const {
  setCurrentTab,
  toggleIsEnabled,
  setIsEditMode,
  setName,
  persistName,
  resetName,
  resetSqlResult,
  updateAuditLastRunInfo,
  toggleUserAuditSubscription,
} = auditSlice.actions;

export const getAuditState = (state: RootState) => state.audit;
export const getAuditLoadStatus = (state: RootState) =>
  getAuditState(state).loadStatus;
export const getAuditLoadError = (state: RootState) =>
  getAuditState(state).loadError;
export const getAuditGeneralData = (state: RootState) =>
  getAuditState(state).data;
export const getAuditId = (state: RootState) => getAuditGeneralData(state).id;
export const getIsWaitingForRunResult = (state: RootState) =>
  getAuditState(state).isWaitingForRunResult;
export const getIsEnabled = (state: RootState) =>
  getAuditState(state).data.isEnabled;
export const getCurrentTab = (state: RootState) =>
  getAuditState(state).currentTab;
export const getIsEditMode = (state: RootState) =>
  getAuditState(state).isEditMode;
export const getSubmitStatus = (state: RootState) =>
  getAuditState(state).submitStatus;
export const getPersistedName = (state: RootState) =>
  getAuditGeneralData(state).masterName;
export const getName = (state: RootState) => getAuditGeneralData(state).name;

export const getIsDataDirty = (state: RootState) => {
  const currentTab = getCurrentTab(state);
  switch (currentTab) {
    case AuditViewTabs.INFORMATION:
      return getIsDataDirtyInfo(state);
    case AuditViewTabs.CONFIGURATION:
      return getIsDataDirtyConfig(state);
    default:
      return false;
  }
};

export const getPayload = (state: RootState) => {
  const currentTab = getCurrentTab(state);
  switch (currentTab) {
    case AuditViewTabs.INFORMATION:
      return getPayloadInfo(state);
    case AuditViewTabs.CONFIGURATION:
      return { ...getPayloadConfig(state), name: getName(state) };
    default:
      return undefined;
  }
};

export const getIsDataValid = (state: RootState) => {
  const currentTab = getCurrentTab(state);
  switch (currentTab) {
    case AuditViewTabs.INFORMATION:
      return getIsDataValidInfo(state);
    case AuditViewTabs.CONFIGURATION:
      return getIsDataValidConfig(state);
    default:
      return false;
  }
};

export const getIsNameValid = (state: RootState) => {
  const name = getName(state);
  if (!name) return false;
  if (!getIsNameChanged(state)) return true;
  return !getIsNameAlreadyTaken(state, name);
};

export const getIsNameChanged = (state: RootState) =>
  getName(state) !== getPersistedName(state);

export const isOnInfoTab = (state: RootState) => {
  const currentTab = getCurrentTab(state);
  return currentTab === AuditViewTabs.INFORMATION;
};

export const getSqlRunStatus = (state: RootState) =>
  getAuditState(state).runSqlStatus;

export const getSqlResult = (state: RootState) =>
  getAuditState(state).sqlResult;

export const getSqlExecutionPayload = (state: RootState) => ({
  auditId: getAuditId(state),
  connectionId: getAuditConnectionId(state),
  statement: getAuditStatement(state),
});

export const getIsSubscribedToAudit = (state: RootState) => {
  const userId = getUserId(state);
  if (userId === undefined) return false;
  return getAuditGeneralData(state).subscribers.includes(userId);
};

export default auditSlice.reducer;
