import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useOnMount from "../../hooks/useOnMount";
import {
  getAuditLoadStatus,
  getAuditLoadError,
  getCurrentTab,
} from "./auditSlice";
import { fetchAuditById } from "./auditThunks";
import Suspense from "../../components/Suspense/Suspense";
import { AuditRunStatus } from "./runStatus/AuditRunStatus";
import { AuditTabs } from "./tabs/AuditTabs";
import Card from "../../components/Card/Card";
import styles from "./audit.module.scss";
import Header from "./header/Header";
import { AuditViewTabs } from "./auditEnums";
import InformationTab from "./InformationTab";
import ConfigurationTab from "./ConfigurationTab";
import { fetchAudits, getIsDataLoaded } from "../audits/auditsSlice";
import NotesTab from "./NotesTab";
import AuditContextMenu from "./contextMenu/AuditContextMenu";

const Audit: FunctionComponent = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const dispatch = useAppDispatch();
  const { id: auditId } = useParams();
  const status = useAppSelector(getAuditLoadStatus);
  const error = useAppSelector(getAuditLoadError);
  const selectedTab = useAppSelector(getCurrentTab);
  const isAuditsLoaded = useAppSelector(getIsDataLoaded);

  useOnMount(() => {
    const id = auditId ? parseInt(auditId, 10) : undefined;
    dispatch(fetchAuditById(id));
    !isAuditsLoaded && dispatch(fetchAudits());
  });

  return (
    <div className={styles.auditContainer}>
      <Suspense status={status} error={error} loaderType={"card"}>
        <Card
          title={<Header />}
          onClose={goBack}
          className={styles.card}
          actionButton={<AuditContextMenu />}
        >
          <AuditRunStatus />
          <AuditTabs />
          <section>
            {
              {
                [AuditViewTabs.INFORMATION]: <InformationTab />,
                [AuditViewTabs.CONFIGURATION]: <ConfigurationTab />,
                [AuditViewTabs.NOTES]: <NotesTab />,
              }[selectedTab as string]
            }
          </section>
        </Card>
      </Suspense>
    </div>
  );
};

export default Audit;
