import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  DividerLine,
  SubmitButton,
} from "@montel/montelpro-shared-components";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

import styles from "./bottomNavigation.module.scss";
import { RequestStatus } from "@montel/montelpro-shared-components/enums";

const BottomNavigation: FunctionComponent<{
  totalSteps: number;
  currentStep: number;
  onNext: () => void;
  onBack: () => void;
  onSubmit: (payload: any) => void | Promise<any>;
  submitStatus: RequestStatus;
  onSubmitSuccess?: () => void | Promise<any>;
}> = ({
  totalSteps,
  currentStep,
  onNext,
  onBack,
  onSubmit,
  submitStatus,
  onSubmitSuccess,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (submitStatus === RequestStatus.SUCCESS) {
      onSubmitSuccess && onSubmitSuccess();
    }
  }, [submitStatus, navigate, onSubmitSuccess]);

  return (
    <div className={styles.bottomNavigationContainer}>
      <DividerLine />
      <div className={styles.bottomNavigation}>
        <Button
          text={"Back"}
          disabled={currentStep === 0}
          onClick={onBack}
          iconLeft={<Icon path={mdiChevronLeft} />}
        />
        {currentStep < totalSteps - 1 ? (
          <Button
            text={"Next"}
            kind={"primary"}
            iconRight={<Icon path={mdiChevronRight} />}
            onClick={onNext}
          />
        ) : (
          <SubmitButton
            status={submitStatus}
            text={"Submit"}
            kind={"primary"}
            onClick={onSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default BottomNavigation;
