import debugLoggerOptions from "./authDebugLoggerOptions";

const authConfig = {
  auth: {
    clientId: process.env.REACT_APP_AD_APPLICATION_ID || "",
    authority:
      "https://login.microsoftonline.com/" + process.env.REACT_APP_AD_TENANT_ID,
    redirectUri: process.env.REACT_APP_AD_REDIRECT_URI || "",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: undefined && debugLoggerOptions,
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequestScopes = {
  scopes: [],
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  watcherAPI: {
    scopes: [process.env.REACT_APP_WATCHER_API_SCOPE || ""],
  },
  // graphMe: {
  //   endpoint: "https://graph.microsoft.com/v1.0/me",
  //   scopes: ["User.Read"],
  // },
};

export default authConfig;
