import React, { FunctionComponent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Modal from "../../../../components/Modal/Modal";
import { Select } from "@montel/montelpro-shared-components";
import { getOptionByValue } from "../../../../utils/objectUtils";
import { updateData } from "../../servicesSlice";
import { RequestStatus } from "@montel/montelpro-shared-components/enums";
import axios from "axios";
import apiRoutes from "../../../../apiRoutes";
import { toast } from "react-toastify";
import { getSystemsOptions } from "../../../lists/listsSlice";

export interface RelatedServicesModal {
  isModalOpen: boolean;
  closeModal: () => void;
  serviceName: string;
  relatedSystemId?: string | number;
}

const RelatedServicesModal: FunctionComponent<RelatedServicesModal> = ({
  isModalOpen,
  closeModal,
  serviceName,
  relatedSystemId,
}) => {
  const dispatch = useAppDispatch();
  const systemsOptions = useAppSelector(getSystemsOptions);
  const [submitStatus, setSubmitStatus] = useState(RequestStatus.UNLOADED);
  const [draftRelatedSystemId, setDraftRelatedSystemId] =
    useState(relatedSystemId);
  const isRelatedSystemIdChanged = relatedSystemId !== draftRelatedSystemId;

  const saveRelatedSystemId = async () => {
    setSubmitStatus(RequestStatus.LOADING);
    let isSuccess = false;
    try {
      const payload = {
        serviceName,
        relatedSystemId: draftRelatedSystemId || null,
      };
      await axios.patch(apiRoutes.services, payload);
      isSuccess = true;
    } catch (error: any) {
      toast.error("Something went wrong. System was not saved properly.");
    }
    setSubmitStatus(RequestStatus.UNLOADED);
    return isSuccess;
  };

  const onCancel = () => {
    setSubmitStatus(RequestStatus.UNLOADED);
    setDraftRelatedSystemId(relatedSystemId);
    closeModal();
  };

  const onSave = async () => {
    if (!isRelatedSystemIdChanged) return;
    const isSaved = await saveRelatedSystemId();
    if (isSaved) {
      dispatch(
        updateData({ name: serviceName, relatedSystemId: draftRelatedSystemId })
      );
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      title={serviceName || ""}
      onOk={onSave}
      onCancel={onCancel}
      okButtonText="Save"
      onOkStatus={submitStatus}
    >
      <Select
        label={"Related system"}
        options={[{ value: undefined, label: "Unassigned" }, ...systemsOptions]}
        value={
          getOptionByValue(systemsOptions, draftRelatedSystemId) || {
            value: undefined,
            label: "Unassigned",
          }
        }
        onChange={({ value }) => {
          setDraftRelatedSystemId(value);
        }}
      />
    </Modal>
  );
};

export default RelatedServicesModal;
