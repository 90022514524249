import { IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Button } from "@montel/montelpro-shared-components";
import { FunctionComponent } from "react";
import { toast } from "react-toastify";
import { loginRequestScopes } from "./authConfig";

const LoginButton: FunctionComponent = () => {
  const { instance } = useMsal();
  return (
    <Button text="Login" onClick={() => handleLoginWithRedirect(instance)} />
  );
};

const handleLoginWithRedirect = (authInstance: IPublicClientApplication) => {
  authInstance.loginRedirect(loginRequestScopes).catch((e) => {
    console.error(e);
    toast.error("Something went wrong on login.");
  });
};

export default LoginButton;
