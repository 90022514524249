import { FunctionComponent } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { getIsSubscribedToAudit } from "../auditSlice";
import { toggleAuditSubscription } from "../auditThunks";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import MenuItem from "../../../components/ContextMenu/MenuItem";

const FollowButton: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const isSubscribed = useAppSelector(getIsSubscribedToAudit);
  const text = isSubscribed ? "Unfollow" : "Follow";
  const iconPath = isSubscribed ? mdiEyeOff : mdiEye;

  return (
    <MenuItem
      iconPath={iconPath}
      text={text}
      onClick={() => dispatch(toggleAuditSubscription())}
    />
  );
};

export default FollowButton;
