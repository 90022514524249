import { FunctionComponent } from "react";
import styles from "./verticalDividerLine.module.scss";

export interface IVerticalDividerLineProps {
  className?: string;
  [otherProps: string]: any;
}

export const VerticalDividerLine: FunctionComponent<
  IVerticalDividerLineProps
> = ({ className = "", ...otherProps }) => (
  <div className={styles.verticalDividerLine} {...otherProps} />
);
