import { useAccount, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setUser } from "../session/sessionSlice";

const useSetUser = (): void => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (account) {
      dispatch(
        setUser({
          userId: account?.localAccountId,
          userName: account?.name,
        })
      );
    }
  }, [account, dispatch]);
};

export default useSetUser;
