import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useOnMount from "../../hooks/useOnMount";
import { getIsWebSocketLive } from "../session/sessionSlice";
import {
  getLoadStatus,
  getSearchTerm,
  setSearchTerm,
  getError,
  setRelatedServiceNameModal,
} from "./servicesSlice";
import { fetchServices } from "./servicesThunks";
import styles from "./services.module.scss";
import LiveIndicator from "../../components/LiveIndicator/LiveIndicator";
import { Search } from "@montel/montelpro-shared-components";
import ServicesFilter from "./components/ServicesFilter/ServicesFilter";
import ServicesTable from "./components/ServicesTable/ServicesTable";
import stylesAuditsFilter from "../audits/components/AuditsFilter/auditsFilter.module.scss";
import Suspense from "../../components/Suspense/Suspense";
import ApiError from "../../components/ApiError/ApiError";

const Services: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const searchTerm = useAppSelector(getSearchTerm);
  const isWebSocketLive = useAppSelector(getIsWebSocketLive);
  const loadStatus = useAppSelector(getLoadStatus);
  const error = useAppSelector(getError);

  useOnMount(() => {
    dispatch(fetchServices());
    dispatch(setRelatedServiceNameModal(undefined));
  });

  return (
    <div className={styles.servicesContainer}>
      <LiveIndicator
        isLive={isWebSocketLive}
        className={styles.liveIndicator}
      />
      <div className={styles.header}>
        <div>
          <h1>Services</h1>
        </div>
        <div>
          <Search
            value={searchTerm}
            onChange={({ target }) => dispatch(setSearchTerm(target.value))}
            className={styles.searchField}
          />
          <ServicesFilter className={stylesAuditsFilter.hideDesktop} />
        </div>
      </div>
      <Suspense status={loadStatus} error={<ApiError error={error} />}>
        <ServicesTable />
      </Suspense>
    </div>
  );
};

export default Services;
