import React, { FunctionComponent } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import useOnMount from "../../hooks/useOnMount";
import {
  getAuditsStatus,
  getAuditsError,
  getSearchTerm,
  setSearchTerm,
  fetchAudits,
} from "./auditsSlice";
import { getIsAdmin, getIsWebSocketLive } from "../session/sessionSlice";
import styles from "./audits.module.scss";
import stylesAuditsFilter from "../audits/components/AuditsFilter/auditsFilter.module.scss";
import { AuditsTable } from "./components/AuditsTable/AuditsTable";
import Suspense from "../../components/Suspense/Suspense";
import { Button, Search } from "@montel/montelpro-shared-components";
import Icon from "@mdi/react";
import appPages from "../../appPages";
import { mdiPlus } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import { RequestStatus } from "@montel/montelpro-shared-components/enums";
import AuditsFilter from "./components/AuditsFilter/AuditsFilter";
import LiveIndicator from "../../components/LiveIndicator/LiveIndicator";

const Audits: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const status = useAppSelector(getAuditsStatus);
  const error = useAppSelector(getAuditsError);
  const isAdmin = useAppSelector(getIsAdmin);
  const searchTerm = useAppSelector(getSearchTerm);
  const isWebSocketLive = useAppSelector(getIsWebSocketLive);

  useOnMount(() => {
    dispatch(fetchAudits());
  });

  return (
    <div className={styles.auditsContainer}>
      <LiveIndicator
        className={styles.liveIndicator}
        isLive={isWebSocketLive}
      />
      <div className={styles.header}>
        <div>
          <h1>Audits</h1>
          {status === RequestStatus.SUCCESS && isAdmin && (
            <Button
              text={"New Audit"}
              kind={"primary"}
              iconRight={<Icon path={mdiPlus} />}
              onClick={() => navigate(appPages.newAudit)}
            />
          )}
        </div>
        <div>
          <Search
            className={styles.searchField}
            value={searchTerm}
            onChange={({ target }) => dispatch(setSearchTerm(target.value))}
          />
          <AuditsFilter className={stylesAuditsFilter.hideDesktop} />
        </div>
      </div>
      <Suspense status={status} error={error} loaderType={"table"}>
        <AuditsTable />
      </Suspense>
    </div>
  );
};

export default Audits;
