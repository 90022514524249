import { FunctionComponent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getPayloadConfig } from "../../auditConfig/auditConfigurationDataSlice";
import {
  getAuditId,
  getIsNameChanged,
  getIsNameValid,
  getName,
  persistName,
  resetName,
  setName,
} from "../auditSlice";
import { RequestStatus } from "@montel/montelpro-shared-components/enums";
import axios from "axios";
import apiRoutes from "../../../apiRoutes";
import { toast } from "react-toastify";
import Modal from "../../../components/Modal/Modal";
import EditName from "../../newAudit/EditName";

export interface EditNameModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
}

const EditNameModal: FunctionComponent<EditNameModalProps> = ({
  isModalOpen,
  closeModal,
}) => {
  const payloadConfig = useAppSelector(getPayloadConfig);
  const auditId = useAppSelector(getAuditId);
  const name = useAppSelector(getName);
  const isNameChanged = useAppSelector(getIsNameChanged);
  const isNameValid = useAppSelector(getIsNameValid);
  const dispatch = useAppDispatch();
  const [submitStatus, setSubmitStatus] = useState(RequestStatus.UNLOADED);

  const saveName = async () => {
    setSubmitStatus(RequestStatus.LOADING);
    let isSuccess = false;
    try {
      const payload = {
        ...payloadConfig,
        name,
      };
      await axios.patch(apiRoutes.auditConfig(auditId), payload);
      isSuccess = true;
    } catch (error: any) {
      toast.error("Something went wrong. Name was not saved properly.");
    }
    setSubmitStatus(RequestStatus.UNLOADED);
    return isSuccess;
  };

  const onSaveInModal = async () => {
    if (!isNameValid) return;
    if (!isNameChanged) {
      closeModal();
      return;
    }
    const isSaved = await saveName();
    if (isSaved) {
      dispatch(persistName());
      closeModal();
    }
  };
  return (
    <Modal
      title="Edit name"
      isOpen={isModalOpen}
      onCancel={() => {
        dispatch(resetName());
        closeModal();
      }}
      okButtonText="Save"
      onOk={onSaveInModal}
      onOkStatus={submitStatus}
    >
      <EditName
        value={name}
        setValue={(value) => dispatch(setName(value))}
        isValid={isNameValid}
        showValidationErrors={true}
      />
    </Modal>
  );
};

export default EditNameModal;
