const appPages = {
  incident: "/incident",
  incidents: "/incidents",
  audit: "/audit",
  newAudit: "/newAudit",
  auditSqlResult: "/auditSqlResult",
  audits: "/audits",
  services: "/services",
  authComplete: "/auth-complete",
};

export default appPages;
