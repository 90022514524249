import React, { FunctionComponent } from "react";
import { RequestStatus } from "@montel/montelpro-shared-components/enums";
import LoaderTable from "./LoaderTable";
import LoaderCard from "./LoaderCard";
import ApiError from "../ApiError/ApiError";

type LoaderTypes = "table" | "card";

export interface SuspenseProps {
  status: RequestStatus;
  error?: any; // This is an AxiosError<any>, but we dont want to have a dep to axios just for this declaration.
  children: React.ReactElement | React.ReactElement[];
  disabled?: boolean;
  loaderType?: LoaderTypes;
  classNameLoading?: string;
  classNameError?: string;
}

const Suspense: FunctionComponent<SuspenseProps> = ({
  status,
  error,
  children,
  loaderType = "table",
  disabled,
  classNameLoading,
  classNameError,
}) => {
  if (status === RequestStatus.SUCCESS || disabled) {
    return <>{children}</>;
  }
  if (status === RequestStatus.ERROR) {
    return <ApiError error={error} />;
  }
  switch (loaderType) {
    case "table":
      return <LoaderTable />;
    case "card":
      return <LoaderCard />;
    default:
      return <LoaderTable />;
  }
};

export default Suspense;
