import { FunctionComponent } from "react";
import { IIncident } from "../../../incidentsTypes";
import Modal from "../../../../../components/Modal/Modal";
import LabelledContent from "../../../../../components/LabelledContent/LabelledContent";
import { nord } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import json from "react-syntax-highlighter/dist/cjs/languages/hljs/json";
import styles from "./errorDetailsModal.module.scss";
import { IconButton } from "@montel/montelpro-shared-components";
import Icon from "@mdi/react";
import { mdiContentCopy } from "@mdi/js";
import copyToClipboard from "../../../../../utils/copyToClipboard";
import { toast } from "react-toastify";

SyntaxHighlighter.registerLanguage("json", json);

export interface ErrorDetailsModalProps {
  data?: IIncident;
  isModalOpen: boolean;
  closeModal: () => void;
}

const ErrorDetailsModal: FunctionComponent<ErrorDetailsModalProps> = ({
  data,
  isModalOpen,
  closeModal,
}) => {
  if (!data) return <></>;
  const { sourceName, fatalErrorMessage, properties } = data;
  const fatalErrorProperties =
    JSON.stringify(properties, null, 2) || "No properties to show.";

  return (
    <Modal
      isOpen={isModalOpen}
      title={`${sourceName}: details`}
      onOk={closeModal}
      okButtonText={"Close"}
      showCancelButton={false}
      onCancel={closeModal}
      className={styles.modal}
    >
      <div className={styles.errorDetailsContainer}>
        <LabelledContent label={"Message"}>{fatalErrorMessage}</LabelledContent>
        <LabelledContent label={"Properties"}>
          <div className={styles.highlightedSyntax}>
            <SyntaxHighlighter language="json" style={nord}>
              {fatalErrorProperties}
            </SyntaxHighlighter>
            <IconButton
              altText="Copy properties to clipboard"
              icon={<Icon path={mdiContentCopy} />}
              size="small"
              kind="tertiary"
              onClick={() => {
                copyToClipboard(fatalErrorProperties);
                toast.success("Properties copied to clipboard", {
                  autoClose: 2000,
                });
              }}
              className={styles.copyToClipboard}
            />
          </div>
        </LabelledContent>
      </div>
    </Modal>
  );
};

export default ErrorDetailsModal;
