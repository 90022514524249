import React, { FunctionComponent } from "react";
import { IIncident } from "../../incidentsTypes";
import incidentsTableStyles from "./incidentsTable.module.scss";
import IncidentsContextMenu from "./ContextMenu/IncidentsContextMenu";
import { toTitleCase } from "../../../../utils/textUtils";
import { IncidentSourceType, Priority } from "../../../../shared/enums";
import formatTimestamp from "../../../../utils/dateTimeUtils/formatTimestamp";
import AssigneeCell from "./AssigneeCell/AssigneeCell";
import { ISortableTableColumn } from "../../../../components/SortableTable/sortableTableTypes";
import { Link } from "react-router-dom";
import appPages from "../../../../appPages";
import IncidentTypeLabel from "../../../../components/IncidentTypeLabel/IncidentTypeLabel";
import styles from "./incidentsTable.module.scss";

interface IncidentsTableRowProps {
  columns: ISortableTableColumn[];
  data: IIncident;
  onErrorDetailsModalOpen: (arg: any) => void;
}

const IncidentsTableRow: FunctionComponent<IncidentsTableRowProps> = ({
  columns,
  data,
  onErrorDetailsModalOpen,
}) => {
  const {
    id,
    sourceId,
    isClosed,
    postponedTo,
    sourceName,
    assigneeId,
    createdAt,
    priority,
    assigneeName,
    sourceStatus,
    incidentSourceType,
  } = data;

  return (
    <tr key={id}>
      <td className={incidentsTableStyles.contextMenuCellMobile}>
        <IncidentsContextMenu
          sourceId={sourceId}
          incidentId={id}
          disabled={isClosed}
          postponedTo={postponedTo}
          incidentSourceType={incidentSourceType}
        />
      </td>
      <td data-title={columns[0].label}>
        {incidentSourceType === IncidentSourceType.AUDIT ? (
          <Link to={`${appPages.audit}/${sourceId}`}>{sourceName}</Link>
        ) : (
          sourceName
        )}
      </td>
      <td data-title={columns[1].label}>
        <IncidentTypeLabel incidentSourceType={incidentSourceType} />
      </td>
      <td data-title={columns[2].label}>
        {incidentSourceType === IncidentSourceType.FATALERROR ? (
          <a
            className={styles.clickableText}
            onClick={() => onErrorDetailsModalOpen(data)}
          >
            See details
          </a>
        ) : (
          toTitleCase(sourceStatus)
        )}
      </td>
      <td
        data-title={columns[3].label}
        className={
          isCritical(priority) ? incidentsTableStyles.critical : undefined
        }
      >
        {toTitleCase(Priority[priority])}
      </td>
      <td data-title={columns[4].label}>{formatTimestamp(createdAt)}</td>
      <AssigneeCell
        label={columns[5].label}
        incidentId={id}
        userId={assigneeId}
        userName={assigneeName}
        isClosed={isClosed}
      />
      <td className={incidentsTableStyles.contextMenuCell}>
        <IncidentsContextMenu
          sourceId={sourceId}
          incidentId={id}
          disabled={isClosed}
          postponedTo={postponedTo}
          incidentSourceType={incidentSourceType}
        />
      </td>
    </tr>
  );
};

const isCritical = (priority: Priority): boolean =>
  priority === Priority.Critical;

export default IncidentsTableRow;
