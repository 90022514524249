import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../app/store";
import { RequestStatus } from "@montel/montelpro-shared-components/enums";
import { IAuditApi, IAuditItem } from "../audit/auditTypes";
import translateApiAudit from "./translateApiAudit";
import apiRoutes from "../../apiRoutes";
import formatTimestamp from "../../utils/dateTimeUtils/formatTimestamp";
import moment from "moment";
import { runAudit } from "../audit/auditThunks";

export interface IAuditsState {
  data: IAuditItem[];
  searchTerm: string;
  status: RequestStatus;
  error?: any;
  isDataLoaded: boolean;
  filters: { category: string; value: any }[];
}

export const initialState: IAuditsState = {
  data: [] as IAuditItem[],
  searchTerm: "",
  status: RequestStatus.UNLOADED,
  error: undefined,
  isDataLoaded: false,
  filters: [],
};

export const fetchAudits = createAsyncThunk("audits/fetchAudits", async () => {
  const response = await axios.get(`${apiRoutes.audits}`);
  return ((response.data || []) as IAuditApi[]).map(translateApiAudit);
});

export const auditsSlice = createSlice({
  name: "audits",
  initialState,
  reducers: {
    setSearchTerm: (state, { payload }) => {
      state.searchTerm = payload;
    },
    updateAuditsStatus: (state, { payload }) => {
      const { auditId, runStatus, dateTime, error } = payload;
      const audit = state.data.find((audit) => audit.id === auditId);
      if (!audit) return;
      audit.runStatus = runStatus;
      audit.lastRunDateTime = dateTime;
      audit.auditError = error;
      audit.isWaitingForRunResult = false;
    },
    toggleFilter: (state, { payload }) => {
      const { category, value } = payload;
      const isSelected = state.filters.find(
        (filter) => filter.category === category && filter.value === value
      );
      if (isSelected) {
        state.filters = state.filters.filter(
          (filter) => !(filter.category === category && filter.value === value)
        );
      } else {
        state.filters.push({ category, value });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAudits.pending, (state) => {
        state.status = RequestStatus.LOADING;
        state.error = undefined;
      })
      .addCase(fetchAudits.fulfilled, (state, action) => {
        state.status = RequestStatus.SUCCESS;
        state.data = action.payload;
        state.isDataLoaded = true;
      })
      .addCase(fetchAudits.rejected, (state, action) => {
        state.status = RequestStatus.ERROR;
        state.error = action.error;
      })
      .addCase(runAudit.pending, (state, action) => {
        const auditId = action.meta.arg;
        const audit = state.data.find((audit) => audit.id === auditId);
        if (audit) audit.isWaitingForRunResult = true;
      })
      .addCase(runAudit.rejected, (state, action) => {
        const auditId = action.meta.arg;
        const audit = state.data.find((audit) => audit.id === auditId);
        if (audit) audit.isWaitingForRunResult = false;
      });
  },
});

export const { setSearchTerm, updateAuditsStatus, toggleFilter } =
  auditsSlice.actions;
export const getAuditsState = (state: RootState) => state.audits;
export const getAuditsStatus = (state: RootState) =>
  getAuditsState(state).status;
export const getAuditsError = (state: RootState) => getAuditsState(state).error;
export const getAudits = (state: RootState) =>
  getAuditsState(state).data.map((audit) => ({
    ...audit,
    lastRunDateTimeFormatted: audit.lastRunDateTime
      ? formatTimestamp(audit.lastRunDateTime)
      : "Unknown",
  }));
export const getHasAudits = (state: RootState) => getAudits(state).length > 0;
export const getSearchTerm = (state: RootState) =>
  getAuditsState(state).searchTerm;

export const getSearchFilteredAudits = (state: RootState) => {
  const audits = getFilteredAudits(state);
  const searchTerm = getSearchTerm(state);
  if (audits.length === 0 || !searchTerm) return audits;

  const searchableProperties = ["id", "name", "lastRunDateTimeFormatted"];
  const regex = new RegExp(searchTerm, "i");

  return audits.filter((audit: any) =>
    searchableProperties.some((prop) => regex.test(audit[prop]))
  );
};

export const getFilteredAudits = (state: RootState) => {
  const audits = getAudits(state);
  const filters = getAuditsState(state).filters;

  if (filters.length === 0) return audits;

  const categories = Array.from(
    new Set(filters.map((filter) => filter.category))
  );

  return audits.filter((audit: IAuditItem) =>
    categories.every((category) => {
      const values = filters
        .filter((filter) => filter.category === category)
        .map((filter) => filter.value);
      return values.some((value) => {
        if (category === "lastRunDateTimeFormatted")
          return (audit[category as keyof IAuditItem] as string).includes(
            moment().format("DD.MM.YYYY")
          );
        return audit[category as keyof IAuditItem] === value;
      });
    })
  );
};

export const getFilters = (state: RootState) => getAuditsState(state).filters;

export const getIsFilterSelected = (
  state: RootState,
  category: string,
  value: any
): boolean =>
  getFilters(state).some(
    (filter) => filter.category === category && filter.value === value
  );

export const getIsNameAlreadyTaken = (state: RootState, name: string) => {
  const existingNames = getAudits(state).map((audit) => audit.name);
  return existingNames.includes(name);
};

export const getIsDataLoaded = (state: RootState) =>
  getAuditsState(state).isDataLoaded;

export const getIsAuditWaitingForRunResult = (
  state: RootState,
  auditId: number | undefined
) => {
  const audit = getAudits(state).find((audit) => audit.id === auditId);
  return audit?.isWaitingForRunResult;
};

export default auditsSlice.reducer;
