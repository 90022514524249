import { FunctionComponent } from "react";
import { mdiBug, mdiServerNetwork, mdiTextBoxSearchOutline } from "@mdi/js";
import Icon from "@mdi/react";
import styles from "./incidentTypeLabel.module.scss";
import { IncidentSourceType } from "../../shared/enums";

export interface IncidentTypeLabelProps {
  incidentSourceType: IncidentSourceType;
  className?: string;
}

const typeNameMap = {
  audit: "Audit",
  service: "Service",
  fatalError: "Fatal error",
};

const typeIconMap = {
  audit: mdiTextBoxSearchOutline,
  service: mdiServerNetwork,
  fatalError: mdiBug,
};

const IncidentTypeLabel: FunctionComponent<IncidentTypeLabelProps> = ({
  incidentSourceType,
  className,
}) => {
  return (
    <div className={`${styles.incidentTypeLabel} ${className || ""}`}>
      <Icon path={typeIconMap[incidentSourceType]} />
      {typeNameMap[incidentSourceType]}
    </div>
  );
};

export default IncidentTypeLabel;
