import { ServicesStatus, ServiceStatusTextMapping } from "../../servicesTypes";

const menuConfig = [
  {
    label: "Status",
    value: "status",
    items: [
      {
        label: ServiceStatusTextMapping(ServicesStatus.UNKNOWN),
        value: ServicesStatus.UNKNOWN,
      },
      {
        label: ServiceStatusTextMapping(ServicesStatus.STARTING),
        value: ServicesStatus.STARTING,
      },
      {
        label: ServiceStatusTextMapping(ServicesStatus.ACTIVE),
        value: ServicesStatus.ACTIVE,
      },
      {
        label: ServiceStatusTextMapping(ServicesStatus.NOT_RESPONDING),
        value: ServicesStatus.NOT_RESPONDING,
      },
      {
        label: ServiceStatusTextMapping(ServicesStatus.NOT_RUNNING),
        value: ServicesStatus.NOT_RUNNING,
      },
      {
        label: ServiceStatusTextMapping(ServicesStatus.INTERNAL_ERROR),
        value: ServicesStatus.INTERNAL_ERROR,
      },
    ],
  },
];

export default menuConfig;
