import {
  ComparisonOperator,
  ComparisonValueType,
  SchedulingModeValues,
} from "./auditConfigurationEnums";
import { IAuditConfig } from "./auditConfigurationTypes";
import { IAuditApi } from "../audit/auditTypes";
import stringToTimeObject from "../audit/utils/stringToTimeObject";
import { replaceUndefinedToNull } from "../../utils/objectUtils";

const translateToAuditConfigApi = (
  {
    connectionId,
    statement,
    conditionOperator,
    operandType,
    expectedResult,
    runOnceStart,
    runStart,
    runStop,
    runStep,
    daysOfWeek,
  }: IAuditConfig,
  selectedSchedulingMode: SchedulingModeValues
): Partial<IAuditApi> => {
  const isRunOnceMode = selectedSchedulingMode === SchedulingModeValues.ONCE;
  return replaceUndefinedToNull({
    connectionId: connectionId,
    statement,
    schedule: {
      daysOfWeek,
      runOnceStart: stringToTimeObject(
        isRunOnceMode ? runOnceStart : undefined
      ),
      runStart: stringToTimeObject(isRunOnceMode ? undefined : runStart),
      runStop: stringToTimeObject(isRunOnceMode ? undefined : runStop),
      runStep: isRunOnceMode ? undefined : runStep,
    },
    auditCondition: {
      conditionOperator: conditionOperator as ComparisonOperator,
      operandType: operandType as ComparisonValueType,
      expectedResult,
    },
  });
};

export default translateToAuditConfigApi;
