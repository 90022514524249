import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import styles from "./daysOfWeek.module.scss";
import { MultiChoice } from "../../../components/MultiChoice";
import { dayOfWeekOptions } from "../auditConfigurationOptions";
import {
  getAuditConfig,
  getIsDaysOfWeekValid,
  getShowValidationErrors,
  setDaysOfWeek,
} from "../auditConfigurationDataSlice";

export const DaysOfWeek: FunctionComponent<{ isEditMode?: boolean }> = ({
  isEditMode,
}) => {
  const dispatch = useAppDispatch();
  const { daysOfWeek } = useAppSelector(getAuditConfig);
  const showValidationErrors = useAppSelector(getShowValidationErrors);
  const isDaysOfWeekValid = useAppSelector(getIsDaysOfWeekValid);

  return (
    <MultiChoice
      label="Days of week"
      options={dayOfWeekOptions}
      value={daysOfWeek}
      disabled={!isEditMode}
      className={styles.daysOfWeek}
      onChange={(value) => dispatch(setDaysOfWeek(value))}
      errorMessage={
        showValidationErrors && !isDaysOfWeekValid
          ? "Select at least one day of week"
          : undefined
      }
    />
  );
};
