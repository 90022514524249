import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiRoutes from "../../apiRoutes";
import UpdateToast from "../../utils/UpdateToast";
import { IServiceStatusMessageApi } from "./servicesTypes";
import translateApiServices from "./translateApiServices";

export const fetchServices = createAsyncThunk(
  "services/claimServicesStatus",
  async () => {
    const response = await axios.get(`${apiRoutes.services}`);
    return ((response.data || []) as IServiceStatusMessageApi[]).map(
      translateApiServices
    );
  }
);

export const startService = createAsyncThunk(
  "services/startService",
  async ({ serviceName }: { serviceName: string }) => {
    const toast = new UpdateToast().loading();
    try {
      await axios.post(`${apiRoutes.servicesStartService}`, {
        serviceName,
      });
      toast.success("Request to start service was sent.");
    } catch (e) {
      toast.error("Request to start service failed.");
    }
  }
);

export const stopService = createAsyncThunk(
  "services/stopService",
  async ({ serviceName }: { serviceName: string }) => {
    const toast = new UpdateToast().loading();
    try {
      await axios.post(`${apiRoutes.servicesStopService}`, {
        serviceName,
      });
      toast.success("Request to stop service was sent.");
    } catch (e) {
      toast.error("Request to stop service failed.");
    }
  }
);

export const forgetService = createAsyncThunk(
  "services/forgetService",
  async ({ serviceName }: { serviceName: string }) => {
    const toast = new UpdateToast().loading();
    try {
      await axios.post(`${apiRoutes.servicesForgetService}`, {
        serviceName,
      });
      toast.success("Service has been forgotten.");
      return true;
    } catch (e) {
      toast.error("Request to forget service failed.");
      return false;
    }
  }
);
