import { Fragment, FunctionComponent } from "react";
import styles from "./stepIndicator.module.scss";

interface StepIndicatorProps {
  onClick: (index: number) => void;
  stepLabels: string[];
  currentStep: number;
  progress: number;
  className?: string;
}

const StepIndicator: FunctionComponent<StepIndicatorProps> = ({
  onClick,
  stepLabels,
  currentStep,
  progress,
  className,
}) => {
  const getIsStepActive = (stepIndex: number): boolean => {
    if (stepIndex >= stepLabels.length) return false;
    return stepIndex <= progress;
  };

  return (
    <div className={`${styles.step_indicator} ${className}`}>
      {stepLabels.map((label, idx) => {
        const isActive = getIsStepActive(idx);
        const isNextStepActive = getIsStepActive(idx + 1);
        const classNames = `${styles.step} ${
          currentStep === idx && styles.selected
        } ${isActive && styles.active}`;

        return (
          <Fragment key={idx}>
            <button
              className={classNames}
              onClick={() => onClick(idx)}
              disabled={!isActive}
            >
              <span className={styles.step_circle}>
                <span className={styles.step_innerCircle}></span>
              </span>
              <span className={styles.step_label}>{label}</span>
            </button>
            {idx !== stepLabels.length - 1 && (
              <div
                className={`${styles.indicator_line} ${
                  isNextStepActive && styles.indicator_line_active
                }`}
              ></div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default StepIndicator;
