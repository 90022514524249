import { Moment } from "moment";
import { getMomentFromPreset } from "./presetTimeStamps";
import toServerTimestamp from "../../../../../../utils/dateTimeUtils/toServerTimestamp";

const getPostponeDate = (
  postponeDatePreset: number | undefined,
  postponeDate: Moment | undefined
): string => {
  let result = "";
  if (postponeDatePreset !== undefined) {
    result = toServerTimestamp(getMomentFromPreset(postponeDatePreset));
  }
  if (postponeDate) {
    result = toServerTimestamp(postponeDate);
  }
  return result;
};

export default getPostponeDate;
