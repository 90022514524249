import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import ContextMenu from "../../../../components/ContextMenu/ContextMenu";
import styles from "./servicesContextMenu.module.scss";
import ForgetServiceButton from "./ForgetServiceButton";
import RelatedSystemLinkButton from "./RelatedSystemLinkButton";
import AddEditServiceButton from "./AddEditServiceButton";
import StartStopServiceButton from "./StartStopServiceButton";
import RelatedServicesModal from "../RelatedServicesModal/RelatedServicesModal";
import {
  setRelatedServiceNameModal,
  getRelatedServiceNameModal,
} from "../../servicesSlice";

export interface ServicesContextMenuProps {
  serviceName: string;
  relatedSystemId?: number | string;
  isServiceEnabled: boolean;
  disabled: boolean;
  className?: string;
}

const ServicesContextMenu: FunctionComponent<ServicesContextMenuProps> = ({
  serviceName,
  isServiceEnabled,
  relatedSystemId,
  disabled = false,
  className,
}) => {
  const dispatch = useAppDispatch();
  const relatedServiceNameModal = useAppSelector(getRelatedServiceNameModal);
  const showModal =
    relatedServiceNameModal !== undefined &&
    relatedServiceNameModal === serviceName;
  const menuItems = [
    <StartStopServiceButton
      serviceName={serviceName}
      isServiceEnabled={isServiceEnabled}
      key={1}
    />,
    ...(relatedSystemId
      ? [<RelatedSystemLinkButton relatedSystemId={relatedSystemId} key={2} />]
      : []),
    <AddEditServiceButton
      serviceName={serviceName}
      relatedSystemId={relatedSystemId}
      key={3}
    />,
    <ForgetServiceButton serviceName={serviceName} key={4} />,
  ];
  return (
    <>
      <ContextMenu
        children={menuItems}
        disabled={disabled}
        className={`${styles.servicesMenuDropdown} ${
          disabled && styles.disabledMenu
        } ${className && className}`}
      />
      <RelatedServicesModal
        isModalOpen={showModal}
        closeModal={() => dispatch(setRelatedServiceNameModal(undefined))}
        serviceName={serviceName}
        relatedSystemId={relatedSystemId}
      />
    </>
  );
};

export default ServicesContextMenu;
