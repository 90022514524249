import { ITimeApi } from "../auditTypes";

const timeObjectToString = (time: ITimeApi | null): string | undefined => {
  if (time === null) return undefined;
  return `${String(time.hour).padStart(2, "0")}:${String(time.minute).padStart(
    2,
    "0"
  )}`;
};

export default timeObjectToString;
