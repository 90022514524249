import { useEffect, useState } from "react";

const useHighlightOnChange = (
  value: any,
  timeOut: number = 2000
): [boolean] => {
  const [prevValue, setPrevValue] = useState(value);
  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    if (prevValue !== value) {
      setHighlight(true);
      setTimeout(() => setHighlight(false), timeOut);
      setPrevValue(value);
    }
  }, [value, prevValue, timeOut]);

  return [highlight];
};

export default useHighlightOnChange;
