import { useAppSelector } from "../../app/hooks";
import { getSystemById } from "../lists/listsSlice";
import { FunctionComponent } from "react";

interface RelatedSystemLinkProps {
  id: string | number | undefined;
}

const RelatedSystemLink: FunctionComponent<RelatedSystemLinkProps> = ({
  id,
}) => {
  const system = useAppSelector(getSystemById(id));
  const { name, url } = system || {};

  if (name && url) {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        {name}
      </a>
    );
  }
  return <>{id}</>;
};

export default RelatedSystemLink;
