export interface IOption {
  label: any;
  value: any;
  [key: string]: any;
}

const getOptionByKey = (
  options: IOption[],
  value: number | string | undefined | null,
  key = "value"
) => {
  if (value === undefined || value === null || options.length === 0) {
    return undefined;
  }
  return options.find((option) => option[key] === value);
};

export default getOptionByKey;
