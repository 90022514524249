export enum ServicesStatus {
  UNKNOWN,
  STARTING,
  ACTIVE,
  NOT_RESPONDING,
  NOT_RUNNING,
  INTERNAL_ERROR,
  SILENCED,
}

export const ServiceStatusTextMapping = (status: ServicesStatus): string => {
  switch (status) {
    case ServicesStatus.STARTING:
      return "Starting";
    case ServicesStatus.ACTIVE:
      return "Active";
    case ServicesStatus.NOT_RESPONDING:
      return "Not responding";
    case ServicesStatus.NOT_RUNNING:
      return "Not running";
    case ServicesStatus.INTERNAL_ERROR:
      return "Internal Error";
    case ServicesStatus.SILENCED:
      return "Silenced";
    default:
      return "Unknown";
  }
};

export interface IServiceStatusMessageApi {
  name: string;
  lastUpdated: string;
  status: number;
  relatedSystemId?: number | string;
  host: string;
}

export interface IServiceStatusMessageSignal {
  serviceName: string;
  host: string;
  timeStamp: string;
  status: number;
}

export interface IServiceStatusMessage {
  name: string;
  lastUpdated: string;
  lastUpdatedFormatted?: string;
  status: number;
  statusText: string;
  relatedSystemId?: number | string;
  host: string;
}
