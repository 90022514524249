import { FunctionComponent } from "react";
import styles from "./radioButtons.module.scss";

export type RadioOptionType = {
  value: any;
  label: string;
  secondaryLabel?: string;
  disabled?: boolean;
};

export interface RadioButtonsProps {
  label?: string;
  onChange: (value: any) => void;
  value?: any;
  options: RadioOptionType[];
  className?: string;
  hideLabel?: boolean;
  disabled?: boolean;

  [otherProps: string]: any;
}

export const RadioButtons: FunctionComponent<RadioButtonsProps> = ({
  label,
  onChange,
  value,
  options,
  className = "",
  hideLabel = false,
  disabled = false,
  ...otherProps
}) => {
  return (
    <div className={`${styles.radioButtons} ${className}`}>
      <h3>{label}</h3>
      <fieldset disabled={disabled}>
        {options.map(
          ({ label, secondaryLabel, value: optionValue, disabled }, idx) => {
            return (
              <div key={idx} className={styles.radioButton}>
                <label>
                  <input
                    type="radio"
                    value={optionValue}
                    checked={value === optionValue}
                    disabled={disabled}
                    onChange={() => onChange(optionValue)}
                    {...otherProps}
                  />
                  <span className={styles.checkMark}></span>
                  <div className={styles.labels}>
                    <span>{label}</span>
                    <span>{secondaryLabel}</span>
                  </div>
                </label>
              </div>
            );
          }
        )}
      </fieldset>
    </div>
  );
};
