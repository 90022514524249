import React, { FunctionComponent } from "react";
import {
  ISortableTableColumn,
  SortOrder,
} from "../../../../components/SortableTable/sortableTableTypes";
import { comparatorDate } from "../../../../utils/objectUtils";
import { useAppSelector } from "../../../../app/hooks";
import { getHasServices, getSearchFilteredServices } from "../../servicesSlice";
import styles from "../../../../components/SortableTable/sortableTable.module.scss";
import stylesServices from "./servicesTable.module.scss";
import { useSortableTable } from "../../../../components/SortableTable/useSortableTable";
import SortableTableHeadMobile from "../../../../components/SortableTable/SortableTableHeadMobile";
import SortableTableHead, {
  SortableTableHeadProps,
} from "../../../../components/SortableTable/SortableTableHead";
import ServicesFilter from "../ServicesFilter/ServicesFilter";
import ServicesFilterChips from "../ServicesFilter/ServicesFilterChips";
import { IServiceStatusMessage } from "../../servicesTypes";
import ServicesTableRow from "./ServicesTableRow";

const columns: ISortableTableColumn[] = [
  {
    label: "Name",
    key: "name",
    sortable: true,
  },
  {
    label: "Host",
    key: "host",
    sortable: true,
  },
  {
    label: "Last Update",
    key: "lastUpdated",
    sortable: true,
    comparator: comparatorDate,
  },
  {
    label: "Status",
    key: "statusText",
    sortable: true,
    order: SortOrder.ASC,
  },
  {
    label: "",
    key: "",
    sortable: false,
  },
];

const ServicesTable: FunctionComponent = () => {
  const hasServices = useAppSelector(getHasServices);
  const feeds = useAppSelector(getSearchFilteredServices);
  const [tableData, tableHeaderProps] = useSortableTable(feeds, columns);

  const NoServices = (
    <div className={stylesServices.noData}>
      <h2>There are no feeds yet</h2>
    </div>
  );

  return (
    <section
      className={`${styles.sortableTable} ${stylesServices.servicesTable}`}
    >
      <div className={stylesServices.mobileSortFilter}>
        <SortableTableHeadMobile
          {...({
            columns,
            ...tableHeaderProps,
          } as SortableTableHeadProps)}
        />
        <ServicesFilter />
      </div>
      <ServicesFilterChips />
      {!hasServices ? (
        NoServices
      ) : (
        <table>
          <SortableTableHead
            {...({
              columns,
              ...tableHeaderProps,
            } as SortableTableHeadProps)}
          />
          <tbody>
            {(tableData as IServiceStatusMessage[]).map((data) => (
              <ServicesTableRow key={data.name} columns={columns} data={data} />
            ))}
          </tbody>
        </table>
      )}
    </section>
  );
};

export default ServicesTable;
