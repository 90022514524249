import { FunctionComponent } from "react";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import { MenuConfig } from "./Types";

export interface IMenuLevelOneProps {
  onClick: (category: string) => void;
  menuConfig: MenuConfig;
}

const MenuLevelOne: FunctionComponent<IMenuLevelOneProps> = ({
  onClick,
  menuConfig,
}) => {
  return (
    <ul>
      {menuConfig.map(({ value, label }) => {
        return (
          <li key={value}>
            <button onClick={() => onClick(value)}>
              <span>
                {label}
                <Icon path={mdiChevronRight} />
              </span>
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default MenuLevelOne;
