import { IAuditItem, IAuditApi } from "../audit/auditTypes";

export const translateAudit = ({
  id,
  name,
  isEnabled,
  runStatus,
  errorMessage,
  priority,
  lastRunDateTime,
  description,
  solutionProposal,
  connectionId,
  statement,
  relatedSystems,
  schedule,
  auditCondition,
  subscribers,
}: IAuditApi): IAuditItem => ({
  id,
  name,
  isEnabled,
  runStatus,
  auditError: errorMessage,
  priority,
  lastRunDateTime,
  description,
  solutionProposal,
  connectionId,
  statement,
  relatedSystems,
  schedule,
  auditCondition,
  subscribers,
});

export default translateAudit;
