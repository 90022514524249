import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchLists } from "../features/lists/listsSlice";
import { getIsAuthCompleted } from "../features/session/sessionSlice";

const useLoadCommonData = () => {
  const dispatch = useAppDispatch();
  const isAuthCompleted = useAppSelector(getIsAuthCompleted);
  useEffect(() => {
    isAuthCompleted && dispatch(fetchLists());
  }, [isAuthCompleted, dispatch]);
};

export default useLoadCommonData;
