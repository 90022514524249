import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useOnMount from "../../hooks/useOnMount";
import Card from "../../components/Card/Card";
import styles from "./newAudit.module.scss";
import appPages from "../../appPages";
import Configuration from "../auditConfig/EditConfiguration";
import {
  createNewAudit,
  decrementStep,
  getCurrentStep,
  getIsCurrentStepValid,
  getIsDataDirty,
  getProgress,
  getSubmitStatus,
  incrementStep,
  resetState,
  setCurrentStep,
  setShowErrors,
} from "./newAuditSlice";
import { BottomNavigation, StepIndicator } from "../../components/StepSequence";
import InformationStep from "./InformationStep";
import Review from "./Review";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import useModal from "../../hooks/useModal";

const NewAudit: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentStep = useAppSelector(getCurrentStep);
  const progress = useAppSelector(getProgress);
  const submitStatus = useAppSelector(getSubmitStatus);
  const isCurrentStepValid = useAppSelector(getIsCurrentStepValid);
  const isDataDirty = useAppSelector(getIsDataDirty);
  const { isModalOpen, openModal, closeModal } = useModal();

  useOnMount(() => {
    dispatch(resetState());
  });

  const stepLabels = ["Info", "Configuration", "Review"];
  const steps = [<InformationStep />, <Configuration />, <Review />];

  const onStepClick = (nextStep: number) => {
    if (nextStep > currentStep && !isCurrentStepValid) {
      return;
    }
    dispatch(setCurrentStep(nextStep));
  };
  const onNextClick = () => {
    dispatch(setShowErrors());
    if (isCurrentStepValid) {
      dispatch(incrementStep());
    }
  };
  const redirectToOverview = () => navigate(appPages.audits);

  return (
    <div className={styles.newAuditContainer}>
      <Card
        title={"New audit"}
        onClose={isDataDirty ? openModal : redirectToOverview}
      >
        <StepIndicator
          onClick={onStepClick}
          stepLabels={stepLabels}
          currentStep={currentStep}
          progress={progress}
        />
        <div className={styles.newAuditBody}>
          <div className={styles.stepContent}>{steps[currentStep]}</div>
          <BottomNavigation
            totalSteps={steps.length}
            onNext={onNextClick}
            onBack={() => dispatch(decrementStep())}
            currentStep={currentStep}
            onSubmit={() => dispatch(createNewAudit())}
            submitStatus={submitStatus}
            onSubmitSuccess={redirectToOverview}
          />
        </div>
      </Card>
      <ConfirmationModal
        isOpen={isModalOpen}
        onCancel={closeModal}
        onOk={redirectToOverview}
      />
    </div>
  );
};

export default NewAudit;
