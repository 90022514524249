import { FunctionComponent } from "react";
import { useAppSelector } from "../../../app/hooks";
import { getIsAdmin } from "../../session/sessionSlice";
import ContextMenu from "../../../components/ContextMenu/ContextMenu";
import DeleteButton from "./DeleteButton";
import FollowButton from "./FollowButton";
import DisableButton from "./DisableButton";
import EditNameButton from "./EditNameButton";
import useModal from "../../../hooks/useModal";
import EditNameModal from "./EditNameModal";
import DeleteAuditModal from "./DeleteAuditModal";

const AuditContextMenu: FunctionComponent = () => {
  const isAdmin = useAppSelector(getIsAdmin);
  const {
    isModalOpen: isEditNameModalOpen,
    openModal: openEditNameModal,
    closeModal: closeEditNameModal,
  } = useModal();
  const {
    isModalOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const menuItems = isAdmin
    ? [
        <FollowButton key={1} />,
        <DisableButton key={2} />,
        <EditNameButton key={3} openModal={openEditNameModal} />,
        <DeleteButton key={4} openModal={openDeleteModal} />,
      ]
    : [<FollowButton key={1} />];

  return (
    <>
      <ContextMenu children={menuItems} />
      {isEditNameModalOpen && (
        <EditNameModal
          isModalOpen={isEditNameModalOpen}
          closeModal={closeEditNameModal}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteAuditModal
          isModalOpen={isDeleteModalOpen}
          closeModal={closeDeleteModal}
        />
      )}
    </>
  );
};

export default AuditContextMenu;
