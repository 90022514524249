import React, { FunctionComponent } from "react";
import { useAppDispatch } from "../../../../../app/hooks";
import moment from "moment";
import { updateIncidentPostponedTo } from "../../../incidentsThunks";
import toServerTimestamp from "../../../../../utils/dateTimeUtils/toServerTimestamp";
import { setPostponeModalId } from "../../../incidentsSlice";
import MenuItem from "../../../../../components/ContextMenu/MenuItem";
import { mdiClockTimeFour, mdiClockRemove } from "@mdi/js";

export interface PostponeButtonProps {
  incidentId: number;
  postponedTo?: string;
}

export const PostponeButton: FunctionComponent<PostponeButtonProps> = ({
  incidentId,
  postponedTo,
}) => {
  const dispatch = useAppDispatch();
  const isPostponed = moment(postponedTo).isAfter(moment.now());

  const postponeButton = (
    <MenuItem
      iconPath={mdiClockTimeFour}
      text={"Postpone"}
      onClick={() => {
        dispatch(setPostponeModalId(incidentId));
      }}
    />
  );

  const undoPostponeButton = (
    <MenuItem
      iconPath={mdiClockRemove}
      text={"Move back to active"}
      onClick={() =>
        dispatch(
          updateIncidentPostponedTo({
            incidentId,
            postponedTo: toServerTimestamp(moment().subtract({ minutes: 5 })),
          })
        )
      }
    />
  );

  return <>{isPostponed ? undoPostponeButton : postponeButton}</>;
};
