import { useEffect } from "react";
import applyAxiosInterceptor from "./applyAxiosInterceptor";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useAppDispatch } from "../../app/hooks";
import { completeAuth } from "../session/sessionSlice";

const useAxiosInteceptor = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress: status } = useMsal();
  const isMsalAuthCompleted =
    isAuthenticated && status === InteractionStatus.None;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isMsalAuthCompleted) {
      applyAxiosInterceptor(instance, accounts, dispatch);
      dispatch(completeAuth());
    }
  }, [isMsalAuthCompleted, instance, accounts, dispatch]);
};

export default useAxiosInteceptor;
