import { FullPageLoader } from "@montel/montelpro-shared-components";
import { FunctionComponent, ReactElement } from "react";
import { useAppSelector } from "../../app/hooks";
import { getIsAuthCompleted } from "../session/sessionSlice";
import AuthError from "./AuthError";
import useInitAuth from "./useInitAuth";

const RequireAuth: FunctionComponent<{
  children: ReactElement;
}> = ({ children }) => {
  const isAuthCompleted = useAppSelector(getIsAuthCompleted);
  const { authError } = useInitAuth();

  if (isAuthCompleted) {
    return children;
  }
  if (authError) {
    return <AuthError />;
  }
  return <FullPageLoader />;
};

export default RequireAuth;
