import { FunctionComponent } from "react";
import MenuItem from "../../../../../components/ContextMenu/MenuItem";
import { mdiCheckBold } from "@mdi/js";
import { useAppDispatch } from "../../../../../app/hooks";
import { updateIncidentComplete } from "../../../incidentsThunks";

export interface CompleteIncidentButtonProps {
  incidentId: number;
}

const CompleteIncidentButton: FunctionComponent<
  CompleteIncidentButtonProps
> = ({ incidentId }) => {
  const dispatch = useAppDispatch();

  return (
    <MenuItem
      iconPath={mdiCheckBold}
      text={"Complete"}
      onClick={() => dispatch(updateIncidentComplete(incidentId))}
    />
  );
};

export default CompleteIncidentButton;
