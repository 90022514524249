import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Button,
  DividerLine,
  IconButton,
  SubmitButton,
} from "@montel/montelpro-shared-components";
import { RequestStatus } from "@montel/montelpro-shared-components/enums";
import { FunctionComponent, ReactElement } from "react";
import ReactModal from "react-modal";
import styles from "./modal.module.scss";

ReactModal.setAppElement("#root");

const Modal: FunctionComponent<{
  isOpen: boolean;
  title: string;
  onOk: () => void;
  onCancel: () => void;
  children: ReactElement;
  showCancelButton?: boolean;
  okButtonText?: string;
  onOkStatus?: RequestStatus;
  className?: string;
}> = ({
  isOpen,
  title,
  onCancel,
  onOk,
  children,
  showCancelButton = true,
  okButtonText = "OK",
  onOkStatus = RequestStatus.UNLOADED,
  className,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onCancel}
      className={`${styles.modal} ${className}`}
      overlayClassName={styles.overlay}
    >
      <div className={styles.header}>
        <h2>{title}</h2>
        <IconButton
          onClick={onCancel}
          icon={<Icon path={mdiClose} />}
          altText="Close window"
          kind="tertiary"
        />
      </div>
      <DividerLine />
      <div className={styles.content}>{children}</div>
      <DividerLine />
      <div className={styles.footer}>
        {showCancelButton && (
          <Button onClick={onCancel} kind="tertiary" text="Cancel" />
        )}
        <SubmitButton
          onClick={onOk}
          kind="primary"
          text={okButtonText}
          status={onOkStatus}
          showSuccess={false}
        />
      </div>
    </ReactModal>
  );
};

export default Modal;
