import { createContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import getValidAccessToken from "../features/auth/getValidAccessToken";
import { updateAuditsStatus } from "../features/audits/auditsSlice";
import { updateAuditLastRunInfo } from "../features/audit/auditSlice";
import apiRoutes from "../apiRoutes";
import { IAuditJobUpdateMessageAPI } from "./types";
import translateAuditJobMessage from "./translateAuditJobMessage";
import { toast } from "react-toastify";
import {
  getIsAuthCompleted,
  setIsWebSocketLive,
} from "../features/session/sessionSlice";
import translateApiIncident from "../features/incidents/translateApiIncident";
import { getPersonIdNameMapping } from "../features/lists/listsSlice";
import {
  updateIncident,
  updateSourceStatus,
} from "../features/incidents/incidentsSlice";
import { IServiceStatusMessageSignal } from "../features/services/servicesTypes";
import { updateData } from "../features/services/servicesSlice";
import translateSignalServices from "../features/services/translateSignalServices";

export const WebSocketContext = createContext<HubConnection | null>(null);

const WebSocketProvider = ({ children }: any) => {
  const { instance: msalInstance, accounts } = useMsal();
  const dispatch = useAppDispatch();
  const isAuthCompleted = useAppSelector(getIsAuthCompleted);
  const personIdNameMapping = useAppSelector(getPersonIdNameMapping);

  const [auditJobHub, setAuditJobHub] = useState<HubConnection | null>(null);

  useEffect(() => {
    if (
      !auditJobHub &&
      isAuthCompleted &&
      Object.keys(personIdNameMapping).length > 0
    ) {
      setAuditJobHub(
        initiateHub(dispatch, msalInstance, accounts, personIdNameMapping)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthCompleted, personIdNameMapping]);

  return (
    <WebSocketContext.Provider value={auditJobHub}>
      {children}
    </WebSocketContext.Provider>
  );
};

const initiateHub = (
  dispatch: any,
  msalInstance: any,
  accounts: any,
  personIdNameMapping: any
) => {
  const liveUpdateHub = new HubConnectionBuilder()
    .withUrl(`${apiRoutes.liveUpdates}`, {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
      accessTokenFactory: async () => {
        const accessToken = await getValidAccessToken(msalInstance, accounts);
        return accessToken || "";
      },
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Error)
    .build();

  liveUpdateHub.on("TransferIncidentData", (data: any) => {
    let incident = translateApiIncident(data);
    incident = {
      ...incident,
      assigneeName: incident.assigneeId
        ? personIdNameMapping[incident.assigneeId]
        : "Unassigned",
    };
    dispatch(updateIncident(incident));
  });

  liveUpdateHub.on("TransferJobData", (data: IAuditJobUpdateMessageAPI) => {
    const message = translateAuditJobMessage(data);
    dispatch(updateAuditsStatus(message));
    dispatch(updateAuditLastRunInfo(message));
    dispatch(updateSourceStatus(message));
  });

  liveUpdateHub.on(
    "TransferServiceData",
    (data: IServiceStatusMessageSignal) => {
      const feedStatusMessages = translateSignalServices(data);
      dispatch(updateData(feedStatusMessages));
    }
  );

  liveUpdateHub.onreconnecting(() => dispatch(setIsWebSocketLive(false)));
  liveUpdateHub.onreconnected(() => dispatch(setIsWebSocketLive(true)));
  liveUpdateHub.onclose(() => {
    dispatch(setIsWebSocketLive(false));
    toast.error(
      "Could not connect to live updates stream. Try refreshing this page",
      { autoClose: false }
    );
  });
  liveUpdateHub
    .start()
    .then(() => dispatch(setIsWebSocketLive(true)))
    .catch(() => dispatch(setIsWebSocketLive(false)));

  return liveUpdateHub;
};

export default WebSocketProvider;
