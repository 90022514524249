import { FunctionComponent } from "react";

import FilterMenu from "../../../../components/FilterMenu/FilterMenu";
import { getIsFilterSelected, toggleFilter } from "../../auditsSlice";
import menuConfig from "./menuConfig";

const AuditsFilter: FunctionComponent<{ className?: string }> = ({
  className,
}) => {
  return (
    <FilterMenu
      menuConfig={menuConfig}
      isFilterSelectedSelector={getIsFilterSelected}
      toggleFilterAction={toggleFilter}
      className={className}
    />
  );
};

export default AuditsFilter;
