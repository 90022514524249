import { SortOrder } from "../../components/SortableTable/sortableTableTypes";

const comparatorAnyWithDate =
  (secondaryKey: string) =>
  (key: string, order: SortOrder) =>
  (a: any, b: any) => {
    const factor = order === SortOrder.ASC ? 1 : -1;
    if (a[key] > b[key]) return 1 * factor;
    if (a[key] < b[key]) return -1 * factor;
    if (new Date(a[secondaryKey]) < new Date(b[secondaryKey])) return 1;
    if (new Date(a[secondaryKey]) > new Date(b[secondaryKey])) return -1;
    return 0;
  };

export default comparatorAnyWithDate;
