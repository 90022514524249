import { FunctionComponent } from "react";
import { editNewNote } from "./notesSlice";
import styles from "./notes.module.scss";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import NoteEdit from "./NoteEdit";
import { getIsNewNoteInEditMode } from "./notesSelectors";

const AddNewNote: FunctionComponent = () => {
  const isEditMode = useAppSelector(getIsNewNoteInEditMode);
  const dispatch = useAppDispatch();
  const cardClassNames = `${styles.card} ${styles.addNote}`;

  if (isEditMode) {
    return (
      <div className={cardClassNames}>
        <NoteEdit isNew />
      </div>
    );
  }

  return (
    <div className={cardClassNames} onClick={() => dispatch(editNewNote())}>
      <p className={styles.text}>Add your note ...</p>
    </div>
  );
};

export default AddNewNote;
