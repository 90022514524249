import { IAuditApi, IAuditGeneralData } from "../auditTypes";

export const translateAuditGeneralData = ({
  id,
  name,
  isEnabled,
  runStatus,
  errorMessage,
  lastRunDateTime,
  subscribers,
}: IAuditApi): IAuditGeneralData => ({
  id,
  masterName: name,
  name,
  isEnabled,
  lastRunStatus: runStatus,
  auditError: errorMessage,
  lastRunDateTime,
  subscribers,
});

export default translateAuditGeneralData;
