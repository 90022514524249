import { FunctionComponent } from "react";
import ContentLoader from "react-content-loader";
import useWindowDimensions from "./useWindowDimensions";
import styles from "./suspense.module.scss";

const LoaderTable: FunctionComponent = () => {
  const { width, height } = useWindowDimensions();
  const isMobile = width < 992;
  const baseHeight = height - 200;
  const lineHeight = isMobile ? 200 : 36;
  const gap = 10;
  const numberOfLines = isMobile ? 3 : 5;
  const TableRows = (
    <ContentLoader
      speed={2}
      width="100%"
      height={baseHeight}
      backgroundColor="#425a66"
      foregroundColor="#516e7d"
    >
      {Array.from(Array(numberOfLines).keys()).map((idx) => {
        return (
          <rect
            key={idx}
            height={lineHeight}
            width={1000}
            y={idx * gap + lineHeight * idx}
            x={0}
            rx="5"
            ry="5"
          />
        );
      })}
    </ContentLoader>
  );
  return <div className={styles.skeletonLoaderContainer}>{TableRows}</div>;
};

export default LoaderTable;
