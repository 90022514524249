import {
  IServiceStatusMessage,
  ServiceStatusTextMapping,
  IServiceStatusMessageSignal,
} from "./servicesTypes";
import formatTimestamp from "../../utils/dateTimeUtils/formatTimestamp";

const translateSignalServices = ({
  serviceName,
  timeStamp,
  status,
  host,
}: IServiceStatusMessageSignal): IServiceStatusMessage => ({
  name: serviceName,
  lastUpdated: timeStamp,
  status,
  host,
  statusText: ServiceStatusTextMapping(status),
  lastUpdatedFormatted: timeStamp ? formatTimestamp(timeStamp) : "Unknown",
});

export default translateSignalServices;
