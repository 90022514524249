import { FunctionComponent } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { Select, Input, Fieldset } from "@montel/montelpro-shared-components";
import { getOptionByValue } from "../../../utils/objectUtils";
import { ComparisonValueType } from "../auditConfigurationEnums";
import styles from "./expectedResult.module.scss";
import {
  getAuditConfig,
  getIsConditionOperatorValid,
  getIsExpectedResultValid,
  getIsOperandTypeValid,
  getIsOperatorAndValueTypeComboValid,
  getShowValidationErrors,
  setExpectedResultOperator,
  setExpectedResultValue,
  setExpectedResultValueType,
} from "../auditConfigurationDataSlice";
import {
  comparisonOperatorOptions,
  comparisonValueTypeOptions,
} from "../auditConfigurationOptions";

const ExpectedResult: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const {
    conditionOperator: operator,
    operandType: valueType,
    expectedResult: value,
  } = useAppSelector(getAuditConfig);

  const showValidationErrors = useAppSelector(getShowValidationErrors);
  const isOperatorValid = useAppSelector(getIsConditionOperatorValid);
  const isValueTypeValid = useAppSelector(getIsOperandTypeValid);
  const isValueValid = useAppSelector(getIsExpectedResultValid);
  const isOperatorAndValueTypeComboValid = useAppSelector(
    getIsOperatorAndValueTypeComboValid
  );

  return (
    <div>
      <Fieldset legend="Expected result" className={styles.expectedResult}>
        <div className={styles.inputsContainer}>
          <div className={styles.resultText}>Result</div>
          <Select
            className={styles.operator}
            label={"Operator"}
            hideLabel
            value={getOptionByValue(comparisonOperatorOptions, operator)}
            options={comparisonOperatorOptions}
            onChange={({ value }) => dispatch(setExpectedResultOperator(value))}
            errorMessage={
              showValidationErrors && !isOperatorValid ? "Required" : undefined
            }
          />
          <Select
            className={styles.valueType}
            label={"Value type"}
            hideLabel
            value={getOptionByValue(comparisonValueTypeOptions, valueType)}
            options={comparisonValueTypeOptions}
            onChange={({ value }) =>
              dispatch(setExpectedResultValueType(value))
            }
            errorMessage={
              showValidationErrors && !isValueTypeValid ? "Required" : undefined
            }
          />
          {valueType === ComparisonValueType.NULL ? (
            <></>
          ) : (
            <Input
              className={styles.value}
              label={"Value"}
              type={valueType === ComparisonValueType.INT ? "number" : "text"}
              hideLabel
              value={value === undefined ? "" : value}
              onChange={(event: any) => {
                dispatch(setExpectedResultValue(event.target.value));
              }}
              errorMessage={
                showValidationErrors && !isValueValid ? "Required" : undefined
              }
            />
          )}
        </div>
        {isOperatorAndValueTypeComboValid ? (
          <></>
        ) : (
          <div className={styles.errorMessage}>
            Selected operator is not applicable to selected value type
          </div>
        )}
      </Fieldset>
    </div>
  );
};

export default ExpectedResult;
